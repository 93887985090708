// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.28.1
// source: avs.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "avs.v1";

export enum AVSCategory {
  AVS_CATEGORY_BLS = 0,
  AVS_CATEGORY_ECDSA = 1,
  UNRECOGNIZED = -1,
}

export function aVSCategoryFromJSON(object: any): AVSCategory {
  switch (object) {
    case 0:
    case "AVS_CATEGORY_BLS":
      return AVSCategory.AVS_CATEGORY_BLS;
    case 1:
    case "AVS_CATEGORY_ECDSA":
      return AVSCategory.AVS_CATEGORY_ECDSA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AVSCategory.UNRECOGNIZED;
  }
}

export function aVSCategoryToJSON(object: AVSCategory): string {
  switch (object) {
    case AVSCategory.AVS_CATEGORY_BLS:
      return "AVS_CATEGORY_BLS";
    case AVSCategory.AVS_CATEGORY_ECDSA:
      return "AVS_CATEGORY_ECDSA";
    case AVSCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum OperatorStatus {
  unregistered = 0,
  registering = 1,
  registered = 2,
  launching = 3,
  running = 4,
  UNRECOGNIZED = -1,
}

export function operatorStatusFromJSON(object: any): OperatorStatus {
  switch (object) {
    case 0:
    case "unregistered":
      return OperatorStatus.unregistered;
    case 1:
    case "registering":
      return OperatorStatus.registering;
    case 2:
    case "registered":
      return OperatorStatus.registered;
    case 3:
    case "launching":
      return OperatorStatus.launching;
    case 4:
    case "running":
      return OperatorStatus.running;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperatorStatus.UNRECOGNIZED;
  }
}

export function operatorStatusToJSON(object: OperatorStatus): string {
  switch (object) {
    case OperatorStatus.unregistered:
      return "unregistered";
    case OperatorStatus.registering:
      return "registering";
    case OperatorStatus.registered:
      return "registered";
    case OperatorStatus.launching:
      return "launching";
    case OperatorStatus.running:
      return "running";
    case OperatorStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetAddressByTransactionHashRequest {
  chainId: number;
  transactionHash: string;
}

export interface GetAddressByTransactionHashResponse {
  avsDeployment: AVSDeployment | undefined;
}

export interface AVSDeployment {
  avsCategory: AVSCategory;
  deploymentId: string;
  proxyAddress: string;
  pauseRegistry: string;
  indexRegistryProxy: string;
  stakeRegistryProxy: string;
  apkRegistryProxy: string;
  registryCoordinatorProxy: string;
  serviceManagerProxy: string;
  operatorStateRetriever: string;
  avsType: number;
  quorumNumbers: number[];
  aggregatorConfig: string;
  avsName: string;
  abi: string;
  operators: OperatorInfo[];
  strategyInfo: StrategyInfo[];
}

export interface OperatorInfo {
  operator: string;
  bls: string;
}

export interface StrategyInfo {
  quorum: number;
  strategy: StrategyElement[];
}

export interface StrategyElement {
  address: string;
  multiplier: string;
}

export interface ListAllAVSDeploymentRequest {
}

export interface ListAllAVSDeploymentResponse {
  avsDeployment: AVSDeployment[];
}

export interface RemoveAVSDeploymentRequest {
  deploymentId: string;
}

export interface RemoveAVSDeploymentResponse {
  deploymentId: string;
}

export interface GetAVSStatusByNameRequest {
  name: string;
}

export interface GetAVSStatusByNameResponse {
  avsName: string;
  avsContractAddress: string;
  status: string;
}

export interface ActivateAVSRequest {
  chainId: number;
  transactionHash: string;
  avsName: string;
  avsType: number;
  quorumNumbers: number[];
}

export interface AVSMethod {
  method: string;
  quorumNumbers: number[];
  quorumThresholdPercentages: number[];
  workProofQuorumNumbers: number[];
  workProofQuorumThresholdPercentages: number[];
}

export interface ActivateAVSResponse {
  avsDeployment: AVSDeployment | undefined;
}

export interface GetAVSTemplateRequest {
}

export interface GetAVSTemplateResponse {
  templates: AVSTemplate[];
}

export interface AVSTemplate {
  type: number;
  name: string;
  method: string;
  abi: string;
}

export interface GetAggregatorStatusRequest {
}

export interface GetAggregatorStatusResponse {
  getAVSStatusByNameResponse: GetAVSStatusByNameResponse[];
}

export interface ActivateOperatorRequest {
  deploymentId: string;
  quorums: number[];
}

export interface ActivateOperatorResponse {
  status: OperatorStatus;
  txnHash: string;
}

export interface RegisterOperatorRequest {
  deploymentId: string;
}

export interface RegisterOperatorResponse {
  receipt: string;
}

export interface QueryOperatorRequest {
  deploymentId: string;
}

export interface QueryOperatorResponse {
  status: OperatorStatus;
  ingress: string;
  userName: string;
  password: string;
}

export interface LaunchOperatorRequest {
  deploymentId: string;
  promoCode: string;
}

export interface LaunchOperatorResponse {
  status: OperatorStatus;
}

export interface ListAllAVSDeploymentAdminRequest {
}

export interface ListAllAVSDeploymentAdminResponse {
  avsDeployment: AVSDeployment[];
}

export interface TerminateOperatorRequest {
  deploymentId: string;
}

export interface TerminateOperatorResponse {
}

export interface TerminateOperatorsRequest {
  deploymentId: string[];
}

export interface TerminateOperatorsResponse {
}

export interface OperatorDeployment {
  deploymentId: string;
  ingress: string;
}

export interface ListAllRunningOperatorsAdminRequest {
}

export interface ListAllRunningOperatorsAdminResponse {
  operator: OperatorDeployment[];
}

export interface GetAggregatorRequest {
}

export interface GetAggregatorResponse {
  aggregator: string[];
}

function createBaseGetAddressByTransactionHashRequest(): GetAddressByTransactionHashRequest {
  return { chainId: 0, transactionHash: "" };
}

export const GetAddressByTransactionHashRequest = {
  encode(message: GetAddressByTransactionHashRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== 0) {
      writer.uint32(8).uint32(message.chainId);
    }
    if (message.transactionHash !== "") {
      writer.uint32(18).string(message.transactionHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAddressByTransactionHashRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAddressByTransactionHashRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chainId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAddressByTransactionHashRequest {
    return {
      chainId: isSet(object.chainId) ? globalThis.Number(object.chainId) : 0,
      transactionHash: isSet(object.transactionHash) ? globalThis.String(object.transactionHash) : "",
    };
  },

  toJSON(message: GetAddressByTransactionHashRequest): unknown {
    const obj: any = {};
    if (message.chainId !== 0) {
      obj.chainId = Math.round(message.chainId);
    }
    if (message.transactionHash !== "") {
      obj.transactionHash = message.transactionHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAddressByTransactionHashRequest>, I>>(
    base?: I,
  ): GetAddressByTransactionHashRequest {
    return GetAddressByTransactionHashRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAddressByTransactionHashRequest>, I>>(
    object: I,
  ): GetAddressByTransactionHashRequest {
    const message = createBaseGetAddressByTransactionHashRequest();
    message.chainId = object.chainId ?? 0;
    message.transactionHash = object.transactionHash ?? "";
    return message;
  },
};

function createBaseGetAddressByTransactionHashResponse(): GetAddressByTransactionHashResponse {
  return { avsDeployment: undefined };
}

export const GetAddressByTransactionHashResponse = {
  encode(message: GetAddressByTransactionHashResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.avsDeployment !== undefined) {
      AVSDeployment.encode(message.avsDeployment, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAddressByTransactionHashResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAddressByTransactionHashResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avsDeployment = AVSDeployment.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAddressByTransactionHashResponse {
    return { avsDeployment: isSet(object.avsDeployment) ? AVSDeployment.fromJSON(object.avsDeployment) : undefined };
  },

  toJSON(message: GetAddressByTransactionHashResponse): unknown {
    const obj: any = {};
    if (message.avsDeployment !== undefined) {
      obj.avsDeployment = AVSDeployment.toJSON(message.avsDeployment);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAddressByTransactionHashResponse>, I>>(
    base?: I,
  ): GetAddressByTransactionHashResponse {
    return GetAddressByTransactionHashResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAddressByTransactionHashResponse>, I>>(
    object: I,
  ): GetAddressByTransactionHashResponse {
    const message = createBaseGetAddressByTransactionHashResponse();
    message.avsDeployment = (object.avsDeployment !== undefined && object.avsDeployment !== null)
      ? AVSDeployment.fromPartial(object.avsDeployment)
      : undefined;
    return message;
  },
};

function createBaseAVSDeployment(): AVSDeployment {
  return {
    avsCategory: 0,
    deploymentId: "",
    proxyAddress: "",
    pauseRegistry: "",
    indexRegistryProxy: "",
    stakeRegistryProxy: "",
    apkRegistryProxy: "",
    registryCoordinatorProxy: "",
    serviceManagerProxy: "",
    operatorStateRetriever: "",
    avsType: 0,
    quorumNumbers: [],
    aggregatorConfig: "",
    avsName: "",
    abi: "",
    operators: [],
    strategyInfo: [],
  };
}

export const AVSDeployment = {
  encode(message: AVSDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.avsCategory !== 0) {
      writer.uint32(8).int32(message.avsCategory);
    }
    if (message.deploymentId !== "") {
      writer.uint32(18).string(message.deploymentId);
    }
    if (message.proxyAddress !== "") {
      writer.uint32(26).string(message.proxyAddress);
    }
    if (message.pauseRegistry !== "") {
      writer.uint32(34).string(message.pauseRegistry);
    }
    if (message.indexRegistryProxy !== "") {
      writer.uint32(42).string(message.indexRegistryProxy);
    }
    if (message.stakeRegistryProxy !== "") {
      writer.uint32(50).string(message.stakeRegistryProxy);
    }
    if (message.apkRegistryProxy !== "") {
      writer.uint32(58).string(message.apkRegistryProxy);
    }
    if (message.registryCoordinatorProxy !== "") {
      writer.uint32(66).string(message.registryCoordinatorProxy);
    }
    if (message.serviceManagerProxy !== "") {
      writer.uint32(74).string(message.serviceManagerProxy);
    }
    if (message.operatorStateRetriever !== "") {
      writer.uint32(82).string(message.operatorStateRetriever);
    }
    if (message.avsType !== 0) {
      writer.uint32(88).uint32(message.avsType);
    }
    writer.uint32(98).fork();
    for (const v of message.quorumNumbers) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.aggregatorConfig !== "") {
      writer.uint32(106).string(message.aggregatorConfig);
    }
    if (message.avsName !== "") {
      writer.uint32(114).string(message.avsName);
    }
    if (message.abi !== "") {
      writer.uint32(122).string(message.abi);
    }
    for (const v of message.operators) {
      OperatorInfo.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.strategyInfo) {
      StrategyInfo.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AVSDeployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAVSDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.avsCategory = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proxyAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pauseRegistry = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.indexRegistryProxy = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.stakeRegistryProxy = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.apkRegistryProxy = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.registryCoordinatorProxy = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.serviceManagerProxy = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.operatorStateRetriever = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.avsType = reader.uint32();
          continue;
        case 12:
          if (tag === 96) {
            message.quorumNumbers.push(reader.uint32());

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.quorumNumbers.push(reader.uint32());
            }

            continue;
          }

          break;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.aggregatorConfig = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.avsName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.abi = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.operators.push(OperatorInfo.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.strategyInfo.push(StrategyInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AVSDeployment {
    return {
      avsCategory: isSet(object.avsCategory) ? aVSCategoryFromJSON(object.avsCategory) : 0,
      deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "",
      proxyAddress: isSet(object.proxyAddress) ? globalThis.String(object.proxyAddress) : "",
      pauseRegistry: isSet(object.pauseRegistry) ? globalThis.String(object.pauseRegistry) : "",
      indexRegistryProxy: isSet(object.indexRegistryProxy) ? globalThis.String(object.indexRegistryProxy) : "",
      stakeRegistryProxy: isSet(object.stakeRegistryProxy) ? globalThis.String(object.stakeRegistryProxy) : "",
      apkRegistryProxy: isSet(object.apkRegistryProxy) ? globalThis.String(object.apkRegistryProxy) : "",
      registryCoordinatorProxy: isSet(object.registryCoordinatorProxy)
        ? globalThis.String(object.registryCoordinatorProxy)
        : "",
      serviceManagerProxy: isSet(object.serviceManagerProxy) ? globalThis.String(object.serviceManagerProxy) : "",
      operatorStateRetriever: isSet(object.operatorStateRetriever)
        ? globalThis.String(object.operatorStateRetriever)
        : "",
      avsType: isSet(object.avsType) ? globalThis.Number(object.avsType) : 0,
      quorumNumbers: globalThis.Array.isArray(object?.quorumNumbers)
        ? object.quorumNumbers.map((e: any) => globalThis.Number(e))
        : [],
      aggregatorConfig: isSet(object.aggregatorConfig) ? globalThis.String(object.aggregatorConfig) : "",
      avsName: isSet(object.avsName) ? globalThis.String(object.avsName) : "",
      abi: isSet(object.abi) ? globalThis.String(object.abi) : "",
      operators: globalThis.Array.isArray(object?.operators)
        ? object.operators.map((e: any) => OperatorInfo.fromJSON(e))
        : [],
      strategyInfo: globalThis.Array.isArray(object?.strategyInfo)
        ? object.strategyInfo.map((e: any) => StrategyInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AVSDeployment): unknown {
    const obj: any = {};
    if (message.avsCategory !== 0) {
      obj.avsCategory = aVSCategoryToJSON(message.avsCategory);
    }
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    if (message.proxyAddress !== "") {
      obj.proxyAddress = message.proxyAddress;
    }
    if (message.pauseRegistry !== "") {
      obj.pauseRegistry = message.pauseRegistry;
    }
    if (message.indexRegistryProxy !== "") {
      obj.indexRegistryProxy = message.indexRegistryProxy;
    }
    if (message.stakeRegistryProxy !== "") {
      obj.stakeRegistryProxy = message.stakeRegistryProxy;
    }
    if (message.apkRegistryProxy !== "") {
      obj.apkRegistryProxy = message.apkRegistryProxy;
    }
    if (message.registryCoordinatorProxy !== "") {
      obj.registryCoordinatorProxy = message.registryCoordinatorProxy;
    }
    if (message.serviceManagerProxy !== "") {
      obj.serviceManagerProxy = message.serviceManagerProxy;
    }
    if (message.operatorStateRetriever !== "") {
      obj.operatorStateRetriever = message.operatorStateRetriever;
    }
    if (message.avsType !== 0) {
      obj.avsType = Math.round(message.avsType);
    }
    if (message.quorumNumbers?.length) {
      obj.quorumNumbers = message.quorumNumbers.map((e) => Math.round(e));
    }
    if (message.aggregatorConfig !== "") {
      obj.aggregatorConfig = message.aggregatorConfig;
    }
    if (message.avsName !== "") {
      obj.avsName = message.avsName;
    }
    if (message.abi !== "") {
      obj.abi = message.abi;
    }
    if (message.operators?.length) {
      obj.operators = message.operators.map((e) => OperatorInfo.toJSON(e));
    }
    if (message.strategyInfo?.length) {
      obj.strategyInfo = message.strategyInfo.map((e) => StrategyInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AVSDeployment>, I>>(base?: I): AVSDeployment {
    return AVSDeployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AVSDeployment>, I>>(object: I): AVSDeployment {
    const message = createBaseAVSDeployment();
    message.avsCategory = object.avsCategory ?? 0;
    message.deploymentId = object.deploymentId ?? "";
    message.proxyAddress = object.proxyAddress ?? "";
    message.pauseRegistry = object.pauseRegistry ?? "";
    message.indexRegistryProxy = object.indexRegistryProxy ?? "";
    message.stakeRegistryProxy = object.stakeRegistryProxy ?? "";
    message.apkRegistryProxy = object.apkRegistryProxy ?? "";
    message.registryCoordinatorProxy = object.registryCoordinatorProxy ?? "";
    message.serviceManagerProxy = object.serviceManagerProxy ?? "";
    message.operatorStateRetriever = object.operatorStateRetriever ?? "";
    message.avsType = object.avsType ?? 0;
    message.quorumNumbers = object.quorumNumbers?.map((e) => e) || [];
    message.aggregatorConfig = object.aggregatorConfig ?? "";
    message.avsName = object.avsName ?? "";
    message.abi = object.abi ?? "";
    message.operators = object.operators?.map((e) => OperatorInfo.fromPartial(e)) || [];
    message.strategyInfo = object.strategyInfo?.map((e) => StrategyInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOperatorInfo(): OperatorInfo {
  return { operator: "", bls: "" };
}

export const OperatorInfo = {
  encode(message: OperatorInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.bls !== "") {
      writer.uint32(18).string(message.bls);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperatorInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperatorInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bls = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperatorInfo {
    return {
      operator: isSet(object.operator) ? globalThis.String(object.operator) : "",
      bls: isSet(object.bls) ? globalThis.String(object.bls) : "",
    };
  },

  toJSON(message: OperatorInfo): unknown {
    const obj: any = {};
    if (message.operator !== "") {
      obj.operator = message.operator;
    }
    if (message.bls !== "") {
      obj.bls = message.bls;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperatorInfo>, I>>(base?: I): OperatorInfo {
    return OperatorInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperatorInfo>, I>>(object: I): OperatorInfo {
    const message = createBaseOperatorInfo();
    message.operator = object.operator ?? "";
    message.bls = object.bls ?? "";
    return message;
  },
};

function createBaseStrategyInfo(): StrategyInfo {
  return { quorum: 0, strategy: [] };
}

export const StrategyInfo = {
  encode(message: StrategyInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quorum !== 0) {
      writer.uint32(8).uint32(message.quorum);
    }
    for (const v of message.strategy) {
      StrategyElement.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.quorum = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.strategy.push(StrategyElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StrategyInfo {
    return {
      quorum: isSet(object.quorum) ? globalThis.Number(object.quorum) : 0,
      strategy: globalThis.Array.isArray(object?.strategy)
        ? object.strategy.map((e: any) => StrategyElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StrategyInfo): unknown {
    const obj: any = {};
    if (message.quorum !== 0) {
      obj.quorum = Math.round(message.quorum);
    }
    if (message.strategy?.length) {
      obj.strategy = message.strategy.map((e) => StrategyElement.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyInfo>, I>>(base?: I): StrategyInfo {
    return StrategyInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StrategyInfo>, I>>(object: I): StrategyInfo {
    const message = createBaseStrategyInfo();
    message.quorum = object.quorum ?? 0;
    message.strategy = object.strategy?.map((e) => StrategyElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyElement(): StrategyElement {
  return { address: "", multiplier: "" };
}

export const StrategyElement = {
  encode(message: StrategyElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.multiplier !== "") {
      writer.uint32(18).string(message.multiplier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.multiplier = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StrategyElement {
    return {
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      multiplier: isSet(object.multiplier) ? globalThis.String(object.multiplier) : "",
    };
  },

  toJSON(message: StrategyElement): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.multiplier !== "") {
      obj.multiplier = message.multiplier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyElement>, I>>(base?: I): StrategyElement {
    return StrategyElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StrategyElement>, I>>(object: I): StrategyElement {
    const message = createBaseStrategyElement();
    message.address = object.address ?? "";
    message.multiplier = object.multiplier ?? "";
    return message;
  },
};

function createBaseListAllAVSDeploymentRequest(): ListAllAVSDeploymentRequest {
  return {};
}

export const ListAllAVSDeploymentRequest = {
  encode(_: ListAllAVSDeploymentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllAVSDeploymentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllAVSDeploymentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAllAVSDeploymentRequest {
    return {};
  },

  toJSON(_: ListAllAVSDeploymentRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllAVSDeploymentRequest>, I>>(base?: I): ListAllAVSDeploymentRequest {
    return ListAllAVSDeploymentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllAVSDeploymentRequest>, I>>(_: I): ListAllAVSDeploymentRequest {
    const message = createBaseListAllAVSDeploymentRequest();
    return message;
  },
};

function createBaseListAllAVSDeploymentResponse(): ListAllAVSDeploymentResponse {
  return { avsDeployment: [] };
}

export const ListAllAVSDeploymentResponse = {
  encode(message: ListAllAVSDeploymentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.avsDeployment) {
      AVSDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllAVSDeploymentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllAVSDeploymentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avsDeployment.push(AVSDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAllAVSDeploymentResponse {
    return {
      avsDeployment: globalThis.Array.isArray(object?.avsDeployment)
        ? object.avsDeployment.map((e: any) => AVSDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAllAVSDeploymentResponse): unknown {
    const obj: any = {};
    if (message.avsDeployment?.length) {
      obj.avsDeployment = message.avsDeployment.map((e) => AVSDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllAVSDeploymentResponse>, I>>(base?: I): ListAllAVSDeploymentResponse {
    return ListAllAVSDeploymentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllAVSDeploymentResponse>, I>>(object: I): ListAllAVSDeploymentResponse {
    const message = createBaseListAllAVSDeploymentResponse();
    message.avsDeployment = object.avsDeployment?.map((e) => AVSDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRemoveAVSDeploymentRequest(): RemoveAVSDeploymentRequest {
  return { deploymentId: "" };
}

export const RemoveAVSDeploymentRequest = {
  encode(message: RemoveAVSDeploymentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAVSDeploymentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAVSDeploymentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveAVSDeploymentRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: RemoveAVSDeploymentRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveAVSDeploymentRequest>, I>>(base?: I): RemoveAVSDeploymentRequest {
    return RemoveAVSDeploymentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveAVSDeploymentRequest>, I>>(object: I): RemoveAVSDeploymentRequest {
    const message = createBaseRemoveAVSDeploymentRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseRemoveAVSDeploymentResponse(): RemoveAVSDeploymentResponse {
  return { deploymentId: "" };
}

export const RemoveAVSDeploymentResponse = {
  encode(message: RemoveAVSDeploymentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAVSDeploymentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAVSDeploymentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveAVSDeploymentResponse {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: RemoveAVSDeploymentResponse): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveAVSDeploymentResponse>, I>>(base?: I): RemoveAVSDeploymentResponse {
    return RemoveAVSDeploymentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveAVSDeploymentResponse>, I>>(object: I): RemoveAVSDeploymentResponse {
    const message = createBaseRemoveAVSDeploymentResponse();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseGetAVSStatusByNameRequest(): GetAVSStatusByNameRequest {
  return { name: "" };
}

export const GetAVSStatusByNameRequest = {
  encode(message: GetAVSStatusByNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAVSStatusByNameRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAVSStatusByNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAVSStatusByNameRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetAVSStatusByNameRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAVSStatusByNameRequest>, I>>(base?: I): GetAVSStatusByNameRequest {
    return GetAVSStatusByNameRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAVSStatusByNameRequest>, I>>(object: I): GetAVSStatusByNameRequest {
    const message = createBaseGetAVSStatusByNameRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetAVSStatusByNameResponse(): GetAVSStatusByNameResponse {
  return { avsName: "", avsContractAddress: "", status: "" };
}

export const GetAVSStatusByNameResponse = {
  encode(message: GetAVSStatusByNameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.avsName !== "") {
      writer.uint32(10).string(message.avsName);
    }
    if (message.avsContractAddress !== "") {
      writer.uint32(18).string(message.avsContractAddress);
    }
    if (message.status !== "") {
      writer.uint32(26).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAVSStatusByNameResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAVSStatusByNameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avsName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.avsContractAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAVSStatusByNameResponse {
    return {
      avsName: isSet(object.avsName) ? globalThis.String(object.avsName) : "",
      avsContractAddress: isSet(object.avsContractAddress) ? globalThis.String(object.avsContractAddress) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: GetAVSStatusByNameResponse): unknown {
    const obj: any = {};
    if (message.avsName !== "") {
      obj.avsName = message.avsName;
    }
    if (message.avsContractAddress !== "") {
      obj.avsContractAddress = message.avsContractAddress;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAVSStatusByNameResponse>, I>>(base?: I): GetAVSStatusByNameResponse {
    return GetAVSStatusByNameResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAVSStatusByNameResponse>, I>>(object: I): GetAVSStatusByNameResponse {
    const message = createBaseGetAVSStatusByNameResponse();
    message.avsName = object.avsName ?? "";
    message.avsContractAddress = object.avsContractAddress ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseActivateAVSRequest(): ActivateAVSRequest {
  return { chainId: 0, transactionHash: "", avsName: "", avsType: 0, quorumNumbers: [] };
}

export const ActivateAVSRequest = {
  encode(message: ActivateAVSRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== 0) {
      writer.uint32(8).uint32(message.chainId);
    }
    if (message.transactionHash !== "") {
      writer.uint32(18).string(message.transactionHash);
    }
    if (message.avsName !== "") {
      writer.uint32(26).string(message.avsName);
    }
    if (message.avsType !== 0) {
      writer.uint32(32).uint32(message.avsType);
    }
    writer.uint32(42).fork();
    for (const v of message.quorumNumbers) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateAVSRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateAVSRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chainId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionHash = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.avsName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.avsType = reader.uint32();
          continue;
        case 5:
          if (tag === 40) {
            message.quorumNumbers.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.quorumNumbers.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateAVSRequest {
    return {
      chainId: isSet(object.chainId) ? globalThis.Number(object.chainId) : 0,
      transactionHash: isSet(object.transactionHash) ? globalThis.String(object.transactionHash) : "",
      avsName: isSet(object.avsName) ? globalThis.String(object.avsName) : "",
      avsType: isSet(object.avsType) ? globalThis.Number(object.avsType) : 0,
      quorumNumbers: globalThis.Array.isArray(object?.quorumNumbers)
        ? object.quorumNumbers.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: ActivateAVSRequest): unknown {
    const obj: any = {};
    if (message.chainId !== 0) {
      obj.chainId = Math.round(message.chainId);
    }
    if (message.transactionHash !== "") {
      obj.transactionHash = message.transactionHash;
    }
    if (message.avsName !== "") {
      obj.avsName = message.avsName;
    }
    if (message.avsType !== 0) {
      obj.avsType = Math.round(message.avsType);
    }
    if (message.quorumNumbers?.length) {
      obj.quorumNumbers = message.quorumNumbers.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateAVSRequest>, I>>(base?: I): ActivateAVSRequest {
    return ActivateAVSRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateAVSRequest>, I>>(object: I): ActivateAVSRequest {
    const message = createBaseActivateAVSRequest();
    message.chainId = object.chainId ?? 0;
    message.transactionHash = object.transactionHash ?? "";
    message.avsName = object.avsName ?? "";
    message.avsType = object.avsType ?? 0;
    message.quorumNumbers = object.quorumNumbers?.map((e) => e) || [];
    return message;
  },
};

function createBaseAVSMethod(): AVSMethod {
  return {
    method: "",
    quorumNumbers: [],
    quorumThresholdPercentages: [],
    workProofQuorumNumbers: [],
    workProofQuorumThresholdPercentages: [],
  };
}

export const AVSMethod = {
  encode(message: AVSMethod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.method !== "") {
      writer.uint32(10).string(message.method);
    }
    writer.uint32(18).fork();
    for (const v of message.quorumNumbers) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.quorumThresholdPercentages) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.workProofQuorumNumbers) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.workProofQuorumThresholdPercentages) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AVSMethod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAVSMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.method = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.quorumNumbers.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.quorumNumbers.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.quorumThresholdPercentages.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.quorumThresholdPercentages.push(reader.uint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.workProofQuorumNumbers.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.workProofQuorumNumbers.push(reader.uint32());
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.workProofQuorumThresholdPercentages.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.workProofQuorumThresholdPercentages.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AVSMethod {
    return {
      method: isSet(object.method) ? globalThis.String(object.method) : "",
      quorumNumbers: globalThis.Array.isArray(object?.quorumNumbers)
        ? object.quorumNumbers.map((e: any) => globalThis.Number(e))
        : [],
      quorumThresholdPercentages: globalThis.Array.isArray(object?.quorumThresholdPercentages)
        ? object.quorumThresholdPercentages.map((e: any) => globalThis.Number(e))
        : [],
      workProofQuorumNumbers: globalThis.Array.isArray(object?.workProofQuorumNumbers)
        ? object.workProofQuorumNumbers.map((e: any) => globalThis.Number(e))
        : [],
      workProofQuorumThresholdPercentages: globalThis.Array.isArray(object?.workProofQuorumThresholdPercentages)
        ? object.workProofQuorumThresholdPercentages.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: AVSMethod): unknown {
    const obj: any = {};
    if (message.method !== "") {
      obj.method = message.method;
    }
    if (message.quorumNumbers?.length) {
      obj.quorumNumbers = message.quorumNumbers.map((e) => Math.round(e));
    }
    if (message.quorumThresholdPercentages?.length) {
      obj.quorumThresholdPercentages = message.quorumThresholdPercentages.map((e) => Math.round(e));
    }
    if (message.workProofQuorumNumbers?.length) {
      obj.workProofQuorumNumbers = message.workProofQuorumNumbers.map((e) => Math.round(e));
    }
    if (message.workProofQuorumThresholdPercentages?.length) {
      obj.workProofQuorumThresholdPercentages = message.workProofQuorumThresholdPercentages.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AVSMethod>, I>>(base?: I): AVSMethod {
    return AVSMethod.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AVSMethod>, I>>(object: I): AVSMethod {
    const message = createBaseAVSMethod();
    message.method = object.method ?? "";
    message.quorumNumbers = object.quorumNumbers?.map((e) => e) || [];
    message.quorumThresholdPercentages = object.quorumThresholdPercentages?.map((e) => e) || [];
    message.workProofQuorumNumbers = object.workProofQuorumNumbers?.map((e) => e) || [];
    message.workProofQuorumThresholdPercentages = object.workProofQuorumThresholdPercentages?.map((e) => e) || [];
    return message;
  },
};

function createBaseActivateAVSResponse(): ActivateAVSResponse {
  return { avsDeployment: undefined };
}

export const ActivateAVSResponse = {
  encode(message: ActivateAVSResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.avsDeployment !== undefined) {
      AVSDeployment.encode(message.avsDeployment, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateAVSResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateAVSResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avsDeployment = AVSDeployment.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateAVSResponse {
    return { avsDeployment: isSet(object.avsDeployment) ? AVSDeployment.fromJSON(object.avsDeployment) : undefined };
  },

  toJSON(message: ActivateAVSResponse): unknown {
    const obj: any = {};
    if (message.avsDeployment !== undefined) {
      obj.avsDeployment = AVSDeployment.toJSON(message.avsDeployment);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateAVSResponse>, I>>(base?: I): ActivateAVSResponse {
    return ActivateAVSResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateAVSResponse>, I>>(object: I): ActivateAVSResponse {
    const message = createBaseActivateAVSResponse();
    message.avsDeployment = (object.avsDeployment !== undefined && object.avsDeployment !== null)
      ? AVSDeployment.fromPartial(object.avsDeployment)
      : undefined;
    return message;
  },
};

function createBaseGetAVSTemplateRequest(): GetAVSTemplateRequest {
  return {};
}

export const GetAVSTemplateRequest = {
  encode(_: GetAVSTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAVSTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAVSTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAVSTemplateRequest {
    return {};
  },

  toJSON(_: GetAVSTemplateRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAVSTemplateRequest>, I>>(base?: I): GetAVSTemplateRequest {
    return GetAVSTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAVSTemplateRequest>, I>>(_: I): GetAVSTemplateRequest {
    const message = createBaseGetAVSTemplateRequest();
    return message;
  },
};

function createBaseGetAVSTemplateResponse(): GetAVSTemplateResponse {
  return { templates: [] };
}

export const GetAVSTemplateResponse = {
  encode(message: GetAVSTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      AVSTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAVSTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAVSTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(AVSTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAVSTemplateResponse {
    return {
      templates: globalThis.Array.isArray(object?.templates)
        ? object.templates.map((e: any) => AVSTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAVSTemplateResponse): unknown {
    const obj: any = {};
    if (message.templates?.length) {
      obj.templates = message.templates.map((e) => AVSTemplate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAVSTemplateResponse>, I>>(base?: I): GetAVSTemplateResponse {
    return GetAVSTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAVSTemplateResponse>, I>>(object: I): GetAVSTemplateResponse {
    const message = createBaseGetAVSTemplateResponse();
    message.templates = object.templates?.map((e) => AVSTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAVSTemplate(): AVSTemplate {
  return { type: 0, name: "", method: "", abi: "" };
}

export const AVSTemplate = {
  encode(message: AVSTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.method !== "") {
      writer.uint32(26).string(message.method);
    }
    if (message.abi !== "") {
      writer.uint32(34).string(message.abi);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AVSTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAVSTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.method = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.abi = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AVSTemplate {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      method: isSet(object.method) ? globalThis.String(object.method) : "",
      abi: isSet(object.abi) ? globalThis.String(object.abi) : "",
    };
  },

  toJSON(message: AVSTemplate): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.method !== "") {
      obj.method = message.method;
    }
    if (message.abi !== "") {
      obj.abi = message.abi;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AVSTemplate>, I>>(base?: I): AVSTemplate {
    return AVSTemplate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AVSTemplate>, I>>(object: I): AVSTemplate {
    const message = createBaseAVSTemplate();
    message.type = object.type ?? 0;
    message.name = object.name ?? "";
    message.method = object.method ?? "";
    message.abi = object.abi ?? "";
    return message;
  },
};

function createBaseGetAggregatorStatusRequest(): GetAggregatorStatusRequest {
  return {};
}

export const GetAggregatorStatusRequest = {
  encode(_: GetAggregatorStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAggregatorStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAggregatorStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAggregatorStatusRequest {
    return {};
  },

  toJSON(_: GetAggregatorStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAggregatorStatusRequest>, I>>(base?: I): GetAggregatorStatusRequest {
    return GetAggregatorStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAggregatorStatusRequest>, I>>(_: I): GetAggregatorStatusRequest {
    const message = createBaseGetAggregatorStatusRequest();
    return message;
  },
};

function createBaseGetAggregatorStatusResponse(): GetAggregatorStatusResponse {
  return { getAVSStatusByNameResponse: [] };
}

export const GetAggregatorStatusResponse = {
  encode(message: GetAggregatorStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.getAVSStatusByNameResponse) {
      GetAVSStatusByNameResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAggregatorStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAggregatorStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.getAVSStatusByNameResponse.push(GetAVSStatusByNameResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAggregatorStatusResponse {
    return {
      getAVSStatusByNameResponse: globalThis.Array.isArray(object?.getAVSStatusByNameResponse)
        ? object.getAVSStatusByNameResponse.map((e: any) => GetAVSStatusByNameResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAggregatorStatusResponse): unknown {
    const obj: any = {};
    if (message.getAVSStatusByNameResponse?.length) {
      obj.getAVSStatusByNameResponse = message.getAVSStatusByNameResponse.map((e) =>
        GetAVSStatusByNameResponse.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAggregatorStatusResponse>, I>>(base?: I): GetAggregatorStatusResponse {
    return GetAggregatorStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAggregatorStatusResponse>, I>>(object: I): GetAggregatorStatusResponse {
    const message = createBaseGetAggregatorStatusResponse();
    message.getAVSStatusByNameResponse =
      object.getAVSStatusByNameResponse?.map((e) => GetAVSStatusByNameResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActivateOperatorRequest(): ActivateOperatorRequest {
  return { deploymentId: "", quorums: [] };
}

export const ActivateOperatorRequest = {
  encode(message: ActivateOperatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    writer.uint32(18).fork();
    for (const v of message.quorums) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateOperatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateOperatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.quorums.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.quorums.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateOperatorRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "",
      quorums: globalThis.Array.isArray(object?.quorums) ? object.quorums.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: ActivateOperatorRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    if (message.quorums?.length) {
      obj.quorums = message.quorums.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateOperatorRequest>, I>>(base?: I): ActivateOperatorRequest {
    return ActivateOperatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateOperatorRequest>, I>>(object: I): ActivateOperatorRequest {
    const message = createBaseActivateOperatorRequest();
    message.deploymentId = object.deploymentId ?? "";
    message.quorums = object.quorums?.map((e) => e) || [];
    return message;
  },
};

function createBaseActivateOperatorResponse(): ActivateOperatorResponse {
  return { status: 0, txnHash: "" };
}

export const ActivateOperatorResponse = {
  encode(message: ActivateOperatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.txnHash !== "") {
      writer.uint32(18).string(message.txnHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateOperatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateOperatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.txnHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateOperatorResponse {
    return {
      status: isSet(object.status) ? operatorStatusFromJSON(object.status) : 0,
      txnHash: isSet(object.txnHash) ? globalThis.String(object.txnHash) : "",
    };
  },

  toJSON(message: ActivateOperatorResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = operatorStatusToJSON(message.status);
    }
    if (message.txnHash !== "") {
      obj.txnHash = message.txnHash;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateOperatorResponse>, I>>(base?: I): ActivateOperatorResponse {
    return ActivateOperatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateOperatorResponse>, I>>(object: I): ActivateOperatorResponse {
    const message = createBaseActivateOperatorResponse();
    message.status = object.status ?? 0;
    message.txnHash = object.txnHash ?? "";
    return message;
  },
};

function createBaseRegisterOperatorRequest(): RegisterOperatorRequest {
  return { deploymentId: "" };
}

export const RegisterOperatorRequest = {
  encode(message: RegisterOperatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterOperatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterOperatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterOperatorRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: RegisterOperatorRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterOperatorRequest>, I>>(base?: I): RegisterOperatorRequest {
    return RegisterOperatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterOperatorRequest>, I>>(object: I): RegisterOperatorRequest {
    const message = createBaseRegisterOperatorRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseRegisterOperatorResponse(): RegisterOperatorResponse {
  return { receipt: "" };
}

export const RegisterOperatorResponse = {
  encode(message: RegisterOperatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receipt !== "") {
      writer.uint32(10).string(message.receipt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterOperatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterOperatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.receipt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterOperatorResponse {
    return { receipt: isSet(object.receipt) ? globalThis.String(object.receipt) : "" };
  },

  toJSON(message: RegisterOperatorResponse): unknown {
    const obj: any = {};
    if (message.receipt !== "") {
      obj.receipt = message.receipt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterOperatorResponse>, I>>(base?: I): RegisterOperatorResponse {
    return RegisterOperatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterOperatorResponse>, I>>(object: I): RegisterOperatorResponse {
    const message = createBaseRegisterOperatorResponse();
    message.receipt = object.receipt ?? "";
    return message;
  },
};

function createBaseQueryOperatorRequest(): QueryOperatorRequest {
  return { deploymentId: "" };
}

export const QueryOperatorRequest = {
  encode(message: QueryOperatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryOperatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryOperatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryOperatorRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: QueryOperatorRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryOperatorRequest>, I>>(base?: I): QueryOperatorRequest {
    return QueryOperatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryOperatorRequest>, I>>(object: I): QueryOperatorRequest {
    const message = createBaseQueryOperatorRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseQueryOperatorResponse(): QueryOperatorResponse {
  return { status: 0, ingress: "", userName: "", password: "" };
}

export const QueryOperatorResponse = {
  encode(message: QueryOperatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.ingress !== "") {
      writer.uint32(18).string(message.ingress);
    }
    if (message.userName !== "") {
      writer.uint32(26).string(message.userName);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryOperatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryOperatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ingress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QueryOperatorResponse {
    return {
      status: isSet(object.status) ? operatorStatusFromJSON(object.status) : 0,
      ingress: isSet(object.ingress) ? globalThis.String(object.ingress) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: QueryOperatorResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = operatorStatusToJSON(message.status);
    }
    if (message.ingress !== "") {
      obj.ingress = message.ingress;
    }
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QueryOperatorResponse>, I>>(base?: I): QueryOperatorResponse {
    return QueryOperatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryOperatorResponse>, I>>(object: I): QueryOperatorResponse {
    const message = createBaseQueryOperatorResponse();
    message.status = object.status ?? 0;
    message.ingress = object.ingress ?? "";
    message.userName = object.userName ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseLaunchOperatorRequest(): LaunchOperatorRequest {
  return { deploymentId: "", promoCode: "" };
}

export const LaunchOperatorRequest = {
  encode(message: LaunchOperatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    if (message.promoCode !== "") {
      writer.uint32(18).string(message.promoCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LaunchOperatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaunchOperatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.promoCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaunchOperatorRequest {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "",
      promoCode: isSet(object.promoCode) ? globalThis.String(object.promoCode) : "",
    };
  },

  toJSON(message: LaunchOperatorRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    if (message.promoCode !== "") {
      obj.promoCode = message.promoCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaunchOperatorRequest>, I>>(base?: I): LaunchOperatorRequest {
    return LaunchOperatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaunchOperatorRequest>, I>>(object: I): LaunchOperatorRequest {
    const message = createBaseLaunchOperatorRequest();
    message.deploymentId = object.deploymentId ?? "";
    message.promoCode = object.promoCode ?? "";
    return message;
  },
};

function createBaseLaunchOperatorResponse(): LaunchOperatorResponse {
  return { status: 0 };
}

export const LaunchOperatorResponse = {
  encode(message: LaunchOperatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LaunchOperatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLaunchOperatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LaunchOperatorResponse {
    return { status: isSet(object.status) ? operatorStatusFromJSON(object.status) : 0 };
  },

  toJSON(message: LaunchOperatorResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = operatorStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LaunchOperatorResponse>, I>>(base?: I): LaunchOperatorResponse {
    return LaunchOperatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LaunchOperatorResponse>, I>>(object: I): LaunchOperatorResponse {
    const message = createBaseLaunchOperatorResponse();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseListAllAVSDeploymentAdminRequest(): ListAllAVSDeploymentAdminRequest {
  return {};
}

export const ListAllAVSDeploymentAdminRequest = {
  encode(_: ListAllAVSDeploymentAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllAVSDeploymentAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllAVSDeploymentAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAllAVSDeploymentAdminRequest {
    return {};
  },

  toJSON(_: ListAllAVSDeploymentAdminRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllAVSDeploymentAdminRequest>, I>>(
    base?: I,
  ): ListAllAVSDeploymentAdminRequest {
    return ListAllAVSDeploymentAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllAVSDeploymentAdminRequest>, I>>(
    _: I,
  ): ListAllAVSDeploymentAdminRequest {
    const message = createBaseListAllAVSDeploymentAdminRequest();
    return message;
  },
};

function createBaseListAllAVSDeploymentAdminResponse(): ListAllAVSDeploymentAdminResponse {
  return { avsDeployment: [] };
}

export const ListAllAVSDeploymentAdminResponse = {
  encode(message: ListAllAVSDeploymentAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.avsDeployment) {
      AVSDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllAVSDeploymentAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllAVSDeploymentAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.avsDeployment.push(AVSDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAllAVSDeploymentAdminResponse {
    return {
      avsDeployment: globalThis.Array.isArray(object?.avsDeployment)
        ? object.avsDeployment.map((e: any) => AVSDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAllAVSDeploymentAdminResponse): unknown {
    const obj: any = {};
    if (message.avsDeployment?.length) {
      obj.avsDeployment = message.avsDeployment.map((e) => AVSDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllAVSDeploymentAdminResponse>, I>>(
    base?: I,
  ): ListAllAVSDeploymentAdminResponse {
    return ListAllAVSDeploymentAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllAVSDeploymentAdminResponse>, I>>(
    object: I,
  ): ListAllAVSDeploymentAdminResponse {
    const message = createBaseListAllAVSDeploymentAdminResponse();
    message.avsDeployment = object.avsDeployment?.map((e) => AVSDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTerminateOperatorRequest(): TerminateOperatorRequest {
  return { deploymentId: "" };
}

export const TerminateOperatorRequest = {
  encode(message: TerminateOperatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateOperatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateOperatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TerminateOperatorRequest {
    return { deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "" };
  },

  toJSON(message: TerminateOperatorRequest): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateOperatorRequest>, I>>(base?: I): TerminateOperatorRequest {
    return TerminateOperatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateOperatorRequest>, I>>(object: I): TerminateOperatorRequest {
    const message = createBaseTerminateOperatorRequest();
    message.deploymentId = object.deploymentId ?? "";
    return message;
  },
};

function createBaseTerminateOperatorResponse(): TerminateOperatorResponse {
  return {};
}

export const TerminateOperatorResponse = {
  encode(_: TerminateOperatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateOperatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateOperatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TerminateOperatorResponse {
    return {};
  },

  toJSON(_: TerminateOperatorResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateOperatorResponse>, I>>(base?: I): TerminateOperatorResponse {
    return TerminateOperatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateOperatorResponse>, I>>(_: I): TerminateOperatorResponse {
    const message = createBaseTerminateOperatorResponse();
    return message;
  },
};

function createBaseTerminateOperatorsRequest(): TerminateOperatorsRequest {
  return { deploymentId: [] };
}

export const TerminateOperatorsRequest = {
  encode(message: TerminateOperatorsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.deploymentId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateOperatorsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateOperatorsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TerminateOperatorsRequest {
    return {
      deploymentId: globalThis.Array.isArray(object?.deploymentId)
        ? object.deploymentId.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: TerminateOperatorsRequest): unknown {
    const obj: any = {};
    if (message.deploymentId?.length) {
      obj.deploymentId = message.deploymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateOperatorsRequest>, I>>(base?: I): TerminateOperatorsRequest {
    return TerminateOperatorsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateOperatorsRequest>, I>>(object: I): TerminateOperatorsRequest {
    const message = createBaseTerminateOperatorsRequest();
    message.deploymentId = object.deploymentId?.map((e) => e) || [];
    return message;
  },
};

function createBaseTerminateOperatorsResponse(): TerminateOperatorsResponse {
  return {};
}

export const TerminateOperatorsResponse = {
  encode(_: TerminateOperatorsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateOperatorsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateOperatorsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TerminateOperatorsResponse {
    return {};
  },

  toJSON(_: TerminateOperatorsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateOperatorsResponse>, I>>(base?: I): TerminateOperatorsResponse {
    return TerminateOperatorsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateOperatorsResponse>, I>>(_: I): TerminateOperatorsResponse {
    const message = createBaseTerminateOperatorsResponse();
    return message;
  },
};

function createBaseOperatorDeployment(): OperatorDeployment {
  return { deploymentId: "", ingress: "" };
}

export const OperatorDeployment = {
  encode(message: OperatorDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deploymentId !== "") {
      writer.uint32(10).string(message.deploymentId);
    }
    if (message.ingress !== "") {
      writer.uint32(18).string(message.ingress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperatorDeployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperatorDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deploymentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ingress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperatorDeployment {
    return {
      deploymentId: isSet(object.deploymentId) ? globalThis.String(object.deploymentId) : "",
      ingress: isSet(object.ingress) ? globalThis.String(object.ingress) : "",
    };
  },

  toJSON(message: OperatorDeployment): unknown {
    const obj: any = {};
    if (message.deploymentId !== "") {
      obj.deploymentId = message.deploymentId;
    }
    if (message.ingress !== "") {
      obj.ingress = message.ingress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperatorDeployment>, I>>(base?: I): OperatorDeployment {
    return OperatorDeployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperatorDeployment>, I>>(object: I): OperatorDeployment {
    const message = createBaseOperatorDeployment();
    message.deploymentId = object.deploymentId ?? "";
    message.ingress = object.ingress ?? "";
    return message;
  },
};

function createBaseListAllRunningOperatorsAdminRequest(): ListAllRunningOperatorsAdminRequest {
  return {};
}

export const ListAllRunningOperatorsAdminRequest = {
  encode(_: ListAllRunningOperatorsAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllRunningOperatorsAdminRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllRunningOperatorsAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAllRunningOperatorsAdminRequest {
    return {};
  },

  toJSON(_: ListAllRunningOperatorsAdminRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllRunningOperatorsAdminRequest>, I>>(
    base?: I,
  ): ListAllRunningOperatorsAdminRequest {
    return ListAllRunningOperatorsAdminRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllRunningOperatorsAdminRequest>, I>>(
    _: I,
  ): ListAllRunningOperatorsAdminRequest {
    const message = createBaseListAllRunningOperatorsAdminRequest();
    return message;
  },
};

function createBaseListAllRunningOperatorsAdminResponse(): ListAllRunningOperatorsAdminResponse {
  return { operator: [] };
}

export const ListAllRunningOperatorsAdminResponse = {
  encode(message: ListAllRunningOperatorsAdminResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.operator) {
      OperatorDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAllRunningOperatorsAdminResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAllRunningOperatorsAdminResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.operator.push(OperatorDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAllRunningOperatorsAdminResponse {
    return {
      operator: globalThis.Array.isArray(object?.operator)
        ? object.operator.map((e: any) => OperatorDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListAllRunningOperatorsAdminResponse): unknown {
    const obj: any = {};
    if (message.operator?.length) {
      obj.operator = message.operator.map((e) => OperatorDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAllRunningOperatorsAdminResponse>, I>>(
    base?: I,
  ): ListAllRunningOperatorsAdminResponse {
    return ListAllRunningOperatorsAdminResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAllRunningOperatorsAdminResponse>, I>>(
    object: I,
  ): ListAllRunningOperatorsAdminResponse {
    const message = createBaseListAllRunningOperatorsAdminResponse();
    message.operator = object.operator?.map((e) => OperatorDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAggregatorRequest(): GetAggregatorRequest {
  return {};
}

export const GetAggregatorRequest = {
  encode(_: GetAggregatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAggregatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAggregatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetAggregatorRequest {
    return {};
  },

  toJSON(_: GetAggregatorRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAggregatorRequest>, I>>(base?: I): GetAggregatorRequest {
    return GetAggregatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAggregatorRequest>, I>>(_: I): GetAggregatorRequest {
    const message = createBaseGetAggregatorRequest();
    return message;
  },
};

function createBaseGetAggregatorResponse(): GetAggregatorResponse {
  return { aggregator: [] };
}

export const GetAggregatorResponse = {
  encode(message: GetAggregatorResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aggregator) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAggregatorResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAggregatorResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aggregator.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAggregatorResponse {
    return {
      aggregator: globalThis.Array.isArray(object?.aggregator)
        ? object.aggregator.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetAggregatorResponse): unknown {
    const obj: any = {};
    if (message.aggregator?.length) {
      obj.aggregator = message.aggregator;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAggregatorResponse>, I>>(base?: I): GetAggregatorResponse {
    return GetAggregatorResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAggregatorResponse>, I>>(object: I): GetAggregatorResponse {
    const message = createBaseGetAggregatorResponse();
    message.aggregator = object.aggregator?.map((e) => e) || [];
    return message;
  },
};

export type AVSServiceDefinition = typeof AVSServiceDefinition;
export const AVSServiceDefinition = {
  name: "AVSService",
  fullName: "avs.v1.AVSService",
  methods: {
    /** Parse contract addresses from transaction hash */
    getAddressByTransactionHash: {
      name: "GetAddressByTransactionHash",
      requestType: GetAddressByTransactionHashRequest,
      requestStream: false,
      responseType: GetAddressByTransactionHashResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              39,
              18,
              37,
              47,
              118,
              49,
              47,
              97,
              118,
              115,
              47,
              123,
              99,
              104,
              97,
              105,
              110,
              95,
              105,
              100,
              125,
              47,
              123,
              116,
              114,
              97,
              110,
              115,
              97,
              99,
              116,
              105,
              111,
              110,
              95,
              104,
              97,
              115,
              104,
              125,
            ]),
          ],
        },
      },
    },
    activateAVS: {
      name: "ActivateAVS",
      requestType: ActivateAVSRequest,
      requestStream: false,
      responseType: ActivateAVSResponse,
      responseStream: false,
      options: {
        _unknownFields: { 578365826: [new Uint8Array([12, 34, 7, 47, 118, 49, 47, 97, 118, 115, 58, 1, 42])] },
      },
    },
    activateOperator: {
      name: "ActivateOperator",
      requestType: ActivateOperatorRequest,
      requestStream: false,
      responseType: ActivateOperatorResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 34, 13, 47, 118, 49, 47, 111, 112, 101, 114, 97, 116, 111, 114, 115, 58, 1, 42]),
          ],
        },
      },
    },
    registerOperator: {
      name: "RegisterOperator",
      requestType: RegisterOperatorRequest,
      requestStream: false,
      responseType: RegisterOperatorResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([18, 26, 13, 47, 118, 49, 47, 111, 112, 101, 114, 97, 116, 111, 114, 115, 58, 1, 42]),
          ],
        },
      },
    },
    launchOperator: {
      name: "LaunchOperator",
      requestType: LaunchOperatorRequest,
      requestStream: false,
      responseType: LaunchOperatorResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              23,
              34,
              18,
              47,
              118,
              49,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              111,
              114,
              47,
              110,
              111,
              100,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    getAggregator: {
      name: "GetAggregator",
      requestType: GetAggregatorRequest,
      requestStream: false,
      responseType: GetAggregatorResponse,
      responseStream: false,
      options: { _unknownFields: { 578365826: [new Uint8Array([10, 18, 8, 47, 118, 49, 47, 105, 110, 102, 111])] } },
    },
    terminateOperator: {
      name: "TerminateOperator",
      requestType: TerminateOperatorRequest,
      requestStream: false,
      responseType: TerminateOperatorResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              30,
              42,
              28,
              47,
              118,
              49,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              111,
              114,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
            ]),
          ],
        },
      },
    },
    queryOperator: {
      name: "QueryOperator",
      requestType: QueryOperatorRequest,
      requestStream: false,
      responseType: QueryOperatorResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              30,
              18,
              28,
              47,
              118,
              49,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              111,
              114,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
            ]),
          ],
        },
      },
    },
    listAllAVSDeployment: {
      name: "ListAllAVSDeployment",
      requestType: ListAllAVSDeploymentRequest,
      requestStream: false,
      responseType: ListAllAVSDeploymentResponse,
      responseStream: false,
      options: { _unknownFields: { 578365826: [new Uint8Array([9, 18, 7, 47, 118, 49, 47, 97, 118, 115])] } },
    },
    getAggregatorStatus: {
      name: "GetAggregatorStatus",
      requestType: GetAggregatorStatusRequest,
      requestStream: false,
      responseType: GetAggregatorStatusResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([17, 18, 15, 47, 118, 49, 47, 97, 103, 103, 114, 101, 103, 97, 116, 111, 114, 115]),
          ],
        },
      },
    },
    removeAVSDeployment: {
      name: "RemoveAVSDeployment",
      requestType: RemoveAVSDeploymentRequest,
      requestStream: false,
      responseType: RemoveAVSDeploymentResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              42,
              22,
              47,
              118,
              49,
              47,
              97,
              118,
              115,
              47,
              123,
              100,
              101,
              112,
              108,
              111,
              121,
              109,
              101,
              110,
              116,
              73,
              100,
              125,
            ]),
          ],
        },
      },
    },
    getAVSStatusByName: {
      name: "GetAVSStatusByName",
      requestType: GetAVSStatusByNameRequest,
      requestStream: false,
      responseType: GetAVSStatusByNameResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              18,
              22,
              47,
              118,
              49,
              47,
              97,
              103,
              103,
              114,
              101,
              103,
              97,
              116,
              111,
              114,
              115,
              47,
              123,
              110,
              97,
              109,
              101,
              125,
            ]),
          ],
        },
      },
    },
    getAVSTemplate: {
      name: "GetAVSTemplate",
      requestType: GetAVSTemplateRequest,
      requestStream: false,
      responseType: GetAVSTemplateResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([15, 18, 13, 47, 118, 49, 47, 116, 101, 109, 112, 108, 97, 116, 101, 115])],
        },
      },
    },
    listAllAVSDeploymentAdmin: {
      name: "ListAllAVSDeploymentAdmin",
      requestType: ListAllAVSDeploymentAdminRequest,
      requestStream: false,
      responseType: ListAllAVSDeploymentAdminResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([15, 18, 13, 47, 118, 49, 47, 97, 100, 109, 105, 110, 47, 97, 118, 115])],
        },
      },
    },
    listAllRunningOperatorsAdmin: {
      name: "ListAllRunningOperatorsAdmin",
      requestType: ListAllRunningOperatorsAdminRequest,
      requestStream: false,
      responseType: ListAllRunningOperatorsAdminResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              21,
              18,
              19,
              47,
              118,
              49,
              47,
              97,
              100,
              109,
              105,
              110,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              111,
              114,
              115,
            ]),
          ],
        },
      },
    },
    /** only called by admin */
    terminateOperators: {
      name: "TerminateOperators",
      requestType: TerminateOperatorsRequest,
      requestStream: false,
      responseType: TerminateOperatorsResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              34,
              19,
              47,
              118,
              49,
              47,
              97,
              100,
              109,
              105,
              110,
              47,
              111,
              112,
              101,
              114,
              97,
              116,
              111,
              114,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
