import { Box, Stack, Typography, useTheme } from '@mui/material';
import IconDeploy from 'components/icons/IconDeploy';
import { CSSProperties, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { px } from '../../theme/margins';
import { NavBar } from './NavBar';

export function PageBanner({
  background: _background,
  color: _color,
  title,
}: {
  title: ReactNode;
  background?: CSSProperties['background'];
  color?: CSSProperties['color'];
}) {
  const theme = useTheme();
  const { pistachio, water } = theme.colors.gradients;
  const location = useLocation();
  const isAvsRoute = location.pathname?.includes('/avs');
  const isRaasRoute = location.pathname?.includes('/rollups');
  const background = _background || isAvsRoute ? water : isRaasRoute ? pistachio : water;
  const color = _color || '#202723';

  return (
    <>
      <Box
        sx={{
          background,
        }}
      >
        <Box margin="0 auto" pb={{ md: '40px' }} pt={{ md: '38px' }} px={px}>
          <Stack alignItems="flex-end" flexDirection="row" justifyContent="space-between">
            <Typography color={color} variant="h1">
              {title}
            </Typography>
            <IconDeploy sx={{ color }} />
          </Stack>
        </Box>
        {(isAvsRoute || isRaasRoute) && <NavBar />}
      </Box>
    </>
  );
}
