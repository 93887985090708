import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AVSDeployments } from 'pages/AvsDeployments';
import { AVSDetails } from 'pages/AvsDetails';
import { RaasDeployments } from 'pages/RaasDeployments';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import Layout from './components/Layout';
import { AuthContextProvider } from './contexts/AuthContext';
import { AcceptInvite } from './pages/AcceptInvite';
import { CreateOrganization } from './pages/CreateOrganization';
import { DeploymentDetails } from './pages/DeploymentDetails';
import Disclaimer from './pages/Disclaimer';
import { Login, RequireLogin } from './pages/Login';
import { NewAVS } from './pages/NewAVS';
import { NewDeployment } from './pages/NewDeployment';
import Privacy from './pages/Privacy';
import { inputGlobalStyles } from './theme/globalStyle';

export const PATHS = {
  DEFAULT: '/avs',
  DEPLOYMENTS: '/rollups',
  NEW_DEPLOYMENT: '/rollups/new',
  DEPLOYMENT_DETAILS: '/rollups/:deploymentId',
  AVS_DEPLOYMENTS: '/avs',
  NEW_AVS: '/avs/new',
  AVS_DETAILS: '/avs/:deploymentId',
  SETTINGS: '/settings',
  LOGIN: '/login',
  CREATE_ORG: '/create-org',
  NEW_DEPLOYMENT_DEPR: '/new-deployment',
  ACCEPT_INVITE: '/invite/:id',
};

const DefaultRoute: React.FC = () => {
  return <Navigate to={PATHS.DEFAULT} />;
};

export const routes = [
  {
    element: <Login />,
    path: PATHS.LOGIN,
  },
  {
    element: <AcceptInvite />,
    path: PATHS.ACCEPT_INVITE,
  },
  {
    element: <CreateOrganization />,
    path: PATHS.CREATE_ORG,
  },
  {
    path: PATHS.DEPLOYMENTS,
    element: (
      <RequireLogin>
        <Outlet />
      </RequireLogin>
    ),
    children: [
      { path: '', element: <RaasDeployments /> },
      { path: ':deploymentId', element: <DeploymentDetails /> },
      {
        path: 'new',
        element: <NewDeployment />,
      },
    ],
  },
  {
    path: PATHS.AVS_DEPLOYMENTS,
    element: (
      <RequireLogin>
        <Outlet />
      </RequireLogin>
    ),
    children: [
      { path: '', element: <AVSDeployments /> },
      { path: ':deploymentId', element: <AVSDetails /> },
      {
        path: 'new',
        element: <NewAVS />,
      },
    ],
  },
  {
    element: <Privacy />,
    path: '/privacy',
  },
  {
    element: <Disclaimer />,
    path: '/disclaimer',
  },
  {
    element: <DefaultRoute />,
    path: '*',
  },
];

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
        <AuthContextProvider>
          <Layout>{content}</Layout>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
