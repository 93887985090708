import { BoxProps, StandardTextFieldProps, TextField, Typography } from '@mui/material';
import { Option } from 'components/Option';
import { ReactNode } from 'react';
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form';

import { NumericStepper, NumericStepperProps } from '../NumericStepper';
import { WithInfo } from '../WithInfo';

interface FormTextFieldProps extends StandardTextFieldProps {
  fieldConfig: { name: string; label?: ReactNode; default?: string; placeholder: string };
  controllerProps?: Partial<ControllerProps>;
  numericStepperProps?: Partial<NumericStepperProps>;
  tooltipContent?: string;
  optionProps?: BoxProps;
}

export default function FormTextField({
  controllerProps,
  fieldConfig,
  numericStepperProps,
  onChange,
  optionProps,
  sx,
  tooltipContent,
  ...textFieldProps
}: FormTextFieldProps) {
  const { control, setValue } = useFormContext();
  const fieldValue = useWatch({ control, name: fieldConfig.name });
  let numericInputProps: { endAdornment: ReactNode; type: string };

  const showNumericStepper = textFieldProps.type === 'number' && !textFieldProps.disabled;

  if (showNumericStepper) {
    const setFieldValue = (newValue: string) =>
      setValue(fieldConfig.name, newValue, { shouldTouch: true, shouldDirty: true });

    numericInputProps = {
      type: 'text',
      endAdornment: (
        <NumericStepper setValue={setFieldValue} value={fieldValue} {...numericStepperProps} />
      ),
    };
  }

  return (
    <Controller
      control={control}
      name={fieldConfig.name}
      render={({ field, fieldState }) => {
        return (
          <>
            <Option
              optionTitle={
                tooltipContent ? (
                  <WithInfo
                    info={tooltipContent}
                    text={<Typography variant="bodySmallC">{fieldConfig.label}</Typography>}
                  />
                ) : (
                  fieldConfig.label
                )
              }
              {...optionProps}
            >
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...numericInputProps, ...textFieldProps.InputProps }}
                error={Boolean(fieldState.error)}
                fullWidth
                helperText={fieldState.error?.message || ' '} // Empty space so that layout does not shift when error message appears
                inputRef={field.ref}
                onBlur={() => {
                  if (showNumericStepper) {
                    const val = field.value;
                    const trimmedVal = String(Number(val));

                    if (isNaN(val)) {
                      setValue(field.name, '0', { shouldTouch: true });
                    } else {
                      setValue(field.name, trimmedVal, { shouldTouch: true });
                    }
                  }

                  field.onBlur();
                }}
                onChange={event => {
                  const value = event?.target?.value;

                  if (textFieldProps?.type === 'number' && !/^[0-9.]*$/i.test(value)) {
                    return;
                  }

                  field.onChange(event);
                  onChange?.(event);
                }}
                placeholder={fieldConfig.placeholder}
                ref={undefined}
                sx={{
                  '& .MuiOutlinedInput-root': { background: '#FFFFFF' },
                  mb: -3,
                  ...sx,
                }}
                value={field.value || ''}
                {...textFieldProps}
              />
            </Option>
          </>
        );
      }}
      {...controllerProps}
    />
  );
}
