import { Button, Grid, StandardTextFieldProps, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  UseFormStateReturn,
} from 'react-hook-form';
import { IoMdAdd } from 'react-icons/io';

interface TFieldConfig {
  name: string;
  label: string;
  placeholder?: string;
}

interface RenderFieldProps<T extends FieldValues> {
  field: ControllerRenderProps;
  fieldArray: UseFieldArrayReturn<FieldValues, TFieldConfig['name'], string>;
  fieldArrayConfig: TFieldConfig;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
  index: number;
}

type FormFieldArrayProps<T extends FieldValues> = StandardTextFieldProps &
  (
    | {
        fieldArrayConfig: TFieldConfig;
        addRowText?: string;
        renderAddRowComponent?: never;
        initialRows?: number;
        controllerProps?: Partial<ControllerProps>;
        renderField: (props: RenderFieldProps<T>) => JSX.Element;
      }
    | {
        fieldArrayConfig: TFieldConfig;
        addRowText?: never;
        renderAddRowComponent?: (handleAddRow: () => void) => JSX.Element;
        initialRows?: number;
        controllerProps?: Partial<ControllerProps>;
        renderField: (props: RenderFieldProps<T>) => JSX.Element;
      }
  );

function renderDefaultField<T extends FieldValues>({
  field,
  fieldArray,
  fieldArrayConfig,
  fieldState,
  index,
}: RenderFieldProps<T>) {
  return (
    <Grid alignItems="center" container flexDirection="row" spacing={2}>
      <Grid item xs={8}>
        <TextField
          {...field}
          InputLabelProps={{ shrink: true }}
          error={Boolean(fieldState?.error)}
          fullWidth
          helperText={fieldState?.error?.message}
          inputRef={field.ref}
          label={
            fieldArrayConfig.label ? (
              typeof fieldArrayConfig.label === 'string' ? (
                `${fieldArrayConfig.label} ${index + 1}`
              ) : (
                <Typography>
                  {fieldArrayConfig.label}&nbsp;{index + 1}
                </Typography>
              )
            ) : undefined
          }
          name={field.name}
          placeholder={fieldArrayConfig.placeholder}
          ref={undefined}
          sx={{ my: 1 }}
          value={field.value || ''}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          onClick={() => {
            fieldArray.remove(index);
          }}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  );
}

function FormFieldArray<T extends FieldValues>({
  addRowText,
  controllerProps = {},
  fieldArrayConfig,
  initialRows = 1,
  renderAddRowComponent,
  renderField = renderDefaultField,
}: FormFieldArrayProps<T>) {
  const formContext = useFormContext();
  const fieldArray: UseFieldArrayReturn<FieldValues, TFieldConfig['name'], string> = useFieldArray({
    control: formContext.control,
    name: fieldArrayConfig?.name,
  });

  const handleAddRow = () => {
    fieldArray.append({});
  };

  useEffect(() => {
    if (fieldArray.fields?.length === 0) {
      fieldArray.replace(new Array(initialRows).fill({}));
    }
  }, [fieldArray, fieldArray.fields, initialRows]);

  return (
    <>
      {fieldArray.fields.map((fieldData, index) => {
        const fieldRowName = `${fieldArrayConfig.name}.${index}`; // Updates the fieldArray value at the right index

        return (
          <Controller
            control={formContext.control}
            key={fieldData.id}
            name={fieldRowName}
            render={({ field, fieldState, formState: _formState }) => {
              const formState = _formState as UseFormStateReturn<T>;

              return renderField({
                field,
                fieldState,
                formState,
                index,
                fieldArray,
                fieldArrayConfig,
              });
            }}
            {...controllerProps}
          />
        );
      })}
      {addRowText && (
        <Grid container>
          <Button
            fullWidth
            onClick={handleAddRow}
            startIcon={<IoMdAdd color="#202723" />}
            sx={{
              background: '#FAFAFA',
              color: '#202723',
            }}
            variant="outlined"
          >
            {addRowText}
          </Button>
        </Grid>
      )}
      {renderAddRowComponent && renderAddRowComponent(handleAddRow)}
    </>
  );
}

export default FormFieldArray;
