import { Stack, StackProps, styled } from '@mui/material';
import { ReactNode } from 'react';

export const SectionTitle = styled(
  ({ children, ...props }: { children?: ReactNode } & StackProps) => {
    return <Stack {...props}>{children}</Stack>;
  },
)(
  ({ theme }) => `
  background: linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%);
  align-items: center;
  flex-direction: row;
  padding: ${theme.spacing(5)};
  font-size: 48px;
  line-height: 56px;
  border-bottom: 1px solid #20272314;
  ${theme.breakpoints.down('md')} {
    font-size: 32px;
    line-height: 44px;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 36px;
  }
`,
);
