import { darken, lighten } from '@mui/material';

import { GetComponentStyleFunc } from './types';

export const muiButton: GetComponentStyleFunc<'MuiButton'> = theme => {
  return {
    defaultProps: {
      size: 'large',
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: 'white',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '26px',
          background: theme.colors.functional.container.primary,
          '&:hover': {
            background: darken(theme.colors.functional.container.primary, 0.7),
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '26px',
          background: theme.colors.gradients.metal,
          '&:hover': {
            background: lighten('#E7E7E7', 0.05),
          },
        },
      },
      {
        props: { size: 'large' },
        style: {
          minWidth: '10rem',
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: '0px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '14px',

        '&.MuiLoadingButton-root.MuiLoadingButton-loading > .MuiLoadingButton-loadingIndicator': {
          color: 'white',
        },
        '&.MuiLoadingButton-root.MuiLoadingButton-loading': {
          opacity: 1,
          background: darken(theme.colors.functional.container.primary, 0.3),
        },
      },
      containedSizeLarge: {
        height: '56px',
      },
      outlinedSizeLarge: {
        height: '56px',
      },
      textSizeLarge: {
        height: '56px',
      },
    },
  };
};

export const muiButtonBase: GetComponentStyleFunc<'MuiButtonBase'> = () => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
    },
  };
};
