import { Box, Snackbar, Stack, Theme } from '@mui/material';
import Toast from 'components/Toast';
import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export enum ALERT_SEVERITY {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
}

export const getAlertColor = (severity: ALERT_SEVERITY, theme: Theme) => {
  switch (severity) {
    case ALERT_SEVERITY.SUCCESS:
      return theme.colors.schema.success;
    case ALERT_SEVERITY.PENDING:
      return theme.colors.schema.pending;
    case ALERT_SEVERITY.INFO:
    case ALERT_SEVERITY.DEBUG:
      return theme.colors.schema.info;
    case ALERT_SEVERITY.WARNING:
      return theme.colors.schema.warning;
    case ALERT_SEVERITY.ERROR:
      return theme.colors.schema.error;
    default:
      return 'transparent';
  }
};

export type IAddAlert = (alert: Omit<Alert, 'key'> & { error?: Error }) => string;
type RemoveAlert = (key: string) => void;

interface Alert {
  severity: ALERT_SEVERITY;
  timeout?: number;
  key: string;
  title: string;
  link?: {
    url: string;
    label: string;
  };
  desc?: string;
}

type AlertsContext = {
  addAlert: IAddAlert;
  showReadContractError: (resourceName: string, error: any) => string;
  removeAlert: RemoveAlert;
};

export const AlertsContext = createContext<AlertsContext>({} as AlertsContext);

export const useAlerts = () => {
  return useContext(AlertsContext);
};

export const AlertsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const addAlert: IAddAlert = useCallback(
    ({ desc, error, link, severity, timeout: _timeout, title: _title }) => {
      const defaultTimeout = severity === ALERT_SEVERITY.ERROR ? 20000 : 10000;
      const timeout = _timeout || defaultTimeout;

      const title = _title?.charAt(0)?.toUpperCase() + _title?.substring(1);

      if (error) {
        console.error(error);
      }

      const key = `${severity}_${title}_${desc}`;

      setAlerts(state => {
        if (state.find(cur => cur.key === key)) {
          // do not add if alert already exists
          return state;
        }

        return [{ severity, title, timeout, key, link, desc }, ...state];
      });

      if (timeout !== Infinity) {
        setTimeout(() => {
          // remove alert after timeout
          setAlerts(state => state.filter(cur => cur.key !== key));
        }, timeout);
      }

      return key;
    },
    [],
  );

  const removeAlert: RemoveAlert = key => {
    setAlerts(state => state.filter(cur => cur.key !== key));
  };

  const filteredAlerts = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      return alerts;
    }

    return alerts.filter(cur => cur.severity !== ALERT_SEVERITY.DEBUG);
  }, [alerts]);

  const showReadContractError = useCallback(
    (resourceName: string, error: any) => {
      const errMsg = error?.message?.match(/"status":(\d{3})/im)?.[1];
      const key = addAlert({
        title: `Failed to fetch ${resourceName}`,
        severity: ALERT_SEVERITY.WARNING,
        desc: errMsg ? `Status: ${errMsg}` : 'Retrying with a different RPC',
        error,
      });

      return key;
    },
    [addAlert],
  );

  return (
    <AlertsContext.Provider value={{ addAlert, showReadContractError, removeAlert }}>
      {children}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={filteredAlerts?.length > 0}
      >
        <Box>
          <Stack direction="column" spacing={1}>
            {filteredAlerts?.map(cur => (
              <Toast
                desc={cur.desc}
                duration={cur.timeout}
                key={cur.key}
                link={cur.link}
                onClose={() => {
                  removeAlert(cur.key);
                }}
                severity={cur.severity}
                title={cur.title}
              />
            ))}
          </Stack>
        </Box>
      </Snackbar>
    </AlertsContext.Provider>
  );
};
