import { GetComponentStyleFunc } from './types';

export const muiTooltip: GetComponentStyleFunc<'MuiTooltip'> = theme => {
  return {
    styleOverrides: {
      tooltip: {
        border: '1px solid rgba(32, 39, 35, 0.06)',
        boxShadow: '0px 2px 4px 0px rgba(32, 39, 35, 0.08)',
        backgroundColor: 'white',
        color: theme.colors.functional.text.primary,
        fontSize: '13px',
        padding: '8px 12px',
      },
      arrow: {
        color: 'white',
      },
    },
  };
};
