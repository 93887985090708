import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconPolygon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '40px', height: '40px', ...sx }} viewBox="0 0 40 40" {...props}>
      <path
        clipRule="evenodd"
        d="M39 20C39 30.4424 30.4576 39 20 39C9.55756 39 1 30.4576 1 20C1 9.55756 9.54239 1 20 1C30.4424 1 39 9.54239 39 20ZM30.8834 21.0034L25.5026 24.1042L25.533 24.089C25.2442 24.2562 24.8946 24.2562 24.6058 24.089L22.5842 22.9186V20.0002L25.0618 21.429L28.8314 19.2554V14.9082L25.0618 12.7346L21.353 14.8778L21.2922 14.9234V26.0194C21.2922 26.3538 21.125 26.6578 20.8362 26.825L15.4554 29.9257C15.1666 30.093 14.817 30.093 14.5282 29.9257L9.14742 26.825C8.87381 26.6578 8.69141 26.3538 8.69141 26.0194V19.8178C8.69141 19.4833 8.85863 19.1794 9.14742 19.0122L14.5282 15.9114C14.665 15.8354 14.817 15.7898 14.9842 15.7898C15.1362 15.7898 15.3034 15.8354 15.4402 15.9114L17.4618 17.0818V20.0002L14.969 18.5714L11.1994 20.745V25.0922L14.969 27.2658L18.7386 25.0922V13.9962C18.7386 13.6618 18.9058 13.3578 19.1946 13.1906L24.5754 10.0898C24.8642 9.92255 25.2138 9.92255 25.5026 10.0898L30.8834 13.1906C31.157 13.3578 31.3394 13.6618 31.3394 13.9962V20.1978C31.3394 20.5322 31.1722 20.8361 30.8834 21.0034Z"
        fill="url(#paint0_linear_2077_1065)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2077_1065"
          x1="1"
          x2="39"
          y1="20"
          y2="20"
        >
          <stop stopColor="#A229C5" />
          <stop offset="1" stopColor="#7B3FE4" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
