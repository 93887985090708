export default [
  {
    type: 'constructor',
    inputs: [
      {
        name: 'delegationManager_',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'avsDirectory_',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'avsDirectory',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'createECDSA',
    inputs: [
      {
        name: 'initialOwner_',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'thresholdWeight_',
        type: 'uint256',
        internalType: 'uint256',
      },
      {
        name: 'strategies_',
        type: 'tuple[]',
        internalType: 'struct StrategyParams[]',
        components: [
          {
            name: 'strategy',
            type: 'address',
            internalType: 'contract IStrategy',
          },
          {
            name: 'multiplier',
            type: 'uint96',
            internalType: 'uint96',
          },
        ],
      },
      {
        name: 'serviceManagerSourceCode',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'serviceManagerConstructorArgsSuffix',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'serviceManagerInitData',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'creationCodes',
    inputs: [
      {
        name: '',
        type: 'uint8',
        internalType: 'enum ContractType',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'delegationManager',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'emptyContract',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'finalizeCreation',
    inputs: [
      {
        name: 'serviceManagerSourceCode',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'serviceManagerConstructorArguments',
        type: 'bytes',
        internalType: 'bytes',
      },
      {
        name: 'serviceManagerInitData',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'initializeCreation',
    inputs: [
      {
        name: 'initialOwner_',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'churnApprover_',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'ejector_',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'operatorSetParams_',
        type: 'tuple[]',
        internalType: 'struct IRegistryCoordinator.OperatorSetParam[]',
        components: [
          {
            name: 'maxOperatorCount',
            type: 'uint32',
            internalType: 'uint32',
          },
          {
            name: 'kickBIPsOfOperatorStake',
            type: 'uint16',
            internalType: 'uint16',
          },
          {
            name: 'kickBIPsOfTotalStake',
            type: 'uint16',
            internalType: 'uint16',
          },
        ],
      },
      {
        name: 'minimumStakes_',
        type: 'uint96[]',
        internalType: 'uint96[]',
      },
      {
        name: 'strategyParams_',
        type: 'tuple[][]',
        internalType: 'struct IStakeRegistry.StrategyParams[][]',
        components: [
          {
            name: 'strategy',
            type: 'address',
            internalType: 'contract IStrategy',
          },
          {
            name: 'multiplier',
            type: 'uint96',
            internalType: 'uint96',
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'ongoingDeployments',
    inputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [
      {
        name: 'initialOwner',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'proxyAdmin',
        type: 'address',
        internalType: 'contract ProxyAdmin',
      },
      {
        name: 'pauserRegistry',
        type: 'address',
        internalType: 'contract IPauserRegistry',
      },
      {
        name: 'indexRegistryProxy',
        type: 'address',
        internalType: 'address payable',
      },
      {
        name: 'stakeRegistryProxy',
        type: 'address',
        internalType: 'address payable',
      },
      {
        name: 'apkRegistryProxy',
        type: 'address',
        internalType: 'address payable',
      },
      {
        name: 'registryCoordinatorProxy',
        type: 'address',
        internalType: 'address payable',
      },
      {
        name: 'serviceManagerProxy',
        type: 'address',
        internalType: 'address payable',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'operatorStateRetriever',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'contract OperatorStateRetriever',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'setCreationCode',
    inputs: [
      {
        name: 'contractType',
        type: 'uint8',
        internalType: 'enum ContractType',
      },
      {
        name: 'bytecode',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    name: 'Created',
    inputs: [
      {
        name: 'initialOwner',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'proxyAdmin',
        type: 'address',
        indexed: false,
        internalType: 'contract ProxyAdmin',
      },
      {
        name: 'stakeRegistryProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'serviceManagerProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CreationCodeSet',
    inputs: [
      {
        name: 'contractType',
        type: 'uint8',
        indexed: true,
        internalType: 'enum ContractType',
      },
      {
        name: 'bytecode',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CreationFinalized',
    inputs: [
      {
        name: 'finalizer',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'serviceManagerSourceCodeHash',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32',
      },
      {
        name: 'serviceManagerConstructorArguments',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes',
      },
      {
        name: 'serviceManagerInitData',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes',
      },
    ],
    anonymous: false,
  },
  {
    type: 'event',
    name: 'CreationInitialized',
    inputs: [
      {
        name: 'initializer',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'initialOwner',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'proxyAdmin',
        type: 'address',
        indexed: false,
        internalType: 'contract ProxyAdmin',
      },
      {
        name: 'pauserRegistry',
        type: 'address',
        indexed: false,
        internalType: 'contract IPauserRegistry',
      },
      {
        name: 'indexRegistryProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'stakeRegistryProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'apkRegistryProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'registryCoordinatorProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
      {
        name: 'serviceManagerProxy',
        type: 'address',
        indexed: false,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
  {
    type: 'error',
    name: 'AlreadySet',
    inputs: [],
  },
  {
    type: 'error',
    name: 'NoOngoingDeployment',
    inputs: [],
  },
  {
    type: 'error',
    name: 'ZeroAddress',
    inputs: [],
  },
  {
    type: 'error',
    name: 'ZeroValue',
    inputs: [],
  },
] as const;
