import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconDeploy({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="222"
      sx={{ width: '379px', height: '222px', ...sx }}
      viewBox="0 0 379 222"
      width="379"
      {...props}
    >
      <path
        d="M189.319 32.3954L283.506 1L377.692 32.3954M189.319 32.3954L283.506 63.7909M189.319 32.3954V63.7909L283.506 95.1863M377.692 32.3954L283.506 63.7909M377.692 32.3954V63.7909L283.506 95.1863M283.506 63.7909V95.1863"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.13333"
      />
      <path
        d="M95.1328 95.2754L189.319 63.88L283.505 95.2754M95.1328 95.2754L189.319 126.671M95.1328 95.2754V126.671L189.319 158.066M283.505 95.2754L189.319 126.671M283.505 95.2754V126.671L189.319 158.066M189.319 126.671V158.066"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.13333"
      />
      <path
        d="M1 158.209L95.1863 126.814L189.373 158.209M1 158.209L95.1863 189.605M1 158.209V189.605L95.1863 221M189.373 158.209L95.1863 189.605M189.373 158.209V189.605L95.1863 221M95.1863 189.605V221"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.13333"
      />
    </SvgIcon>
  );
}
