import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { ChangeEventHandler, FormEventHandler, useState } from 'react';

import { axiosAuth } from '../../api';

export function InviteTeamMember() {
  const [emailInput, setEmailInput] = useState('');
  const { addAlert } = useAlerts();

  const { isError, isPending, mutate } = useMutation({
    mutationFn: async (email: string) => {
      await axiosAuth.post('/org/invite', { email });
    },
    onSuccess: (res, email) => {
      addAlert({ title: `Invitation sent to: ${email}`, severity: ALERT_SEVERITY.SUCCESS });
      console.debug('invitation email res: ', res);
    },
    onError: (_err, email) => {
      const err = _err as AxiosError<{ message: string }>;

      addAlert({
        title: `Failed to invite ${email}.`,
        severity: ALERT_SEVERITY.ERROR,
        desc: err?.response?.data?.message,
      });
      console.error('error: ', err);
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const input = e?.target?.value;

    setEmailInput(input);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e?.preventDefault();
    mutate(emailInput);
  };

  return (
    <Stack
      alignItems="center"
      component="form"
      gap={2}
      justifyContent="center"
      onSubmit={handleSubmit}
      sx={{ flex: 2, background: '#fff' }}
    >
      <TextField
        error={isError}
        helperText={isError ? 'Failed to send invitation mail.' : ''}
        onChange={handleChange}
        placeholder="Email address"
        sx={{ width: '20rem' }}
        type="email"
        value={emailInput}
      />
      <LoadingButton loading={isPending} sx={{ width: '20rem' }} type="submit" variant="contained">
        Invite Team Member
      </LoadingButton>
    </Stack>
  );
}
