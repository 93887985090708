import { GetComponentStyleFunc } from './types';

export const muiPopover: GetComponentStyleFunc<'MuiPopover'> = theme => {
  return {
    styleOverrides: {
      paper: {
        background: theme.colors.functional.container.default,
      },
    },
  };
};
