/**
 * @description
 * This component is needed to achieve proper typing on the MUI Card component's color prop.
 * This is because the default MUI Card component does not define an interface suitable for module augmentation,
 * such as ButtonPropsVariantOverrides in the MUI Button component.
 *
 * Styles of each color variant should be changed in the theme.
 */

import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material';

type PropOverrides = { color?: 'default' | 'highContrast' };

export type CardProps = MuiCardProps & PropOverrides;

function Card({ children, color = 'default', ...rest }: CardProps) {
  return (
    <MuiCard color={color} {...rest}>
      {children}
    </MuiCard>
  );
}

export default Card;
