import { StepIcon, StepIconProps } from '@mui/material';

export default function CustomStepIcon(props: StepIconProps) {
  return (
    <StepIcon
      {...props}
      sx={{
        '&&': {
          color: props?.completed ? 'black' : 'white',
          zIndex: 2,
          position: 'relative',
          border: !props?.completed ? '1px solid black' : 'none',
          borderRadius: '50%',
        },
      }}
    />
  );
}
