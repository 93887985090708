import { Box, BoxProps, FormHelperText, Grid, Typography } from '@mui/material';
import FormTextField from 'components/form-fields/FormTextField';
import { ReactNode, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { AVSFormValues } from '..';

export default function CustomArgs({
  fieldArrName,
  label,
  sx,
  ...props
}: {
  fieldArrName: 'initArgs' | 'constructorArgs';
  label?: ReactNode;
} & BoxProps) {
  const { control, formState, register } = useFormContext<AVSFormValues>();

  const customTemplate = useWatch<AVSFormValues>({ name: 'customTemplate' });

  const fieldArray = useFieldArray<AVSFormValues>({
    name: fieldArrName,
    control,
  });

  useEffect(() => {
    const abi = customTemplate?.abi;

    if (customTemplate && !fieldArray.fields.length) {
      if (fieldArrName === 'initArgs') {
        fieldArray.replace(abi?.find((cur: any) => cur.name === 'initialize')?.inputs);
      }

      if (fieldArrName === 'constructorArgs') {
        fieldArray.replace(abi?.find((cur: any) => cur.type === 'constructor')?.inputs);
      }
    }

    if (!customTemplate && fieldArray.fields.length) {
      fieldArray.replace([]);
    }
  }, [customTemplate, fieldArrName, fieldArray]);

  return !customTemplate ? null : (
    <Box id={`step_${fieldArrName}`} mt={5} sx={sx} {...props}>
      {label}
      <Typography variant="bodySmall">
        {fieldArrName === 'initArgs'
          ? `initialize(${fieldArray.fields.map(cur => cur?.type)?.join(',')})`
          : `constructor(${fieldArray.fields.map(cur => cur?.type)?.join(',')})`}
      </Typography>
      <Grid alignItems="center" container spacing={2}>
        {fieldArray.fields.map((fieldData, index) => {
          return (
            <Controller
              control={control}
              key={fieldData.id}
              name={`${fieldArrName}.${index}`}
              render={({ field, formState: _formState }) => {
                return (
                  <Grid item lg={6} xs={12}>
                    <FormTextField
                      fieldConfig={{
                        name: `${field?.name}.value`,
                        label: (
                          <Typography sx={{ textTransform: 'none' }} variant="bodySmall">
                            [{index}] {fieldData?.name}
                          </Typography>
                        ),
                        placeholder: `(${fieldData.type})`,
                      }}
                      id="step_avsName"
                      optionProps={{ sx: { '&&': { pl: 0 } } }}
                    />
                  </Grid>
                );
              }}
              {...register(fieldArrName).ref}
            />
          );
        })}
        {formState.errors?.chains?.root?.message && (
          <FormHelperText error>{String(formState?.errors?.chains?.root?.message)}</FormHelperText>
        )}
      </Grid>
    </Box>
  );
}
