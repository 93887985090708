export default [
  {
    type: 'function',
    name: 'changeProxyAdmin',
    inputs: [
      {
        name: 'proxy',
        type: 'address',
        internalType: 'contract TransparentUpgradeableProxy',
      },
      {
        name: 'newAdmin',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'getProxyAdmin',
    inputs: [
      {
        name: 'proxy',
        type: 'address',
        internalType: 'contract TransparentUpgradeableProxy',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'getProxyImplementation',
    inputs: [
      {
        name: 'proxy',
        type: 'address',
        internalType: 'contract TransparentUpgradeableProxy',
      },
    ],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'owner',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address',
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    name: 'renounceOwnership',
    inputs: [],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'transferOwnership',
    inputs: [
      {
        name: 'newOwner',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'upgrade',
    inputs: [
      {
        name: 'proxy',
        type: 'address',
        internalType: 'contract TransparentUpgradeableProxy',
      },
      {
        name: 'implementation',
        type: 'address',
        internalType: 'address',
      },
    ],
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    name: 'upgradeAndCall',
    inputs: [
      {
        name: 'proxy',
        type: 'address',
        internalType: 'contract TransparentUpgradeableProxy',
      },
      {
        name: 'implementation',
        type: 'address',
        internalType: 'address',
      },
      {
        name: 'data',
        type: 'bytes',
        internalType: 'bytes',
      },
    ],
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'event',
    name: 'OwnershipTransferred',
    inputs: [
      {
        name: 'previousOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
      {
        name: 'newOwner',
        type: 'address',
        indexed: true,
        internalType: 'address',
      },
    ],
    anonymous: false,
  },
] as const;
