import { GetComponentStyleFunc } from './types';

export const muiTypography: GetComponentStyleFunc<'MuiTypography'> = theme => {
  return {
    defaultProps: {
      variant: 'body1',
      variantMapping: {
        h1: 'h1',
        h2: 'div',
        h3: 'div',
        h4: 'div',
        h5: 'div',
        h6: 'div',
        subtitle1: 'div',
        subtitle2: 'div',
        body1: 'div',
        body2: 'div',
      },
    },
    styleOverrides: {
      paragraph: {},
      button: {},
      inherit: {},
      overline: {},
      h1: {
        fontSize: '48px',
        lineHeight: '56px',
        [theme.breakpoints.up('md')]: {
          fontSize: '80px',
          lineHeight: '80px',
        },
      },
      h2: {
        fontWeight: 400,
        fontSize: '80px',
        lineHeight: '80px',
        letterSpacing: '-3.2px',
      },
      h3: {
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '-1.44px',
      },
      h4: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
      },
      h5: {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '-0.48px',
      },
      h5C: {
        // Figma: Heading 5 C
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '1.44px',
      },
      h6: {},
      subtitle1: {
        fontSize: '13px',
        lineHeight: '18px',
      },
      subtitle2: {
        fontSize: '13px',
        lineHeight: '20px',
      },
      caption: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18px',
      },
      captionC: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.78px',
        textTransform: 'uppercase',
      },
      captionM: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
      },
      body1: {
        // Figma: Body Small
        fontSize: '15px',
        lineHeight: '20px',
      },
      body2: {
        // Figma name: Body Medium
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '26px',
      },
      bodySmallM: {
        // Figma name: Body Small M
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '20px',
      },
      bodySmallC: {
        // Figma name: BODY SMALL C
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.9px',
        textTransform: 'uppercase',
      },
      bodyMediumC: {
        // Figma name: BODY MEDIUM C
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '26px',
        letterSpacing: '1.08px',
        textTransform: 'uppercase',
      },
      bodyBold: {
        // Figma name: Body
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
  };
};
