import { Button, Stack, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { verifyPromoCode } from '../../api/auth';

export function VerifyPromoCode() {
  const [promoCodeInput, setPromoCodeInput] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const setPromoCodeParam = (code: string) => {
    const params = Array.from(searchParams.entries()).reduce(
      (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
      {},
    );

    setSearchParams({
      ...params,
      code,
    });
  };

  const {
    error,
    isError,
    mutate: verifyCode,
  } = useMutation({
    mutationFn: verifyPromoCode,
    onError: err => {
      setPromoCodeParam('');
      console.error('error: ', err);
    },
    onSuccess: (_, reqPayload) => {
      setPromoCodeParam(reqPayload || '');
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const input = e?.target?.value;

    setPromoCodeInput(input);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e?.preventDefault();
    verifyCode(promoCodeInput);
  };

  return (
    <Stack
      alignItems="center"
      component="form"
      gap={2}
      justifyContent="center"
      onSubmit={handleSubmit}
      sx={{ flex: 2, background: '#fff' }}
    >
      <TextField
        error={isError}
        helperText={
          error
            ? (error as AxiosError<{ message: string }>)?.response?.data?.message ||
              'Invalid promo code.'
            : ''
        }
        onChange={handleChange}
        placeholder="PROMO123"
        sx={{ width: '20rem' }}
        type="text"
        value={promoCodeInput}
      />
      <Button sx={{ width: '20rem' }} type="submit">
        Verify Promo Code
      </Button>
    </Stack>
  );
}
