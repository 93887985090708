import axios, { AxiosRequestHeaders } from 'axios';

import { authUtil } from '../utils/auth';

export const axiosRaas = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL || 'https://localhost:3001/api',
});

axiosRaas.interceptors.request.use(config => {
  config.headers = { ...authUtil.getAuthHeaders(), ...config.headers } as AxiosRequestHeaders;
  config.withCredentials = true;

  return config;
});

axiosAuth.interceptors.request.use(config => {
  config.headers = { ...authUtil.getAuthHeaders(), ...config.headers } as AxiosRequestHeaders;
  config.withCredentials = true;

  return config;
});
