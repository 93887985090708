import { Button, ButtonProps, Typography } from '@mui/material';
import { FC } from 'react';

const MaxButton: FC<ButtonProps> = ({ onClick, sx, ...rest }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: '#202723',
        // pb: 0.25,
        px: 1,
        minWidth: 'unset',
        minHeight: 'unset',
        height: '1.5rem',
        ...sx,
      }}
      variant="text"
      {...rest}
    >
      <Typography variant="bodySmallC">MAX</Typography>
    </Button>
  );
};

export default MaxButton;
