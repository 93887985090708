import * as yup from 'yup';

export const deploymentNameValidation = yup
  .string()
  .required()
  .noUrl()
  .min(3)
  .max(20)
  .matches(/^[0-9a-z-]+$/, "Please use lowercase alphanumeric characters or '-' only.")
  .matches(/^[^-].*[^-]$/, "Cannot begin or end with '-'.")
  .matches(/^(?!.*-{2}).*$/, "'-' cannot be used consecutively.")
  .matches(/[a-z0-9]([-a-z0-9]*[a-z0-9])/, 'Does not match regex [a-z0-9]([-a-z0-9]*[a-z0-9])')
  .noLeadingDigit();

export const fnArgsValidation = yup.array().of(
  yup
    .object()
    .shape({
      type: yup.string(),
      value: yup
        .string()
        .when('type', { is: 'address', then: schema => schema.address() })
        .when('type', {
          is: (type: string) => {
            return type.startsWith('uint');
          },
          then: schema => schema.matches(/^\d+$/, 'Please enter integer values only'),
        })
        .required(),
    })
    .required(),
);
