import { GetComponentStyleFunc } from './types';

export const muiLink: GetComponentStyleFunc<'MuiLink'> = theme => {
  return {
    styleOverrides: {
      root: {
        fontSize: '13px',
        lineHeight: '18px',
        letterSpacing: '0.06em',
        fontWeight: 500,
        textDecoration: 'none',
        color: theme.colors.functional.text.primary,
      },
    },
  };
};
