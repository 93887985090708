import { Box, CircularProgress, Collapse, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchAllAggregatorsStatus, fetchAllAvs, fetchGlobalPublicAvs } from 'api/avs';
import { useState } from 'react';
import { IoAddSharp, IoArrowForwardSharp } from 'react-icons/io5';
import { PiCaretDownBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { AVS_TYPES } from 'types/avs';
import { AVSDeployment, GetAVSStatusByNameResponse } from 'types/protoc-gen/avs';

import { PATHS } from '../../App';
import { PageBanner } from '../../components/Layout/PageBanner';
import { avsStatusLabelMap, avsTypeLabels } from '../../labels/deployments';
import { QUERY_KEYS } from '../../types/react-query';
import { GlobalAvsDeployments } from './GlobalAvsDeployments';

function AvsDeploymentCard({
  aggregator,
  avsDeployment,
}: {
  avsDeployment: AVSDeployment;
  aggregator?: GetAVSStatusByNameResponse;
}) {
  const navigate = useNavigate();

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={() => navigate(`/avs/${avsDeployment.deploymentId}`)}
        sx={{
          cursor: 'pointer',
          background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
          px: 5,
          py: 3,
        }}
      >
        <Typography
          alignItems="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          variant="bodyBold"
        >
          {
            avsStatusLabelMap[
              String(
                avsDeployment?.avsType === AVS_TYPES.ECDSA_HELLO_WORLD
                  ? 'running'
                  : aggregator?.status,
              )
            ]
          }
          {avsDeployment.avsName}
        </Typography>
        <Box component={IoArrowForwardSharp} size={24} />
      </Stack>
      <Stack
        px={5}
        sx={{ background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)', minHeight: '8rem' }}
      >
        <Stack direction="row" py={3}>
          <Stack flex={1}>
            <Typography variant="caption">Type</Typography>
            <Typography variant="bodySmallM">
              {avsTypeLabels[avsDeployment.avsType as AVS_TYPES]}
            </Typography>
          </Stack>
          {/* <Stack flex={1}>
            <Typography variant="caption">Framework</Typography>
            <Typography variant="bodySmallM">To Do</Typography>
          </Stack> */}
        </Stack>
      </Stack>
    </Stack>
  );
}

export function AVSDeployments() {
  const navigate = useNavigate();
  const {
    data: avsDeployments,
    isFetching: isFetchingAvsDeployments,
    isLoading: isLoadingAvsDeployments,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_DEPLOYMENTS],
    queryFn: fetchAllAvs,
  });
  const {
    data: aggregators,
    isFetching: isFetchingAggregators,
    isLoading: isLoadingAggregators,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS],
    queryFn: fetchAllAggregatorsStatus,
  });

  const [showGlobal, setShowGlobal] = useState(false);
  const {
    data: globalAvsDeployments,
    isFetching: isFetchingGlobalAvs,
    isLoading: isLoadingGlobalAvs,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_GLOBAL_AVS],
    queryFn: fetchGlobalPublicAvs,
    enabled: Boolean(showGlobal),
  });

  return (
    <>
      <PageBanner title="AVS Dashboard" />
      <Grid
        alignItems="center"
        container
        gap={2}
        sx={{ background: '#fafafa', px: 5, py: 4, border: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <Typography variant="h5">Your Deployments</Typography>
        {((isLoadingAvsDeployments && isFetchingAvsDeployments) ||
          (isLoadingAggregators && isFetchingAggregators)) && <CircularProgress size={20} />}
      </Grid>
      <Grid
        container
        sx={{ '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' } }}
      >
        {avsDeployments?.map(avs => (
          <Grid item key={avs.deploymentId} xs={4}>
            <AvsDeploymentCard
              aggregator={aggregators?.find(agg => agg.avsName === avs?.avsName)}
              avsDeployment={avs}
            />
          </Grid>
        ))}
        <Grid
          alignItems="center"
          container
          flexDirection="column"
          item
          justifyContent="center"
          onClick={() => navigate(PATHS.NEW_AVS)}
          sx={{ bgcolor: '#fff', cursor: 'pointer' }}
          xs={4}
        >
          <IoAddSharp size={24} />
          <Typography mt={3} variant="bodySmallC">
            New AVS
          </Typography>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        onClick={() => setShowGlobal(prev => !prev)}
        sx={{
          background: '#fafafa',
          px: 5,
          py: 4,
          border: '1px solid rgba(32, 39, 35, 0.08)',
          cursor: 'pointer',
        }}
      >
        <Typography variant="h5">{showGlobal ? '' : 'Browse '} Global AVS</Typography>
        <Box
          component={PiCaretDownBold}
          size={20}
          sx={{
            transform: showGlobal ? 'rotate(-180deg)' : 'none',
            transition: 'all 0.2s ease-in-out',
          }}
        />
      </Grid>
      <Collapse in={showGlobal} mountOnEnter>
        <GlobalAvsDeployments
          data={globalAvsDeployments?.avsDeployment || ([] as AVSDeployment[])}
          isLoading={isLoadingGlobalAvs && isFetchingGlobalAvs}
        />
      </Collapse>
    </>
  );
}
