import '@rainbow-me/rainbowkit/styles.css';

import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Link,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { activateAvs as _activateAvs } from 'api/avs';
import CustomStepIcon from 'components/CustomStepIcon';
import IconLink from 'components/icons/IconLink';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useAvsCreator } from 'hooks/useAvsCreator';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { QUERY_KEYS } from 'types/react-query';
import { truncate } from 'utils/strings';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';

import { avsTypeOptions } from './form-steps/SelectAVSType';
import { AVSFormValues } from '.';

export function DeployAvsPopup({ ...props }: DialogProps) {
  const { chain, isConnected } = useAccount();
  const isReady = chain && isConnected;
  const { addAlert } = useAlerts();
  const avsType = useWatch<AVSFormValues>({ name: 'avsType' });
  const avsQuorums = useWatch<AVSFormValues>({ name: 'strategies' });
  const avsName = useWatch<AVSFormValues>({ name: 'avsName' });
  const chains = useWatch<AVSFormValues>({ name: 'chains' });
  const thresholdWeight = useWatch<AVSFormValues>({ name: 'ecdsaWeight' });
  const customTemplate = useWatch<AVSFormValues>({ name: 'customTemplate' });
  const initArgs = useWatch<AVSFormValues>({ name: 'initArgs' });
  const constructorArgs = useWatch<AVSFormValues>({ name: 'constructorArgs' });

  const queryClient = useQueryClient();

  const {
    data: activateAvsRes,
    error: activateAvsErr,
    isPending: isActivatingAvs,
    mutate,
  } = useMutation({
    mutationFn: _activateAvs,
    mutationKey: [QUERY_KEYS.CREATE_AVS],
    onSuccess: res => {
      addAlert({
        title: `Successfully deployed AVS ${res?.avsDeployment?.avsName}`,
        severity: ALERT_SEVERITY.SUCCESS,
      });
      setActiveStep(3);
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.GET_AVS_DEPLOYMENTS] });
      queryClient?.invalidateQueries({ queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS] });
    },
    retry: 4,
    retryDelay: failureCount => failureCount ** 2 * 1000, // wait for [1,4,9,16]s (total = 30s) before showing error
  });

  const activateAvs = useCallback(
    (txHash: Hash) => {
      if (chain?.id && txHash && avsType >= 0 && avsName) {
        mutate({
          chainId: chain?.id,
          transactionHash: txHash,
          avsName,
          avsType,
          quorumNumbers: avsQuorums,
        });
      } else {
        console.error('Missing fields: ', {
          chainId: chain?.id,
          transactionHash: txHash,
          avsName,
          avsType,
          quorumNumbers: avsQuorums,
        });
      }
    },
    [avsName, avsQuorums, avsType, chain?.id, mutate],
  );

  const {
    createECDSA,
    createECDSATxHash,
    finalizeCreation,
    finalizeTxHash,
    initTxHash,
    initializeCreation,
    isCreatingECDSA,
    isFinalizing,
    isInitPending,
    status,
  } = useAvsCreator({
    onCreationInitialized: () => {
      setActiveStep(2);
    },
    onCreationFinalized: txHash => {
      activateAvs(txHash);
    },
    thresholdWeight,
    avsQuorums,
    avsType,
    chains,
    customTemplate: customTemplate
      ? {
          ...customTemplate,
          initArgs: initArgs?.map((cur: any) => cur?.value) || [],
          constructorArgs: constructorArgs?.map((cur: any) => cur?.value) || [],
        }
      : undefined,
  });

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (activeStep === 0 && isReady) {
      setActiveStep(1);
    }

    if (!isReady) {
      setActiveStep(0);
    }
  }, [isReady, activeStep]);

  const steps: {
    title: ReactNode;
    description?: ReactNode;
    content?: ReactNode;
    actionBar: ReactNode;
  }[] = useMemo(
    () => [
      {
        title: <Typography>Connect your ethereum wallet</Typography>,
        description: isReady && (
          <Box sx={{ '& button>div:first-of-type': { pl: 0 } }}>
            <ConnectButton />
          </Box>
        ),
        actionBar: !isReady && <ConnectButton />,
      },
      ...(avsTypeOptions?.find(cur => cur.value === avsType)?.sig === 'ecdsa'
        ? [
            {
              title: 'Create AVS',
              description: createECDSATxHash ? (
                <Link
                  href={`${chain?.blockExplorers?.default?.url}/tx/${createECDSATxHash}`}
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}
                  target="_blank"
                >
                  Txn hash: {truncate(createECDSATxHash, undefined, 5, 3)}
                  <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
                </Link>
              ) : (
                ''
              ),
              actionBar: (
                <Web3LoadingButton
                  fullWidth
                  loading={isCreatingECDSA || isActivatingAvs}
                  onClick={() =>
                    activateAvsErr ? activateAvs(createECDSATxHash as Hash) : createECDSA()
                  }
                  variant="contained"
                >
                  {activateAvsErr ? 'Retry' : 'Create'}
                </Web3LoadingButton>
              ),
            },
          ]
        : [
            {
              title: 'Deploy base contracts',
              actionBar: (
                <Web3LoadingButton
                  fullWidth
                  loading={isInitPending}
                  onClick={initializeCreation}
                  variant="contained"
                >
                  Deploy
                </Web3LoadingButton>
              ),
              description: initTxHash ? (
                <Link
                  href={`${chain?.blockExplorers?.default?.url}/tx/${initTxHash}`}
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}
                  target="_blank"
                >
                  Txn hash: {truncate(initTxHash, undefined, 5, 3)}
                  <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
                </Link>
              ) : (
                ''
              ),
            },
            {
              title: 'Deploy service manager contract',
              actionBar: (
                <Web3LoadingButton
                  disabled={status !== 'readyForFinalize' && !activateAvsErr}
                  fullWidth
                  loading={isFinalizing || isActivatingAvs}
                  onClick={() =>
                    activateAvsErr ? activateAvs(initTxHash as Hash) : finalizeCreation()
                  }
                  variant="contained"
                >
                  {activateAvsErr ? 'Retry' : 'Deploy'}
                </Web3LoadingButton>
              ),
              description: finalizeTxHash ? (
                <Link
                  href={`${chain?.blockExplorers?.default?.url}/tx/${finalizeTxHash}`}
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}
                  target="_blank"
                >
                  Txn hash: {truncate(finalizeTxHash, undefined, 5, 3)}
                  <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
                </Link>
              ) : (
                ''
              ),
            },
          ]),
    ],
    [
      isReady,
      createECDSATxHash,
      chain?.blockExplorers?.default?.url,
      isCreatingECDSA,
      isActivatingAvs,
      activateAvsErr,
      isInitPending,
      initializeCreation,
      initTxHash,
      status,
      isFinalizing,
      finalizeTxHash,
      avsType,
      activateAvs,
      createECDSA,
      finalizeCreation,
    ],
  );

  return (
    <Dialog disablePortal sx={{ '& .MuiPaper-root': { p: 5 } }} {...props}>
      <Typography variant="h5">Deploy your AVS</Typography>
      <Stepper activeStep={activeStep} connector={null} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={index}
            sx={
              index !== steps?.length - 1
                ? {
                    position: 'relative',
                    '& .MuiStepLabel-iconContainer:after': {
                      content: "' '",
                      height: 'calc(100% - 24px)',
                      borderRight: '1px solid black',
                      position: 'absolute',
                      left: '11px',
                      top: '32px',
                      zIndex: 0,
                    },
                  }
                : {}
            }
          >
            <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
              <Typography mt={0.25}>{step.title}</Typography> {step.description}
            </StepLabel>

            <StepContent>
              <Typography>{step.content}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Stack alignItems="center" justifyContent="center" mt={2} width="100%">
        {activeStep >= steps?.length && activateAvsRes?.avsDeployment?.deploymentId ? (
          <Stack gap={2} width="100%">
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#66B489',
                fontWeight: 500,
              }}
            >
              🎉&nbsp;You&apos;re all set!
            </Typography>
            <Button
              component={RouterLink}
              fullWidth
              to={`/avs/${activateAvsRes?.avsDeployment?.deploymentId}`}
              variant="contained"
            >
              View your AVS
            </Button>
          </Stack>
        ) : (
          steps?.[activeStep]?.actionBar
        )}
      </Stack>
    </Dialog>
  );
}
