import { Box, BoxProps, Grid, Link, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import SelectableCard from '../../../components/Card/SelectableCard';
import IconLink from '../../../components/icons/IconLink';
import IconMainnet from '../../../components/icons/IconMainnet';
import IconTestnet from '../../../components/icons/IconTestnet';
import { Option } from '../../../components/Option';
import { SETTLEMENT_CHAINS } from '../../../types/deployments';
import { FormValues } from '..';

export default function SelectNetwork(props: BoxProps) {
  const form = useFormContext<FormValues>();
  const isTestnet = useWatch<FormValues, 'isTestnet'>({ control: form.control, name: 'isTestnet' });
  const selectedNetwork = useWatch<FormValues, 'network'>({
    control: form.control,
    name: 'network',
  });

  return (
    <Option optionTitle="CHOOSE A SETTLEMENT LAYER" {...props}>
      <Grid container {...form.register('isTestnet')} id="step_isTestnet">
        <Grid item xs={6}>
          <SelectableCard
            icon={<IconTestnet sx={{ height: '24px', width: '24px', mr: 1 }} />}
            isSelected={isTestnet}
            onClick={() => {
              form.setValue('isTestnet', true, { shouldTouch: true, shouldDirty: true });
            }}
            sx={{
              height: '100%',
            }}
            title="Testnet"
          >
            <Typography variant="caption">
              The testing environment for developers to experiment with new features and test
              applications without using real cryptocurrency.
            </Typography>
          </SelectableCard>
        </Grid>
        <Grid item xs={6}>
          <SelectableCard
            icon={<IconMainnet sx={{ height: '24px', width: '24px', mr: 1 }} />}
            isSelected={!isTestnet}
            onClick={() => {
              form.setValue('isTestnet', false, { shouldTouch: true, shouldDirty: true });
            }}
            sx={{
              height: '100%',
            }}
            title="Mainnet"
          >
            <Typography
              alignItems="center"
              component={Link}
              display="flex"
              href="https://calendly.com/wenhaualtresearch/30min"
              rel="noopener noreferrer"
              target="_blank"
              variant="captionC"
            >
              Book a meeting with our expert{' '}
              <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
            </Typography>
          </SelectableCard>
        </Grid>
      </Grid>
      {isTestnet && (
        <Grid container {...form.register('network')} id="step_network">
          <Grid item xs={6}>
            <SelectableCard
              icon={
                <Box
                  component="img"
                  src={`/imgs/eth.svg`}
                  sx={{ height: '24px', width: '24px', mr: 1 }}
                />
              }
              isSelected={selectedNetwork === SETTLEMENT_CHAINS.SEPOLIA}
              onClick={() => {
                form.setValue('network', SETTLEMENT_CHAINS.SEPOLIA, {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
              sx={{
                height: '100%',
              }}
              title="Sepolia"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectableCard
              icon={
                <Box
                  component="img"
                  src={`/imgs/arbitrum.svg`}
                  sx={{ height: '24px', width: '24px', mr: 1 }}
                />
              }
              isSelected={selectedNetwork === SETTLEMENT_CHAINS.ARB_SEPOLIA}
              onClick={() => {
                form.setValue('network', SETTLEMENT_CHAINS.ARB_SEPOLIA, {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
              sx={{
                height: '100%',
              }}
              title="Arbitrum Sepolia"
            />
          </Grid>
        </Grid>
      )}
    </Option>
  );
}
