import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconInfo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '20px', height: '20px', ...sx }} viewBox="0 0 20 20" {...props}>
      <path
        clipRule="evenodd"
        d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 7C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7ZM9 8.5H10H10.5V9V13.5H11.5V14.5H8.5V13.5H9.5V9.5H9V8.5Z"
        fill="#202723"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
