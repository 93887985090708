import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

export interface NumericStepperProps {
  value: any;
  setValue: (newValue: string) => void | ((newValue: string) => () => void);
  step?: number;
  allowNegativeNumbers?: boolean;
}

export const NumericStepper: FC<NumericStepperProps> = ({
  allowNegativeNumbers = false,
  setValue,
  step = 1,
  value,
}) => {
  const handleClickDecrement = () => {
    let newValue = parseFloat(value) - step;

    if (!allowNegativeNumbers && newValue < 0) {
      newValue = parseFloat(value);
    }

    if (isNaN(newValue)) {
      setValue('0');
    } else {
      setValue(String(newValue));
    }
  };

  const handleClickIncrement = () => {
    const newValue = parseFloat(value) + step;

    if (isNaN(newValue)) {
      setValue('1');
    } else {
      setValue(String(newValue));
    }
  };

  return (
    <Stack
      alignItems="center"
      color={'#ccc'}
      direction="row"
      spacing={1}
      sx={{
        '& svg': {
          cursor: 'pointer',
        },
      }}
    >
      <RemoveIcon onClick={handleClickDecrement} />
      <Typography>|</Typography>
      <AddIcon onClick={handleClickIncrement} />
    </Stack>
  );
};
