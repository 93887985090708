import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { GrUserAdmin } from 'react-icons/gr';
import { IoIosMore } from 'react-icons/io';
import { IoArrowBack } from 'react-icons/io5';

import { axiosAuth } from '../../api';
import { useAuthContext } from '../../contexts/AuthContext';
import { px } from '../../theme/margins';
import { InviteTeamMember } from './InviteTeamMember';

enum ROLES {
  ORG_ADMIN = 'ORG_ADMIN',
}

export function TeamSettings() {
  const [showInviteScreen, setShowInviteScreen] = useState(false);
  const { user } = useAuthContext();
  const theme = useTheme();

  const { data: users, isLoading } = useQuery({
    queryKey: ['ORG_USERS', user?.org_name],
    queryFn: async () => {
      const res = await axiosAuth.get<{ users: { email: string; role: ROLES }[] }>(
        `/org/${user?.org_id}/users`,
      );

      return res?.data;
    },
    select: data => data?.users,
    enabled: Boolean(user?.org_name && user?.org_id),
  });

  return (
    <>
      {/* <PageBanner background={theme.colors.gradients.sheet} title="Team Settings" /> */}
      <Stack sx={{ background: theme.colors.gradients.sheet, p: px }}>
        <Typography variant="h3">Team Settings</Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ borderBottom: '1px solid rgba(32, 39, 35, 0.08)', minHeight: '40vh' }}
      >
        <Stack
          px={5}
          sx={{ flex: 1, background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)' }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography mt={5} variant="h3">
              Manage team
            </Typography>
            <Typography mt={3} variant="bodySmall">
              Invite a team member to your organization
            </Typography>
          </Box>
          {showInviteScreen ? (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(false);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <IoArrowBack size={20} />
                BACK
              </Typography>
            </Box>
          ) : (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(true);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <FaCirclePlus size={20} />
                INVITE TO TEAM
              </Typography>
            </Box>
          )}
        </Stack>
        {showInviteScreen ? (
          <InviteTeamMember />
        ) : (
          <Stack
            sx={{
              ...(isLoading && { justifyContent: 'center', alignItems: 'center' }),
              flex: 2,
              background: '#fff',
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              users?.map((cur, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={cur.email}
                  px={5}
                  py={3}
                  sx={{ borderTop: index === 0 ? 'none' : '1px solid rgba(32, 39, 35, 0.08)' }}
                >
                  <Box sx={{ display: 'flex', flex: 1, gap: 1.5 }}>
                    <Typography variant="bodySmall">{cur.email}</Typography>
                    {cur.role === ROLES.ORG_ADMIN && <GrUserAdmin size={20} />}
                  </Box>
                  <IoIosMore size={20} />
                </Stack>
              ))
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}
