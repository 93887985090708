import { Editor, useMonaco } from '@monaco-editor/react';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { WithInfo } from 'components/WithInfo';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CustomArgs from './form-steps/CustomArgs';
import { AVSFormValues } from '.';

const pragmaRegex = /pragma\s+solidity\s*<?[>=^]{0,2}(\d+\.\d+\.\d+)/;

const parseContractName = (code: string) => {
  const [, matchedContractName] =
    code.match(
      /contract (GenericTemplate|CoprocessorTemplate|BridgeTemplate|MachServiceManager|HelloWorldTemplate) is/,
    ) || [];

  console.debug('matchedContractName: ', matchedContractName);

  return matchedContractName;
};

const compile = async ({
  code,
  contractName,
  version,
}: {
  code: string;
  version: string;
  contractName?: string;
}): Promise<{ bytecode: string; abi: any[]; initializeFnSig?: string }> => {
  const worker = new Worker(new URL('./compile-worker.js', import.meta.url));

  if (!contractName) {
    throw Error(
      'Main contract name must be one of: GenericTemplate | CoprocessorTemplate | BridgeTemplate | MachServiceManager',
    );
  }

  return new Promise((resolve, reject) => {
    worker.onmessage = e => {
      // let factory;

      try {
        const data = JSON.parse(e?.data);

        console.debug('Compilation warnings & errors: ', data?.errors);
        const errors = data?.errors?.filter((cur: any) => cur?.severity === 'error');

        if (errors?.length) {
          throw Error(errors?.[0]?.formattedMessage);
        }

        const contractName = data?.contractName;

        console.log('data: ', data);
        const output = data?.contracts?.Flattened?.[contractName];

        console.log('output: ', output);
        resolve({
          abi: output?.abi,
          bytecode: '0x' + output?.evm?.bytecode?.object,
          initializeFnSig: Object.keys(output?.evm?.methodIdentifiers || {})?.find(cur =>
            cur?.startsWith('initialize('),
          ),
        });
        // const { abi, evm } = contract;

        // factory = new ContractFactory(abi, evm?.bytecode, signer);
      } catch (err: any) {
        reject(
          Error(err?.message || 'Error compiling contract. Please check your contract syntax.'),
        );
      } finally {
        worker.terminate();
      }
    };

    worker.onerror = err => {
      console.error('error in webworker: ', err);
      reject(Error(`Compilation Error: ${err?.message}`));
      worker.terminate();
    };

    worker.postMessage({ source: code, contractName, version });
  });
};

export default function CustomContractFields() {
  const { addAlert } = useAlerts();
  const { register, setValue, unregister } = useFormContext<AVSFormValues>();
  const customTemplate = useWatch<AVSFormValues>({ name: 'customTemplate' });
  const monaco = useMonaco();
  const editorRef = useRef<any>(null);

  const [viewFullContract, setViewFullContract] = useState(false);

  const [code, setCode] = useState('');
  const contractName = useMemo(() => {
    return parseContractName(code);
  }, [code]);

  const hideContractDependencies = useCallback(() => {
    if (monaco && contractName && !viewFullContract) {
      const editor = editorRef.current;

      const lines = code?.split('\n');

      const start = lines?.findIndex(cur => cur?.includes(`/${contractName}.sol`)) || 0;

      console.debug('start on line: ', start);

      // autohide on paste/input
      editor.setHiddenAreas([new monaco.Range(4, 1, start, 1)]);
    }
  }, [code, monaco, contractName, viewFullContract]);

  const [isFetchingCode, setIsFetchingCode] = useState(false);

  useEffect(() => {
    const fetchCode = async () => {
      if (code?.startsWith('http')) {
        try {
          setIsFetchingCode(true);
          const res = await axios.get(code);

          setCode(res?.data);
          setViewFullContract(true);
        } catch (err) {
          console.error(err);
          setCode(String(err) + '\n\nPlease ensure that you have pasted a public url');
        } finally {
          setIsFetchingCode(false);
        }
      }
    };

    fetchCode();
  }, [code]);

  useEffect(() => {
    if (!viewFullContract) {
      hideContractDependencies();
    } else {
      const editor = editorRef.current;

      editor?.setHiddenAreas([]);
    }
  }, [hideContractDependencies, viewFullContract]);

  const {
    data: compiledContract,
    isPending: isCompiling,
    mutate: compileContract,
  } = useMutation({
    mutationFn: compile,
    onSuccess: (res, vars) => {
      console.debug('compiledContract: ', compiledContract);
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: `Contract successfully compiled`,
        desc: `Solc version: ${vars?.version}`,
      });
      setValue('customTemplate', res);
    },
    onError: (err: Error) => {
      console.error('error: ', err);
      console.log('errmsg:', err?.message);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to compile contract',
        desc: err?.message || err?.stack,
      });
    },
  });

  return (
    <>
      <Typography id="step_customTemplate" variant="caption">
        {!code ? (
          <>
            Paste flattened contract or url here.{' '}
            <Link
              href="https://book.getfoundry.sh/reference/forge/forge-flatten"
              rel="noopener noreferrer"
              sx={{
                color: theme => theme.colors.functional.text.link,
                '&:hover': { textDecoration: 'underline' },
              }}
              target="_blank"
            >
              Show me how
            </Link>
          </>
        ) : viewFullContract ? (
          <>
            Showing full contract.{' '}
            <Typography
              onClick={() => {
                setViewFullContract(prev => !prev);
              }}
              sx={{
                color: theme => theme.colors.functional.text.link,
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
              variant="caption"
            >
              View {contractName} only
            </Typography>
          </>
        ) : (
          <>
            Showing main contract only.{' '}
            <Typography
              onClick={() => {
                setViewFullContract(prev => !prev);
              }}
              sx={{
                cursor: 'pointer',
                color: theme => theme.colors.functional.text.link,
                '&:hover': { textDecoration: 'underline' },
              }}
              variant="caption"
            >
              View full flattened contract
            </Typography>
          </>
        )}
      </Typography>
      <Box
        {...register('customTemplate')}
        sx={{
          position: 'relative',
        }}
      >
        {isFetchingCode && (
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 10,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              transform: 'translate(-50%,-50%)',
            }}
          >
            <CircularProgress size={30} />
            Fetching contract code from {code}
          </Typography>
        )}
        <Editor
          height="600px"
          language="sol"
          onChange={input => {
            setCode(String(input));
          }}
          onMount={editor => {
            editorRef.current = editor;
          }}
          options={{
            selectOnLineNumbers: true,
            scrollBeyondLastLine: false,
            folding: true,
            showFoldingControls: 'always',
          }}
          theme="vs-dark"
          value={code}
        />
      </Box>
      <LoadingButton
        loading={isCompiling}
        onClick={async () => {
          setValue('customTemplate', undefined);
          unregister('initArgs');
          unregister('constructorArgs');

          // Extract the version from the Solidity file
          const match = code.match(pragmaRegex);

          if (!match) {
            addAlert({ severity: ALERT_SEVERITY.ERROR, title: 'Invalid pragma version' });

            return;
          }

          const pragmaVer = match?.[1];

          const res = await axios.get<string>('https://binaries.soliditylang.org/bin/list.txt');
          const versions = res?.data?.split('\n')?.filter(cur => !cur?.includes('nightly'));

          const version = versions?.find(cur => cur?.includes(`${pragmaVer}+commit`)) || '';

          if (!version) {
            addAlert({
              severity: ALERT_SEVERITY.ERROR,
              title: 'Failed to compile contract.',
              desc: `Could not find compiler version ${pragmaVer}. Please verify that your pragma statement is valid, or use a more specific version if necessary.`,
            });

            return;
          }

          console.debug('compiler version: ', version);

          compileContract({ code, version, contractName });
        }}
        sx={{ my: 2 }}
        variant="contained"
      >
        Compile
      </LoadingButton>
      {customTemplate && (
        <CustomArgs
          fieldArrName="constructorArgs"
          label={
            <Typography>
              <WithInfo
                info="Arguments required for service manager's constructor"
                text={<Typography variant="bodySmallC">CUSTOM CONSTRUCTOR ARGS</Typography>}
              />
            </Typography>
          }
        />
      )}
      {customTemplate && (
        <CustomArgs
          fieldArrName="initArgs"
          label={
            <Typography>
              <WithInfo
                info="Arguments required for service manager's initialize function"
                text={<Typography variant="bodySmallC">CUSTOM INITIALIZE ARGS</Typography>}
              />
            </Typography>
          }
          mt={8}
        />
      )}
    </>
  );
}
