import { BoxProps, Link as MuiLink, Stack, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { px } from '../../theme/margins';
import IconLogo from '../icons/IconLogo';

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '0.9px',
  textTransform: 'uppercase',
  fontWeight: 400,
}));

const Footer: FC<BoxProps> = () => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      px={px}
      sx={{ bgcolor: '#fff', py: 4 }}
    >
      <IconLogo sx={{ height: '48px', width: 'auto' }} />
      <Stack
        alignItems="row"
        flexDirection="row"
        sx={{
          a: {
            color: theme.palette.text.primary,
            mr: 5,
          },
          'a:last-child': {
            mr: 0,
          },
        }}
      >
        <FooterLink href="https://altlayer.io" target="_blank">
          ALTLAYER
        </FooterLink>
        <FooterLink href="https://docs.altlayer.io/altlayer-documentation" target="_blank">
          DOCS
        </FooterLink>
        <FooterLink href="https://www.altlayer.io/updates" target="_blank">
          BLOG
        </FooterLink>
        <FooterLink href="https://careers.altlayer.io/" target="_blank">
          CAREERS
        </FooterLink>
        <FooterLink href="mailto:support@altresear.ch" target="_blank">
          CONTACT
        </FooterLink>
        <Link to="/privacy">PRIVACY</Link>
        <Link to="/disclaimer">DISCLAIMER</Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
