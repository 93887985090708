import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconNay({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ height: '18px', width: '18px', ...sx }} {...props} viewBox="0 0 18 18">
      <g opacity="0.4">
        <circle cx="9" cy="8.5" fill="#202723" r="8.5" />
        <path d="M5.40039 11.7L12.2004 4.90002" stroke="white" />
        <path d="M5.4002 4.90002L12.2002 11.7" stroke="white" />
      </g>
    </SvgIcon>
  );
}
