import { Grid, Typography, useTheme } from '@mui/material';
import ComingSoonTooltip from 'components/ComingSoonTooltip';
import { IoArrowBackSharp } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';

import { PATHS } from '../../App';

export function NavBar() {
  const location = useLocation();
  const theme = useTheme();
  const { pistachio, water } = theme.colors.gradients;
  const gray = 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)';

  const isRaasRoute = location.pathname?.includes('/rollups');
  const isAvsRoute = location.pathname?.includes('/avs');

  return (
    <Grid container>
      <Grid
        color="inherit"
        component={Link}
        container
        item
        px={5}
        py={3}
        sx={{
          cursor: 'pointer',
          textDecoration: isAvsRoute ? 'underline' : 'none',
          textUnderlineOffset: '3px',
          background: isAvsRoute ? water : gray,
        }}
        to={PATHS.AVS_DEPLOYMENTS}
        xs={6}
      >
        <Typography
          alignItems="center"
          display="flex"
          gap={1}
          sx={{
            transform: isAvsRoute ? 'scale(1.1)' : 'none',
            transition: 'all 0.2s ease',
          }}
          variant="bodyMediumC"
        >
          {location.pathname?.match(/\/avs\/\w/i) && <IoArrowBackSharp size={24} />}
          AVS
        </Typography>
      </Grid>
      <Grid
        color="inherit"
        container
        item
        sx={{
          // cursor: 'pointer',
          cursor: 'not-allowed',
          textDecoration: isRaasRoute ? 'underline' : 'none',
          textUnderlineOffset: '3px',
          background: isRaasRoute ? pistachio : gray,
        }}
        xs={6}
      >
        <ComingSoonTooltip
          anchorWrapperSx={{
            width: '100%',
            px: 5,
            py: 3,
          }}
          tooltipProps={{ placement: 'top' }}
        >
          <Grid component={Link} container to={PATHS.DEPLOYMENTS}>
            <Typography
              alignItems="center"
              display="flex"
              gap={1}
              sx={{
                transform: isRaasRoute ? 'scale(1.1)' : 'none',
                transition: 'all 0.2s ease',
              }}
              variant="bodyMediumC"
            >
              {location.pathname?.match(/\/rollups\/\w/i) && <IoArrowBackSharp size={24} />}
              Rollups
            </Typography>
          </Grid>
        </ComingSoonTooltip>
      </Grid>
    </Grid>
  );
}
