import { axiosAuth } from '.';

export const verifyPromoCode = async (code?: string) => {
  if (!code) {
    throw Error('No code provided');
    // return Promise.reject(Error('No code provided'));
  }

  const res = await axiosAuth.get<{ isValid: boolean }>(`/promo-code/${code}`);

  return res?.data?.isValid;
};
