import { Box, BoxProps, Grid, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import SelectableCard from '../../../components/Card/SelectableCard';
import IconExpanded from '../../../components/icons/IconExpanded';
import { Option } from '../../../components/Option';
import { WithInfo } from '../../../components/WithInfo';
import { DAType } from '../../../types/protoc-gen/rollup';
import { FormValues } from '..';

interface DaTypeOption {
  icon: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  value: DAType;
  disabled?: boolean;
}

const daTypeOptions: DaTypeOption[] = [
  {
    icon: (
      <Box component="img" src={`/imgs/eigen.svg`} sx={{ height: '24px', width: '24px', mr: 1 }} />
    ),
    title: <Typography variant="bodySmall">EigenDA</Typography>,
    value: DAType.TYPE_EIGENDA,
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/arbitrum.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">AnyTrust</Typography>,
    value: DAType.TYPE_ANYTRUST,
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/celestia.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">Celestia</Typography>,
    value: DAType.TYPE_CELESTIA,
  },
  {
    icon: (
      <Box component="img" src={`/imgs/avail.svg`} sx={{ height: '24px', width: '24px', mr: 1 }} />
    ),
    title: <Typography variant="bodySmall">Avail</Typography>,
    value: DAType.TYPE_AVAIL,
  },
];

export default function SelectDA(props: BoxProps) {
  const form = useFormContext<FormValues>();
  const daType = useWatch<FormValues, 'daType'>({ control: form.control, name: 'daType' });
  const [expanded, setExpanded] = useState(false);

  return (
    <Option
      endAdornment={
        <Typography
          component={Stack}
          flexDirection="row"
          onClick={() => setExpanded(old => !old)}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
        >
          Details
          <IconExpanded sx={{ ml: 1, transform: expanded ? 'unset' : 'scaleY(-1)' }} />
        </Typography>
      }
      optionTitle={
        <WithInfo
          info="Data availability refers to guarantees that the data needed to verify a block in the blockchain is actually available to all network participants"
          text={<Typography variant="bodySmallC">CHOOSE A DATA AVAILABILITY SOLUTION</Typography>}
        />
      }
      {...props}
    >
      <Grid container {...form.register('daType')}>
        {daTypeOptions?.map(da => (
          <Grid item key={da.value} xs={12 / daTypeOptions?.length}>
            <SelectableCard
              icon={da.icon}
              isSelected={da.value === daType}
              onClick={() => {
                form.setValue('daType', da.value, { shouldTouch: true, shouldDirty: true });
              }}
              sx={{
                height: '100%',
              }}
              title={da.title}
            />
          </Grid>
        ))}
      </Grid>
    </Option>
  );
}
