import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconZKSync({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '40px', height: '40px', ...sx }} viewBox="0 0 40 40" {...props}>
      <path
        clipRule="evenodd"
        d="M40 19.8118L28.6546 8.5V16.7822L17.3908 25.074L28.6546 25.0836V31.1188L40 19.8118Z"
        fill="#4E529C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 19.807L11.3454 31.114V22.899L22.6091 14.54L11.3454 14.5304V8.5L0 19.807Z"
        fill="#8B8EFD"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
