import { Button, Grid, Stack, Typography, TypographyProps } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteDeployment, fetchDeployments } from 'api/deployments';
import { PATHS } from 'App';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { PageBanner } from 'components/Layout/PageBanner';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { daTypeLabels, rollupTypeLabels } from 'labels/deployments';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InitParams, SETTLEMENT_CHAINS } from 'types/deployments';
import { DAType, Deployment, RollupType } from 'types/protoc-gen/rollup';
import { QUERY_KEYS } from 'types/react-query';

function InfoHeader({ children, sx }: TypographyProps) {
  return (
    <Typography
      sx={{
        background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
        px: 5,
        py: 3,
        height: '5rem',
        boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
        ...sx,
      }}
      variant="h5"
    >
      {children}
    </Typography>
  );
}

export function DeploymentDetails() {
  const params = useParams();
  const deploymentId = params?.deploymentId;

  const { data: deployments } = useQuery<Deployment[]>({
    queryKey: ['GET_DEPLOYMENTS'],
    queryFn: fetchDeployments,
  });
  const deployment = deployments?.find(cur => cur.deploymentId === deploymentId);
  const { chainId, chainName, parentChainId }: InitParams = JSON.parse(
    deployment?.initParam || '{}',
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { mutate: deleteNetwork } = useMutation({
    mutationFn: deleteDeployment,
    onSuccess: () => {
      addAlert({
        title: `Succesfully initiated termination of "${chainName}"`,
        severity: ALERT_SEVERITY.SUCCESS,
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_DEPLOYMENTS] });
      navigate(PATHS.DEPLOYMENTS);
    },
  });

  const initParams = useMemo(
    () => [
      { label: 'Chain ID', value: chainId },
      {
        label: 'Framework',
        value: rollupTypeLabels?.[deployment?.rollupType as RollupType],
      },
      {
        label: 'Settlement Layer',
        value: SETTLEMENT_CHAINS?.[parentChainId],
      },
      {
        label: 'Data Availability Layer',
        value: daTypeLabels?.[deployment?.daType as DAType],
      },
    ],
    [chainId, deployment?.daType, deployment?.rollupType, parentChainId],
  );
  const deployedResources = useMemo(
    () => [
      { label: 'RPC', value: 'https://example.com' },
      { label: 'WS', value: 'wss://example.com' },
      { label: 'Explorer', value: 'https://explorer.example.com' },
    ],
    [],
  );

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          deleteNetwork(deploymentId);
        }}
        open={showDeleteDialog}
      >
        Deleting a network cannot be undone.
      </ConfirmationDialog>
      <PageBanner title={chainName} />
      <Grid
        container
        sx={{ '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' } }}
      >
        <Grid
          alignItems="center"
          container
          flexDirection="column"
          item
          sx={{ bgcolor: '#FFF' }}
          xs={8}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{
              background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
              height: '5rem',
              px: 5,
              boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
            }}
            width="100%"
          >
            <Typography variant="h5">{chainName}</Typography>
          </Stack>
          <Grid
            container
            item
            px={5}
            py={3}
            sx={{
              background: '#FAFAFA',
              boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
              minHeight: '5.5rem',
            }}
          >
            {initParams?.map(item => (
              <Grid container flexDirection="column" item key={item.label} xs={3}>
                <Typography variant="caption">{item.label}</Typography>
                <Typography variant="bodySmallM">{item.value}</Typography>
              </Grid>
            ))}
          </Grid>
          <Stack
            alignItems="flex-start"
            justifyContent="flex-start"
            px={5}
            sx={{
              '&>*': {
                height: '3.75rem',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              },
              '&>*:not(&>*:last-of-type)': {
                borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
              },
              borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
              borderTop: '1px solid rgba(32, 39, 35, 0.08)',
            }}
            width="100%"
          >
            {deployedResources?.map(item => (
              <Stack direction="row" key={item.label}>
                <Typography sx={{ width: '17rem' }} variant="bodySmallM">
                  {item.label}
                </Typography>
                <Typography flex={1} variant="caption">
                  {item.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            sx={{
              background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
              border: '1px solid rgba(32, 39, 35, 0.08)',
            }}
            width="100%"
          >
            <Button
              sx={{
                background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
                color: theme => theme.colors.functional.text.primary,
              }}
              variant="text"
            >
              <Typography variant="bodySmallC">FAQ</Typography>
            </Button>
            <Button
              onClick={() => {
                setShowDeleteDialog(true);
              }}
              sx={{
                color: theme => theme.colors.functional.text.primary,
              }}
              variant="text"
            >
              <Typography variant="bodySmallC">Delete network</Typography>
            </Button>
          </Stack>
        </Grid>
        <Grid container flexDirection="column" item sx={{ bgcolor: '#fff' }} xs={4}>
          <InfoHeader>Bridge assets</InfoHeader>
          <Typography
            sx={{
              background: '#FFF',
              px: 5,
              py: 3,
              height: '5.5rem',
              boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
              display: 'flex',
              alignItems: 'center',
            }}
            variant="bodySmallC"
          >
            Bridge assets to your rollup
          </Typography>
          <InfoHeader>Rollup info</InfoHeader>
          <Stack
            sx={{
              flex: 1,
              background: '#FFF',
              '&>*': {
                px: 5,
                py: 3,
              },
              boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)',
            }}
          >
            <Typography variant="bodySmallC">Status</Typography>
            <Typography variant="bodySmallC">Metrics</Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
