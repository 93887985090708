import { LoadingButton } from '@mui/lab';
import {
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { FC, ReactNode, useCallback, useMemo } from 'react';

interface ConfirmationDialogProps {
  open: boolean;
  title?: ReactNode;
  handleClose: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
  confirmButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  renderButtons?: (defaultButtons: JSX.Element) => JSX.Element;
}

const ConfirmationDialog: FC<ConfirmationDialogProps & Omit<DialogProps, 'title'>> = ({
  children,
  confirmButtonProps,
  confirmButtonText: _confirmButtonText,
  handleClose,
  isLoading = false,
  onConfirm,
  open = false,
  renderButtons = defaultButtons => defaultButtons,
  sx,
  title = 'Are you sure you want to proceed?',
  ...rest
}) => {
  const confirmButtonText = _confirmButtonText || 'Confirm';

  const handleConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const defaultButtons: JSX.Element = useMemo(
    () => (
      <>
        <LoadingButton
          className="confirm-button"
          fullWidth
          key="confirm"
          loading={isLoading}
          onClick={handleConfirm}
          variant="contained"
          {...confirmButtonProps}
        >
          {confirmButtonText}
        </LoadingButton>
      </>
    ),
    [confirmButtonProps, confirmButtonText, handleConfirm, isLoading],
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '&>div>.MuiPaper-root': { p: 3 },
        ...sx,
      }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {children}
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={3}
          spacing={1}
          sx={{ '& button': { minWidth: 80 } }}
        >
          {renderButtons(defaultButtons)}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
