import { Box, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

export const BaseLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {children}
    </Box>
  );
};

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <BaseLayout>
      <Header />
      <Stack
        sx={{
          flex: 1,
          position: 'relative',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Box
          sx={{
            background: "url('/imgs/bgApp.png')",
            position: 'absolute',
            height: '100%',
            width: '100vw',
            backgroundRepeat: 'repeat',
            zIndex: -1,
            bgcolor: '#fafafa',
            backgroundPositionY: '5rem',
          }}
        />
        {children}
      </Stack>
      <Footer />
    </BaseLayout>
  );
};

export default Layout;
