import { GridProps, Link, LinkProps, SxProps, Typography } from '@mui/material';
import truncate from 'lodash/truncate';
import { FC } from 'react';

import Copyable, { CopyableProps } from '.';

export interface CopyableTextProps extends Partial<CopyableProps> {
  text: string;
  maxLength?: number;
  href?: string;
  sx?: SxProps;
  linkProps?: LinkProps;
  anchorGridProps?: GridProps;
}

const CopyableText: FC<CopyableTextProps> = ({
  anchorGridProps,
  children,
  href = '',
  linkProps,
  maxLength = 35,
  sx,
  text,
  ...rest
}) => {
  const truncatedText = truncate(text, { length: maxLength });

  const _sx: SxProps = {
    whiteSpace: 'nowrap',
    ...sx,
  };

  return (
    <Copyable
      anchorGridProps={anchorGridProps}
      clipboardValue={text}
      showTooltip={text?.length > truncatedText?.length}
      {...rest}
    >
      {href ? (
        <Link href={href} rel="noopener noreferrer" sx={_sx} target="_blank" {...linkProps}>
          {children || <Typography variant="body1">{truncatedText}</Typography>}
        </Link>
      ) : (
        <Typography sx={_sx} variant="body1">
          {children || truncatedText}
        </Typography>
      )}
    </Copyable>
  );
};

export default CopyableText;
