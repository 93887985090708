export enum SETTLEMENT_CHAINS {
  SEPOLIA = 11155111,
  ARB_SEPOLIA = 421614,
}
export interface InitParams {
  chainName: string;
  chainId: number;
  parentChainId: SETTLEMENT_CHAINS;
  challengePeriod: number;
}
