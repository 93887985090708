import { Box, Drawer, Link as MuiLink, Popover, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import IconLink from 'components/icons/IconLink';
import { TeamSettings } from 'pages/TeamSettings';
import React, { useState } from 'react';
import { FaPowerOff } from 'react-icons/fa';
import { GrUserSettings } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';

import { axiosAuth } from '../../api';
import { PATHS } from '../../App';
import { useAuthContext, User } from '../../contexts/AuthContext';
import { px } from '../../theme/margins';
import IconLogo from '../icons/IconLogo';

const Header: React.FC = () => {
  const { setIsLoggedIn, setLoginSuccessRedirectPath, setUser, user } = useAuthContext();
  const navigate = useNavigate();
  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);

  const { mutate } = useMutation({
    mutationFn: async () => {
      await axiosAuth.post('/auth/logout');
    },
    onSuccess: () => {
      setIsLoggedIn(false);
      setUser({} as User);
      setLoginSuccessRedirectPath('');
      navigate(PATHS.LOGIN);
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        onClose={() => setShowSettingsDrawer(false)}
        open={showSettingsDrawer}
      >
        <TeamSettings />
      </Drawer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
          borderBottom: '1px solid rgba(32, 39, 35, 0.03)',
        }}
      >
        <Stack px={px} py={{ md: 2.5, xs: 1.5 }}>
          <Link to={PATHS.DEFAULT}>
            <IconLogo sx={{ height: { xs: '20px', md: '40px' }, width: 'auto' }} />
          </Link>
        </Stack>
        <Stack alignItems="center" direction="row">
          <MuiLink
            href="https://docs.altlayer.io/altlayer-documentation/wizard/introduction"
            rel="noopener noreferrer"
            sx={{
              background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              px: 6,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            target="_blank"
          >
            <Typography variant="bodySmallC">
              User guide
              <IconLink />
            </Typography>
          </MuiLink>
          {user?.email && (
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              px={6}
              sx={{ height: '100%', background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)' }}
            >
              <Box
                alt="profile picture"
                component="img"
                referrerPolicy="no-referrer"
                src={user?.picture}
                sx={{ width: 30, height: 30, borderRadius: '50%' }}
              />
              <Stack>
                <Typography>{user?.email}</Typography>
                {user?.org_name && <Typography fontSize={12}>{user?.org_name}</Typography>}
              </Stack>
              <Box
                component="img"
                onClick={handleClick}
                src={`/imgs/chevron_down.svg`}
                sx={{
                  height: '18px',
                  width: '18px',
                  cursor: 'pointer',
                  transform: anchorEl ? 'rotate(180deg)' : 'none',
                  transition: 'all 0.2s ease-in-out',
                }}
              />
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Stack>
                  <Typography
                    onClick={() => {
                      setShowSettingsDrawer(true);
                      handleClose();
                    }}
                    sx={{
                      py: 2,
                      px: 3,
                      display: 'flex',
                      gap: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: '#ccc4',
                      },
                    }}
                  >
                    <GrUserSettings size={20} />
                    Team Settings
                  </Typography>
                  <Typography
                    onClick={() => {
                      mutate();
                      handleClose();
                    }}
                    sx={{
                      py: 2,
                      px: 3,
                      display: 'flex',
                      gap: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: '#ccc4',
                      },
                    }}
                  >
                    <FaPowerOff size={20} />
                    Logout
                  </Typography>
                </Stack>
              </Popover>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
