import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  GridProps,
  Stack,
  Typography,
} from '@mui/material';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import ECDSAStakeRegistry from 'contracts/ECDSAStakeRegistry';
import RegistryCoordinatorAbi from 'contracts/RegistryCoordinatorAbi';
import dayjs from 'dayjs';
import { useOperators } from 'hooks/useOperators';
import { useWriteTx } from 'hooks/useWriteTx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { msToDaysHrsMinsSeconds } from 'utils/dates';
import { Address } from 'viem';

export default function UpdateStakeBanner({ avs, ...rest }: GridProps & { avs: AVSDeployment }) {
  const [showUpdateStakeModal, setShowUpdateStakeModal] = useState(false);

  const [lastRefresh, setLastRefresh] = useState(
    localStorage.getItem(`last_refresh_${avs?.avsName}`),
  );
  const timeoutRef = useRef<NodeJS.Timer>();

  const [countdownMs, setCountdownMs] = useState<number>(0);
  const countdownArr = useMemo(() => msToDaysHrsMinsSeconds(countdownMs || 0), [countdownMs]);

  useEffect(() => {
    if (!avs.avsName) return;

    if (!lastRefresh) {
      const stored = localStorage.getItem(`last_refresh_${avs?.avsName}`);

      if (!stored) {
        setCountdownMs(0);

        return;
      }

      setLastRefresh(stored);

      return;
    }

    clearInterval(timeoutRef.current);

    timeoutRef.current = setInterval(() => {
      setCountdownMs(dayjs(lastRefresh).add(24, 'hours').diff());
    }, 1000);

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [avs.avsName, lastRefresh]);

  const { operatorList } = useOperators({ avs });

  const operators = operatorList
    ?.filter(operator => operator?.whitelisted && operator?.registered)
    ?.map(operator => operator?.address);

  const { isPending, write: update } = useWriteTx({
    contractAbi: avs?.registryCoordinatorProxy ? RegistryCoordinatorAbi : ECDSAStakeRegistry,
    contractAddress: (avs?.registryCoordinatorProxy as Address) || avs?.stakeRegistryProxy,
    functionName: 'updateOperators',
    functionArgs: [operators],
    txKey: `updateOperators_${JSON.stringify(operators || {})}`,
    onTxConfirmed: () => {
      const now = new Date().toISOString();

      localStorage.setItem(`last_refresh_${avs?.avsName}`, now);
      setLastRefresh(now);
    },
  });

  return (
    <>
      <Dialog
        disablePortal
        onClose={() => setShowUpdateStakeModal(false)}
        open={showUpdateStakeModal}
        sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      >
        <DialogTitle>
          <Typography fontWeight={500} variant="h5">
            Update Stake
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Web3LoadingButton loading={isPending} onClick={update} variant="contained">
            Update
          </Web3LoadingButton>
        </DialogContent>
      </Dialog>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="space-between"
        sx={{
          background: 'linear-gradient(90deg, #A6C3FA 0%, rgba(166, 195, 250, 0.00) 100%)',
          borderTop: '1px solid rgba(32, 39, 35, 0.08)',
          borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
          py: 2,
          mt: 3,
          position: 'relative',
          '&:after': {
            content: '" "',
            width: `${(countdownMs * 100) / (24 * 60 * 60 * 1000)}%`,
            position: 'absolute',
            borderBottom: '6px solid #5C8CE1',
            left: 0,
            bottom: 0,
          },
        }}
        xs={12}
        {...rest}
      >
        <Stack>
          <Typography variant="caption">Stake status refresh needed in</Typography>
          <Grid container sx={{ userSelect: 'none' }} width="240px">
            {countdownArr?.map((cur, i) => (
              <Grid container item key={`${i}_${cur}`} xs={3}>
                <Typography variant="h5C">{String(cur)?.padStart(2, '0')}</Typography>
                <Box
                  flex={1}
                  sx={{
                    position: 'relative',
                  }}
                >
                  {i < countdownArr?.length - 1 && (
                    <Typography
                      sx={{
                        left: '50%',
                        top: '45%',
                        transform: 'translate(-50%,-50%)',
                        position: 'absolute',
                        verticalAlign: 'middle',
                      }}
                      variant="h5C"
                    >
                      :
                    </Typography>
                  )}
                </Box>
              </Grid>
            ))}
            {['Days', 'Hrs', 'Mins', 'Secs'].map(cur => (
              <Grid
                component={Typography}
                item
                key={cur}
                sx={{ opacity: 0.5 }}
                variant="caption"
                xs={3}
              >
                {cur}
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Button
          onClick={() => setShowUpdateStakeModal(true)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
          variant="contained"
        >
          {isPending ? (
            <CircularProgress size={25} />
          ) : (
            <Box component="img" src="/imgs/refresh.svg" sx={{ width: 25 }} />
          )}
          Refresh
        </Button>
      </Grid>
    </>
  );
}
