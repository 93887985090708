import { CardContent, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import Card, { CardProps } from '.';

interface SelectableCardProps {
  icon?: ReactNode;
  title?: ReactNode;
  rightButtonGroup?: ReactNode;
  children?: ReactNode;
  subtitle?: ReactNode;
  isSelected?: boolean;
}

const SelectableCard: FC<SelectableCardProps & Omit<CardProps, 'title'>> = ({
  children,
  icon,
  isSelected,
  rightButtonGroup,
  subtitle,
  sx,
  title,
  ...rest
}) => {
  return (
    <Card
      sx={{
        borderRadius: 0,
        cursor: 'pointer',
        border: '1px solid rgba(32, 39, 35, 0.08)',
        boxShadow: 'none',
        ...(isSelected && { backgroundColor: '#fff', border: '1px solid #5C8CE1' }),
        // svg: {
        //   path: {
        //     fill: isSelected ? theme.palette.common.white : '#000',
        //   },
        // },
        '&:hover': {
          filter: 'brightness(0.98)',
          // svg: {
          //   path: {
          //     fill: theme.palette.common.white,
          //   },
          // },
          // span: {
          //   color: theme.palette.common.white,
          // },
        },
        ...sx,
      }}
      {...rest}
    >
      <Stack className="card-header" p={2}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={4}>
          <Stack alignItems="center" direction="row">
            {icon}
            <Typography component="span" variant="bodySmallM">
              {title}
            </Typography>
          </Stack>
          {rightButtonGroup}
        </Stack>
        <Typography>{subtitle}</Typography>
      </Stack>
      {children && <CardContent sx={{ pt: 0, mt: 2 }}>{children}</CardContent>}
    </Card>
  );
};

export default SelectableCard;
