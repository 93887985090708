import { Card, CardProps, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CheckboxCardProps extends Omit<CardProps, 'content'> {
  content: ReactNode;
  icon?: ReactNode;
  isSelected: boolean;
  leftAdornment?: ReactNode;
  leftButtonGroup?: ReactNode;
  rightButtonGroup?: ReactNode;
  disabled?: boolean;
}

const CheckboxCard: FC<CheckboxCardProps> = ({
  content,
  disabled,
  icon,
  isSelected,
  leftAdornment,
  leftButtonGroup,
  rightButtonGroup,
  sx,
  ...rest
}) => {
  return (
    <Card
      sx={{
        cursor: 'pointer',
        borderRadius: 0,
        backgroundColor: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
        border: '1px solid rgba(32, 39, 35, 0.08)',
        ...(isSelected && { border: '1px solid #5C8CE1' }),
        svg: {
          width: 32,
          height: 32,
        },
        '&:hover': {
          filter: 'brightness(0.98)',
          // span: {
          //   color: theme.palette.common.white,
          // },
        },
        boxShadow: 'none',
        ...(disabled && {
          opacity: 0.8,
          background: theme => theme.colors?.gradients?.metal,
          pointerEvents: 'none',
        }),
        ...sx,
      }}
      {...rest}
    >
      {leftAdornment}
      <Stack alignItems="center" direction="row" flex={1} justifyContent="space-between" p={1}>
        <Stack alignItems="center" direction={'row'}>
          {leftButtonGroup}
          {icon}
          {content}
        </Stack>
        {rightButtonGroup}
      </Stack>
    </Card>
  );
};

export default CheckboxCard;
