import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconOP({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '40px', height: '40px', ...sx }} viewBox="0 0 40 40" {...props}>
      <circle cx="20" cy="20" fill="white" r="20" />
      <path
        d="M14.3841 23.1521C14.9441 23.1521 15.4161 22.9841 15.8081 22.6561C16.2081 22.3281 16.4961 21.8241 16.6641 21.1361C16.8961 20.1921 17.0721 19.3761 17.1921 18.6721C17.2321 18.4641 17.2561 18.2481 17.2561 18.0241C17.2561 17.1121 16.7841 16.6561 15.8321 16.6561C15.2721 16.6561 14.7921 16.8241 14.3921 17.1521C14.0001 17.4801 13.7201 17.9841 13.5521 18.6721C13.3681 19.3441 13.1921 20.1601 13.0081 21.1361C12.9681 21.3361 12.9441 21.5441 12.9441 21.7681C12.9361 22.6961 13.4241 23.1521 14.3841 23.1521Z"
        fill="#E71515"
      />
      <path
        d="M27.1528 19.0881C26.8408 19.3201 26.4808 19.4321 26.0568 19.4321H24.3368L24.9048 16.7441H26.7048C27.1128 16.7441 27.4088 16.8241 27.5768 16.9841C27.7448 17.1361 27.8328 17.3601 27.8328 17.6481C27.8328 17.7761 27.8168 17.9281 27.7848 18.0961C27.6808 18.5281 27.4728 18.8561 27.1528 19.0881Z"
        fill="#E71515"
      />
      <path
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM14.1681 25.3121C12.9761 25.3121 12.0001 25.0321 11.2401 24.4721C10.4881 23.9041 10.1121 23.0881 10.1121 22.0401C10.1121 21.8161 10.1361 21.5521 10.1841 21.2321C10.3121 20.5121 10.4961 19.6481 10.7361 18.6321C11.4161 15.8801 13.1761 14.5041 16.0081 14.5041C16.7761 14.5041 17.4721 14.6321 18.0801 14.8961C18.6881 15.1441 19.1681 15.5281 19.5201 16.0401C19.8721 16.5441 20.0481 17.1441 20.0481 17.8401C20.0481 18.0481 20.0241 18.3121 19.9761 18.6321C19.8241 19.5201 19.6481 20.3921 19.4321 21.2321C19.0801 22.6001 18.4801 23.6321 17.6161 24.3121C16.7601 24.9841 15.6081 25.3121 14.1681 25.3121ZM20.4888 25.0641C20.5528 25.1361 20.6328 25.1681 20.7448 25.1681H22.7848C22.8888 25.1681 22.9848 25.1361 23.0728 25.0641C23.1688 24.9921 23.2168 24.9041 23.2408 24.7921L23.9288 21.5041H25.9528C27.2648 21.5041 28.2968 21.2321 29.0488 20.6801C29.8168 20.1281 30.3208 19.2801 30.5688 18.1281C30.6248 17.8561 30.6568 17.5921 30.6568 17.3441C30.6568 16.4721 30.3128 15.8081 29.6328 15.3441C28.9608 14.8881 28.0728 14.6561 26.9608 14.6561H22.9688C22.8648 14.6561 22.7688 14.6881 22.6808 14.7601C22.5848 14.8321 22.5288 14.9201 22.5128 15.0321L20.4408 24.7921C20.4248 24.8961 20.4408 24.9841 20.4888 25.0641Z"
        fill="#E71515"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
