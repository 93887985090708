import { Box, BoxProps, Typography } from '@mui/material';
import IconArb from 'components/icons/IconArb';
import IconOP from 'components/icons/IconOP';
import IconPolygon from 'components/icons/IconPolygon';
import IconZKSync from 'components/icons/IconZKSync';
import { OperatorState } from 'hooks/useOperators';
import { ReactNode } from 'react';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';

import { SETTLEMENT_CHAINS } from '../types/deployments';
import { DAType, RollupDeploymentStatus, RollupType } from '../types/protoc-gen/rollup';

export const rollupTypeLabels: Record<RollupType, string> = {
  [RollupType.TYPE_OPSTACK]: 'OP Stack',
  [RollupType.TYPE_CDK]: 'Polygon CDK',
  [RollupType.TYPE_ORBIT]: 'Arbitrum Orbit',
  [RollupType.TYPE_ZKSYNC]: 'Zk Sync',
  [RollupType.UNRECOGNIZED]: 'Unrecognized',
};
export const rollupTypeIcons: Record<RollupType, ReactNode> = {
  [RollupType.TYPE_OPSTACK]: <IconOP sx={{ width: 24, height: 24 }} />,
  [RollupType.TYPE_ORBIT]: <IconArb sx={{ width: 24, height: 24 }} />,
  [RollupType.TYPE_ZKSYNC]: <IconZKSync sx={{ width: 24, height: 24 }} />,
  [RollupType.TYPE_CDK]: <IconPolygon sx={{ width: 24, height: 24 }} />,
  [RollupType.UNRECOGNIZED]: null,
};

export const chainLabels: Record<SETTLEMENT_CHAINS, string> = {
  [SETTLEMENT_CHAINS.SEPOLIA]: 'Sepolia',
  [SETTLEMENT_CHAINS.ARB_SEPOLIA]: 'Arbitrum Sepolia',
};

export const daTypeLabels: Record<DAType, string> = {
  [DAType.TYPE_EIGENDA]: 'EigenDA',
  [DAType.TYPE_CELESTIA]: 'Celestia',
  [DAType.TYPE_AVAIL]: 'Avail',
  [DAType.TYPE_ANYTRUST]: 'Anytrust DA',
  [DAType.UNRECOGNIZED]: 'Unrecognized',
};

export const avsTypeLabels: Record<AVS_TYPES, string> = {
  [AVS_TYPES.GENERIC]: 'Generic',
  [AVS_TYPES.COPROCESSOR]: 'Co-processor',
  [AVS_TYPES.BRIDGE]: 'Bridge',
  [AVS_TYPES.MACH]: 'AltLayer Mach',
  [AVS_TYPES.ECDSA_HELLO_WORLD]: 'Hello World',
  [AVS_TYPES.CUSTOM_BLS]: 'Custom',
};

export const avsStrategyLabels: Record<AVS_QUORUMS, string> = {
  [AVS_QUORUMS.ETH_LST]: 'ETH/LST',
  [AVS_QUORUMS.EIGEN]: 'EIGEN',
  [AVS_QUORUMS.REALT]: 'reALT',
};

export const StatusLabel = ({ children, color, sx, ...props }: BoxProps) => (
  <Box
    sx={{
      bgcolor: color,
      py: '3px',
      px: 1,
      display: 'flex',
      alignItems: 'center',
      gap: 0.5,
      ...sx,
    }}
    {...props}
  >
    <Box component="img" src="/imgs/iconStatus.svg" sx={{ width: 12, height: 12 }} />
    <Typography variant="captionC">{children}</Typography>
  </Box>
);
export const avsStatusLabelMap: Record<string, ReactNode> = {
  running: <StatusLabel color="#66B489">Live</StatusLabel>,
  initializing: <StatusLabel color="#F8D97D">Initializing</StatusLabel>,
  unknown: <StatusLabel color="#F1605F">Unknown</StatusLabel>,
};
export const rollupStatusLabelMap: Record<RollupDeploymentStatus, ReactNode> = {
  [RollupDeploymentStatus.STATUS_SUCCEED]: <StatusLabel color="#66B489">Live</StatusLabel>,
  [RollupDeploymentStatus.STATUS_ONGOING]: <StatusLabel color="#F8D97D">Deploying</StatusLabel>,
  [RollupDeploymentStatus.STATUS_WAITING]: <StatusLabel color="#F8D97D">Queued</StatusLabel>,
  [RollupDeploymentStatus.STATUS_FAILED]: <StatusLabel color="#F1605F">Failed</StatusLabel>,
  [RollupDeploymentStatus.UNRECOGNIZED]: null,
};

export const getOperatorStatusColors = (
  operator: OperatorState,
  supportedQuorums: AVS_QUORUMS[],
) => {
  const isRegisteredForAllQuorums =
    operator?.registeredQuorums?.length === supportedQuorums?.length;

  if (operator?.registered && operator?.whitelisted && isRegisteredForAllQuorums)
    return { background: '#66B489', text: 'white' };
  if ([operator?.registered, operator?.whitelisted]?.some(cur => cur === true))
    return { background: '#F8D97D', text: 'black' };

  return { background: '#F1605F', text: 'white' };
};
