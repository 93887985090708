import { createContext, useCallback, useContext, useState } from 'react';

interface Context {
  addPendingTx: (txKey: string) => void;
  removePendingTx: (txKey: string) => void;
  pendingTxs: Set<string>;
}

const TxStatusContext = createContext({} as Context);

export const useTxStatus = () => {
  return useContext(TxStatusContext);
};

export const TxStatusContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [pendingTxs, setPendingTxs] = useState<Set<string>>(new Set());

  const addPendingTx = useCallback(
    (txKey: string) => {
      setPendingTxs(prev => {
        const newSet = new Set(prev);

        newSet.add(txKey);

        return newSet;
      });
    },
    [setPendingTxs],
  );
  const removePendingTx = useCallback(
    (txKey: string) => {
      setPendingTxs(prev => {
        const newSet = new Set(prev);

        newSet.delete(txKey);

        return newSet;
      });
    },
    [setPendingTxs],
  );

  return (
    <TxStatusContext.Provider value={{ removePendingTx, addPendingTx, pendingTxs }}>
      {children}
    </TxStatusContext.Provider>
  );
};
