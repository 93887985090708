import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconUncheckedCircle({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ width: '18px', height: '18px', fill: 'none', ...sx }}
      viewBox="0 0 18 18"
      {...props}
    >
      <circle cx="9" cy="8.5" r="8" stroke="#202723" />
    </SvgIcon>
  );
}
