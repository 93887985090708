import { ListRollupResponse, RemoveRollupByIdResponse } from '../types/protoc-gen/rollup';
import { axiosRaas } from '.';

export async function fetchDeployments() {
  const res = await axiosRaas.get<ListRollupResponse>('/deployments');

  return res?.data?.deployment;
}

export async function deleteDeployment(id?: string) {
  const res = await axiosRaas.delete<RemoveRollupByIdResponse>(`/deployments/${id}`);

  return res?.data;
}
