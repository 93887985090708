import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  createFilterOptions,
  FormHelperText,
  InputAdornment,
  ListItem,
  TextField,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';

export type AutocompleteOption = {
  label: string;
  icon?: ReactNode;
  value: string | number;
  isCreatedOption?: boolean;
  createOptionText?: string;
};

interface CustomAutoCompleteBaseProps
  extends Partial<AutocompleteProps<any, boolean, boolean, boolean>> {
  options: AutocompleteOption[];
  creatable?: boolean;
  getCreateOptionText?: (inputValue: string | number) => string;
  getCreatedOptionLabel?: (inputValue: string | number) => string;
  errorMsg?: string;
  helperText?: string;
  placeholder?: string;
}

interface CustomAutoCompleteMultiple extends Omit<CustomAutoCompleteBaseProps, 'multiple'> {
  multiple: true;
  value: AutocompleteOption[] | null;
  afterChange?: (selected: AutocompleteOption[]) => void;
}
interface CustomAutoCompleteSingle extends Omit<CustomAutoCompleteBaseProps, 'multiple'> {
  multiple?: false;
  value: AutocompleteOption | null;
  afterChange?: (selected: AutocompleteOption) => void;
}
type CustomAutoCompleteProps = CustomAutoCompleteMultiple | CustomAutoCompleteSingle;

const filter = createFilterOptions<AutocompleteOption>();

export const CustomAutocomplete: FC<CustomAutoCompleteProps> = ({
  afterChange,
  creatable,
  errorMsg,
  getCreateOptionText = val => `Add ${val}`,
  getCreatedOptionLabel = val => String(val),
  helperText,
  multiple,
  options = [],
  placeholder,
  sx,
  value,
  ...autocompleteProps
}) => {
  const [createdOptions, setCreatedOptions] = useState<AutocompleteOption[]>([]);
  const originalOptions = options;
  const allOptions = [...originalOptions, ...createdOptions];

  return (
    <>
      <Autocomplete
        clearOnBlur
        disableCloseOnSelect={multiple}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          if (creatable) {
            // Suggest the creation of a new value
            const isExisting = options.some(
              option => inputValue?.toLowerCase() === option?.label?.toLowerCase(),
            );

            if (inputValue !== '' && !isExisting) {
              filtered.push({
                value: inputValue,
                label: inputValue,
                isCreatedOption: true,
                createOptionText: getCreateOptionText(inputValue),
              });
            }
          }

          return filtered;
        }}
        freeSolo={creatable}
        isOptionEqualToValue={(option, selected) => {
          return option?.value === selected?.value;
        }}
        multiple={multiple}
        onChange={(_, selected: AutocompleteOption | AutocompleteOption[]) => {
          let modified = selected;

          if (Array.isArray(selected)) {
            setCreatedOptions(selected?.filter(cur => cur.isCreatedOption));
            modified = selected?.map(cur =>
              cur.isCreatedOption ? { ...cur, label: getCreatedOptionLabel(cur.value) } : cur,
            );
          }

          if (!Array.isArray(selected) && selected?.isCreatedOption) {
            modified = { ...selected, label: getCreatedOptionLabel(selected.value) };
          }

          if (multiple) {
            afterChange?.(modified as AutocompleteOption[]);
          } else {
            afterChange?.(modified as AutocompleteOption);
          }
        }}
        options={allOptions}
        renderInput={params => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                ...(!Array.isArray(value) &&
                  value?.icon && {
                    startAdornment: <InputAdornment position="start">{value?.icon}</InputAdornment>,
                  }),
              }}
              hiddenLabel
              placeholder={!value || (Array.isArray(value) && !value?.length) ? placeholder : ''}
              value=""
            />
          );
        }}
        renderOption={(props, option: AutocompleteOption, { selected }) => {
          return multiple ? (
            <li {...props} key={option.value}>
              <Checkbox
                checked={selected}
                checkedIcon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                icon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
              />
              {option.label}
            </li>
          ) : (
            <ListItem
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              {...props}
              key={option.value}
            >
              {option?.icon}
              {option?.createOptionText || option.label}
            </ListItem>
          );
        }}
        slotProps={{ paper: { sx: { borderRadius: 0 } } }}
        sx={{
          background: '#FFFFFF',
          ...sx,
        }}
        value={value || (multiple ? [] : null)}
        {...autocompleteProps}
      />
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      {helperText && <FormHelperText sx={{ color: '#CCCCCC' }}>{helperText}</FormHelperText>}
    </>
  );
};
