import '@rainbow-me/rainbowkit/styles.css';

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack } from '@mui/material';
import FormTextField from 'components/form-fields/FormTextField';
import { useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { RollupType } from 'types/protoc-gen/rollup';
import * as yup from 'yup';

import { PageBanner } from '../../components/Layout/PageBanner';
import { SectionTitle } from '../../components/SectionTitle';
import SelectAVSType, { avsTypeOptions } from './form-steps/SelectAVSType';
import SelectChainAndStack from './form-steps/SelectChainAndStack';
import SelectStrategies from './form-steps/SelectStrategies';
import AVSSummaryPanel from './AVSSummaryPanel';
import CodePanel from './CodePanel';
import { DeployAvsPopup } from './DeployAvsPopup';
import { schema } from './validationSchema';

export interface AVSFormValues {
  avsName?: string;
  avsType: AVS_TYPES;
  strategies: AVS_QUORUMS[];
  ecdsaWeight?: bigint;
  chains?: {
    chainId: { value: number };
    rollupType: { value: RollupType };
  }[];
  customTemplate?: {
    abi: any[];
    bytecode: string;
  };
  initArgs?: any[];
  constructorArgs?: any[];
}

export function NewAVS() {
  const [showDeployPopup, setShowDeployPopup] = useState(false);

  const form = useForm<AVSFormValues>({
    mode: 'onChange',
    defaultValues: {
      avsName: undefined,
      avsType: AVS_TYPES.GENERIC,
      strategies: [AVS_QUORUMS.ETH_LST],
    },
    resolver: yupResolver(schema as yup.ObjectSchema<AVSFormValues>),
  });
  const avsType = useWatch({ name: 'avsType', control: form.control });

  const onSubmit: SubmitHandler<AVSFormValues> = data => {
    console.log(data);
    setShowDeployPopup(true);
    // create(data);
  };

  const onError: SubmitErrorHandler<AVSFormValues> = formErrors => {
    console.error(formErrors);
  };

  return (
    <>
      <PageBanner title="Deploy an AVS" />
      <Box bgcolor="#fafafa" pb={4}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            {showDeployPopup && (
              <DeployAvsPopup onClose={() => setShowDeployPopup(false)} open={true} />
            )}
            <Box margin="0 auto">
              <Stack flexDirection="row">
                <Box flex={1}>
                  <SectionTitle>Build your stack</SectionTitle>
                  <Grid container mt={4}>
                    <Grid item lg={8} md={10} xl={6} xs={12}>
                      <FormTextField
                        fieldConfig={{
                          name: 'avsName',
                          label: 'AVS Name',
                          placeholder: 'abc-net',
                        }}
                        id="step_avsName"
                      />
                    </Grid>
                  </Grid>
                  <SelectAVSType id="step_avsType" />
                  <CodePanel avsType={avsType} />
                  {avsType === AVS_TYPES.MACH && <SelectChainAndStack />}
                  <SelectStrategies />
                  {avsTypeOptions?.find(cur => cur?.value === avsType)?.sig === 'ecdsa' && (
                    <FormTextField
                      fieldConfig={{
                        name: 'ecdsaWeight',
                        label: 'Threshold Weight',
                        placeholder: 'Enter threshold weight',
                      }}
                      id="step_ecdsaWeight"
                      tooltipContent="The threshold weight required to validate a message. This is the cumulative weight that must be met or exceeded by the sum of the stakes of the signatories for a message to be deemed valid."
                      type="number"
                    />
                  )}
                </Box>
                <Box width="30%">
                  <AVSSummaryPanel />
                </Box>
              </Stack>
            </Box>
          </form>
          <DevTool control={form.control} />
        </FormProvider>
      </Box>
    </>
  );
}
