import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { deploymentNameValidation, fnArgsValidation } from 'utils/yupCustomValidations';
import * as yup from 'yup';

import { avsTypeOptions } from './form-steps/SelectAVSType';

export const schema = yup
  .object()
  .shape({
    avsName: deploymentNameValidation,
    chains: yup.array().when('avsType', {
      is: AVS_TYPES.MACH,
      then: schema =>
        schema
          .of(
            yup.object().shape({
              chainId: yup
                .object()
                .shape({
                  value: yup.number().required('Chain ID is required.'),
                })
                .required('Chain ID is required.'),
              rollupType: yup
                .object()
                .shape({
                  value: yup.number().required('Rollup stack is required.'),
                })
                .required('Rollup stack is required.'),
            }),
          )
          .min(1)
          .unique('Chain IDs must be unique', cur => cur?.chainId?.value)
          .required(),
    }),
    avsType: yup.number().oneOf(Object.values(AVS_TYPES)).required(),
    strategies: yup
      .array()
      .of(yup.number().oneOf(Object.values(AVS_QUORUMS)).required())
      .required(),
    ecdsaWeight: yup.number().when('avsType', {
      is: (val: AVS_TYPES) => avsTypeOptions?.find(cur => cur?.value === val)?.sig === 'ecdsa',
      then: (schema: ReturnType<typeof yup.number>) => schema.min(1).max(1000000000000).required(),
    }),
    customTemplate: yup.object().when('avsType', {
      is: AVS_TYPES.CUSTOM_BLS,
      then: schema =>
        schema
          .shape({
            abi: yup.array().required(),
            bytecode: yup.string().required(),
          })
          .required(),
    }),
    initArgs: fnArgsValidation,
    constructorArgs: fnArgsValidation,
  })
  .required();
