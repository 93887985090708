import './polyfills.js';
import '@rainbow-me/rainbowkit/styles.css';
import './loadYupCustomMethods';

import { ThemeProvider } from '@mui/material';
import { TxStatusContextProvider } from 'contexts/TxStatusContext';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AlertsProvider } from './contexts/AlertsContext';
import { WagmiProvider } from './contexts/WagmiContext';
import App from './App';
import { theme } from './theme';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AlertsProvider>
            <WagmiProvider>
              <TxStatusContextProvider>
                <App />
              </TxStatusContextProvider>
            </WagmiProvider>
          </AlertsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>,
  );
}
