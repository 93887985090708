import { Box, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { IoAddSharp, IoArrowForwardSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { fetchDeployments } from '../../api/deployments';
import { PATHS } from '../../App';
import { PageBanner } from '../../components/Layout/PageBanner';
import {
  chainLabels,
  daTypeLabels,
  rollupStatusLabelMap,
  rollupTypeLabels,
} from '../../labels/deployments';
import { SETTLEMENT_CHAINS } from '../../types/deployments';
import { Deployment } from '../../types/protoc-gen/rollup';
import { QUERY_KEYS } from '../../types/react-query';

interface InitParams {
  chainName: string;
  chainId: number;
  parentChainId: SETTLEMENT_CHAINS;
  challengePeriod: number;
}

function DeploymentCard({ deployment }: { deployment: Deployment }) {
  const { chainName, parentChainId }: InitParams = JSON.parse(deployment.initParam || '{}');

  const navigate = useNavigate();

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={() => navigate(`/rollups/${deployment.deploymentId}`)}
        sx={{
          cursor: 'pointer',
          background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
          px: 5,
          py: 3,
        }}
      >
        <Typography
          alignItems="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          variant="bodyBold"
        >
          {rollupStatusLabelMap[deployment.deploymentStatus]}
          {chainName}
        </Typography>
        <Box component={IoArrowForwardSharp} size={24} />
      </Stack>
      <Stack px={5} sx={{ background: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)' }}>
        <Stack direction="row" py={3}>
          <Stack flex={1}>
            <Typography variant="caption">Framework</Typography>
            <Typography variant="bodySmallM">
              {rollupTypeLabels?.[deployment.rollupType]}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="caption">Settlement Layer</Typography>
            <Typography variant="bodySmallM">{chainLabels?.[parentChainId]}</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack py={3}>
          <Typography variant="caption">Data availability layer</Typography>
          <Typography variant="bodySmallM">{daTypeLabels?.[deployment.daType]}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function RaasDeployments() {
  const navigate = useNavigate();
  const { data: deployments, isLoading: isLoadingRollupDeployments } = useQuery({
    queryKey: [QUERY_KEYS.GET_DEPLOYMENTS],
    queryFn: fetchDeployments,
  });

  return (
    <>
      <PageBanner title="Rollup Dashboard" />
      <Grid
        alignItems="center"
        container
        gap={2}
        sx={{
          background: '#fafafa',
          px: 5,
          py: 4,
          border: '1px solid rgba(32, 39, 35, 0.08)',
        }}
      >
        <Typography variant="h5">Your Deployments</Typography>
        {isLoadingRollupDeployments && <CircularProgress size={20} />}
      </Grid>
      <Grid
        container
        sx={{ '&>*': { minHeight: '12rem', boxShadow: '0px 0px 2px rgba(32, 39, 35, 0.15)' } }}
      >
        {deployments?.map(deployment => (
          <Grid item key={deployment.deploymentId} xs={4}>
            <DeploymentCard deployment={deployment} />
          </Grid>
        ))}

        {process.env.NODE_ENV === 'development' && (
          <Grid
            alignItems="center"
            container
            flexDirection="column"
            item
            justifyContent="center"
            onClick={() => navigate(PATHS.NEW_DEPLOYMENT_DEPR)}
            sx={{ bgcolor: '#fff', cursor: 'pointer' }}
            xs={4}
          >
            <IoAddSharp size={24} />
            <Typography mt={3} variant="bodySmallC">
              New Deployment (Depr)
            </Typography>
          </Grid>
        )}
        <Grid
          alignItems="center"
          container
          flexDirection="column"
          item
          justifyContent="center"
          onClick={() => navigate(PATHS.NEW_DEPLOYMENT)}
          sx={{ bgcolor: '#fff', cursor: 'pointer' }}
          xs={4}
        >
          <IoAddSharp size={24} />
          <Typography mt={3} variant="bodySmallC">
            New Deployment
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
