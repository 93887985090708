import { WriteContractReturnType } from '@wagmi/core';
import AvsCreatorAbi from 'contracts/AvsCreatorAbi';
import BridgeTemplate from 'contracts/templates/BridgeTemplate';
import CoprocessorTemplate from 'contracts/templates/CoprocessorTemplate';
import ECDSAHelloWorldTemplate from 'contracts/templates/ECDSAHelloWorldTemplate';
import GenericTemplate from 'contracts/templates/GenericTemplate';
import MachTemplate from 'contracts/templates/MachTemplate';
import { useEffect, useMemo } from 'react';
import { AVS_QUORUMS, AVS_TYPES, strategiesByQuorums } from 'types/avs';
import { RollupType } from 'types/protoc-gen/rollup';
import {
  AbiParameter,
  Address,
  encodeAbiParameters,
  encodeFunctionData,
  Hash,
  parseAbiParameters,
  parseEther,
} from 'viem';
import { useAccount, useReadContract } from 'wagmi';

import { useWriteTx } from './useWriteTx';

export const avsCreatorAddr = '0xF395C24f416ADf4A5552Fb5DAcF4c4da2a099c9C';

// Define parameters for operator set and strategies
const minimumStake = 0n;

const avsTypeTemplateMap = {
  [AVS_TYPES.GENERIC]: GenericTemplate,
  [AVS_TYPES.BRIDGE]: BridgeTemplate,
  [AVS_TYPES.COPROCESSOR]: CoprocessorTemplate,
  [AVS_TYPES.MACH]: MachTemplate,
  [AVS_TYPES.ECDSA_HELLO_WORLD]: ECDSAHelloWorldTemplate,
};

interface UseAvsCreatorArgs {
  // onError?: (error: WriteContractErrorType | WaitForTransactionReceiptErrorType) => void;
  onCreationInitialized?: (txReceipt: WriteContractReturnType) => void;
  onCreationFinalized?: (txReceipt: WriteContractReturnType) => void;
  avsQuorums: AVS_QUORUMS[];
  avsType: AVS_TYPES;
  chains?: {
    chainId: { value: number };
    rollupType: { value: RollupType };
  }[];
  thresholdWeight?: bigint;
  customTemplate?: { abi: any; bytecode: string; initArgs: any[]; constructorArgs: any[] };
}

export const useAvsCreator = (props: UseAvsCreatorArgs) => {
  const {
    avsQuorums,
    avsType,
    chains,
    customTemplate,
    onCreationFinalized,
    onCreationInitialized,
    thresholdWeight,
  } = props;

  const { minimumStakeForQuorum, operatorSetParams, strategyParams } = useMemo(() => {
    const numQuorums = avsQuorums?.length || 0;
    const BPS = 10_000n; // hardcoded in ECDSAStakeRegistryStorage

    return {
      // Strategy parameters per quorum, mapping each strategy to a weight. Total must === BPS (10_000)
      strategyParams: avsQuorums?.map(quorum =>
        strategiesByQuorums[quorum]
          ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1)) // Reverts if sorted by checksummed address
          ?.map((token, index) => ({
            strategy: token,
            multiplier:
              avsType !== AVS_TYPES.ECDSA_HELLO_WORLD
                ? parseEther('1')
                : index === 0
                ? BPS / BigInt(strategiesByQuorums[quorum]?.length) +
                  (BPS % BigInt(strategiesByQuorums[quorum]?.length))
                : BPS / BigInt(strategiesByQuorums[quorum]?.length),
          })),
      ),
      // Define operator set parameters for each quorum
      operatorSetParams: new Array(numQuorums).fill({
        maxOperatorCount: 50,
        kickBIPsOfOperatorStake: 11000,
        kickBIPsOfTotalStake: 1001,
      }),
      // Prepare minimum stakes array
      minimumStakeForQuorum: Array(numQuorums).fill(minimumStake),
    };
  }, [avsQuorums, avsType]);

  useEffect(() => {
    console.debug('strategyParams changed to: ', strategyParams);
    console.debug('operatorSetParams changed to: ', operatorSetParams);
    console.debug('minimumStakeForQuorum changed to: ', minimumStakeForQuorum);
  }, [strategyParams, operatorSetParams, minimumStakeForQuorum]);

  const { address } = useAccount();

  const {
    isPending: isInitPending,
    txHash: initTxHash,
    write: initializeCreation,
  } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: avsCreatorAddr,
    txKey: `init_${address}`,
    functionName: 'initializeCreation',
    functionArgs: [
      address,
      address,
      address,
      operatorSetParams,
      minimumStakeForQuorum,
      strategyParams,
    ],
    onTxConfirmed: txReceipt => {
      onCreationInitialized?.(txReceipt);
    },
  });

  const { data: ongoingDeployments } = useReadContract({
    abi: AvsCreatorAbi,
    address: avsCreatorAddr,
    functionName: 'ongoingDeployments',
    args: [address as Address],
    query: {
      enabled: Boolean(address && initTxHash),
    },
  });
  const [
    initialOwner,
    _proxyAdmin,
    pauserRegistry,
    _indexRegistryProxy,
    stakeRegistryProxy,
    _apkRegistryProxy,
    registryCoordinatorProxy,
    _serviceManagerProxy,
  ] = ongoingDeployments || [];

  const constructorArgsBytecode = useMemo(() => {
    console.log('registryCoordinatorProxy: ', registryCoordinatorProxy);
    console.log('stakeRegistryProxy: ', stakeRegistryProxy);

    if (avsType === AVS_TYPES.CUSTOM_BLS) {
      if (customTemplate) {
        const constructorArgTypes =
          customTemplate.abi
            ?.find((cur: any) => cur?.type === 'constructor')
            ?.inputs?.map((cur: any) => cur?.type) || [];

        console.log('constructorArgTypes: ', constructorArgTypes);
        console.log('constructorArgValues: ', customTemplate.constructorArgs);

        return encodeAbiParameters(
          parseAbiParameters(constructorArgTypes) as readonly AbiParameter[],
          customTemplate.constructorArgs,
        );
      }

      return '';
    }

    if (!registryCoordinatorProxy || !stakeRegistryProxy) return '';

    if (avsType === AVS_TYPES.MACH || avsType === AVS_TYPES.COPROCESSOR) {
      return encodeAbiParameters(parseAbiParameters(['address', 'address', 'address', 'address']), [
        '0x055733000064333caddbc92763c58bf0192ffebf', // AVS Directory
        '0xacc1fb458a1317e886db376fc8141540537e68fe', // Rewards Coordinator
        registryCoordinatorProxy,
        stakeRegistryProxy,
      ]);
    }

    return encodeAbiParameters(
      parseAbiParameters(['address', 'address', 'address', 'address', 'uint32']),
      [
        '0x055733000064333caddbc92763c58bf0192ffebf', // AVS Directory
        '0xacc1fb458a1317e886db376fc8141540537e68fe', // Rewards Coordinator
        registryCoordinatorProxy,
        stakeRegistryProxy,
        30,
      ],
    );
  }, [avsType, customTemplate, registryCoordinatorProxy, stakeRegistryProxy]);

  const encodedFnData = useMemo(() => {
    console.log('pauserRegistry: ', pauserRegistry);

    if (avsType === AVS_TYPES.ECDSA_HELLO_WORLD && !!address) {
      const res = encodeFunctionData({
        abi: avsTypeTemplateMap[avsType].abi,
        functionName: 'initialize',
        args: [address, address, address],
      });

      return res;
    }

    if (avsType === AVS_TYPES.CUSTOM_BLS) {
      if (customTemplate) {
        const res = encodeFunctionData({
          abi: customTemplate.abi,
          functionName: 'initialize',
          args: customTemplate.initArgs,
        });

        return res;
      }

      return null;
    }

    if (!initialOwner || !pauserRegistry) return null;

    return encodeFunctionData({
      abi: avsTypeTemplateMap[avsType].abi,
      functionName: 'initialize',
      args:
        avsType === AVS_TYPES.COPROCESSOR
          ? [pauserRegistry, 0n, initialOwner, initialOwner, initialOwner]
          : avsType === AVS_TYPES.MACH
          ? [
              pauserRegistry,
              0n,
              initialOwner,
              initialOwner,
              initialOwner,
              initialOwner,
              chains?.map(cur => BigInt(cur.chainId?.value || 0)) || [],
            ]
          : [
              pauserRegistry,
              0n,
              initialOwner,
              initialOwner,
              initialOwner,
              initialOwner,
              initialOwner,
            ],
    });
  }, [avsType, address, initialOwner, pauserRegistry, chains, customTemplate]);

  const {
    isPending: isFinalizing,
    txHash: finalizeTxHash,
    write: finalizeCreation,
  } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: avsCreatorAddr,
    txKey: `finalize_${address}`,
    functionName: 'finalizeCreation',
    functionArgs: [
      avsType === AVS_TYPES.CUSTOM_BLS
        ? customTemplate?.bytecode
        : avsTypeTemplateMap[avsType].bytecode.object,
      constructorArgsBytecode,
      encodedFnData,
    ],
    onTxConfirmed: () => {
      onCreationFinalized?.(initTxHash as Hash);
    },
  });

  console.debug(
    'total multiplier: ',
    strategyParams?.[0]?.reduce((sum, cur) => sum + cur?.multiplier || 0n, 0n),
  );

  const {
    isPending: isCreatingECDSA,
    txHash: createECDSATxHash,
    write: createECDSA,
  } = useWriteTx({
    contractAbi: AvsCreatorAbi,
    contractAddress: avsCreatorAddr,
    txKey: `createECDSA_${address}`,
    functionName: 'createECDSA',
    functionArgs:
      avsType !== AVS_TYPES.ECDSA_HELLO_WORLD
        ? undefined
        : [
            address,
            thresholdWeight,
            strategyParams?.[0],
            avsTypeTemplateMap[avsType].bytecode.object,
            constructorArgsBytecode,
            encodedFnData,
          ],
    onTxConfirmed: txReceipt => {
      onCreationFinalized?.(txReceipt);
    },
  });

  const status = useMemo(() => {
    if (createECDSATxHash || finalizeTxHash) {
      return 'finalized';
    }

    if (initTxHash && encodedFnData && constructorArgsBytecode) {
      return 'readyForFinalize';
    }

    if (initTxHash) {
      return 'initialized';
    }

    return 'idle';
  }, [constructorArgsBytecode, createECDSATxHash, encodedFnData, finalizeTxHash, initTxHash]);

  return useMemo(
    () => ({
      initializeCreation,
      isInitPending,
      initTxHash,
      finalizeTxHash,
      finalizeCreation,
      isFinalizing,
      createECDSA,
      isCreatingECDSA,
      createECDSATxHash,
      status,
    }),
    [
      createECDSA,
      createECDSATxHash,
      finalizeCreation,
      finalizeTxHash,
      initTxHash,
      initializeCreation,
      isCreatingECDSA,
      isFinalizing,
      isInitPending,
      status,
    ],
  );
};
