import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconLink({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '14px', height: '14px', ...sx }} viewBox="0 0 14 14" {...props}>
      <path
        d="M2.59961 10.3999L11.5996 1.3999M11.5996 1.3999H2.59961M11.5996 1.3999V10.3999"
        stroke="#202723"
      />
    </SvgIcon>
  );
}
