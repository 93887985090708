import { TableCell, TableRow, TableRowProps, Typography } from '@mui/material';
import { ColumnConfig } from 'types/table';

interface TRowProps<TRowData> extends TableRowProps {
  columnConfig: ColumnConfig<TRowData>[];
  rowData: TRowData;
}

function TRow<TRowData>({ columnConfig, rowData, ...rest }: TRowProps<TRowData>) {
  return (
    <TableRow hover {...rest}>
      {columnConfig?.map(col => (
        <TableCell align={col.align} key={String(col.id)} style={{ width: col.width }}>
          {typeof col.Cell(rowData) === 'string' ? (
            <Typography gutterBottom noWrap variant="body1">
              {col.Cell(rowData)}
            </Typography>
          ) : (
            col.Cell(rowData)
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default TRow;
