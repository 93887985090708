import { Box, Button, Stack, Typography } from '@mui/material';
import { avsStrategyLabels, avsTypeLabels, rollupTypeLabels } from 'labels/deployments';
import { Checked } from 'pages/NewDeployment/SummaryPanel';
import { useMemo } from 'react';
import { FieldName, useFormContext, useFormState } from 'react-hook-form';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { RollupType } from 'types/protoc-gen/rollup';
import { truncate } from 'utils/strings';

import { AVSFormValues } from '.';

const labelMap: Record<FieldName<AVSFormValues>, string> = {
  avsName: 'AVS Name:',
  avsType: 'AVS Type:',
  strategies: 'Strategies:',
  chains: 'Chains:',
  ecdsaWeight: 'Threshold Weight: ',
  customTemplate: 'Compiled: ',
  initArgs: 'initialize() args: ',
  constructorArgs: 'constructor() args: ',
};

function getDisplayValue(fieldName: FieldName<AVSFormValues>, value: any) {
  switch (fieldName) {
    case 'avsType':
      return avsTypeLabels?.[value as AVS_TYPES];
    case 'strategies':
      return value?.map((cur: AVS_QUORUMS) => avsStrategyLabels?.[cur])?.join(', ');
    case 'chains':
      return value
        ?.map((cur: { chainId: { value: number }; rollupType: { value: RollupType } }) =>
          cur?.chainId?.value && cur?.rollupType?.value
            ? `${cur?.chainId?.value}|${rollupTypeLabels?.[cur?.rollupType?.value]}`
            : '',
        )
        ?.join(', ');
    case 'customTemplate':
      return String(Boolean(value?.bytecode));
    case 'initArgs':
      return value
        ?.filter((cur: any) => !!cur?.value)
        ?.map((cur: any) =>
          cur?.type === 'address' || cur?.type?.startsWith?.('bytes')
            ? truncate(cur?.value || '', '…', 4, 2)
            : cur?.value,
        )
        ?.join(', ');
    case 'constructorArgs':
      return value
        ?.filter((cur: any) => !!cur?.value)
        ?.map((cur: any) =>
          cur?.type === 'address' || cur?.type?.startsWith?.('bytes')
            ? truncate(cur?.value || '', '…', 4, 2)
            : cur?.value,
        )
        ?.join(', ');
    default:
      return value && String(value);
  }
}

export default function AVSSummaryPanel() {
  const form = useFormContext<AVSFormValues>();
  const formValues = form?.watch();
  const formState = useFormState<AVSFormValues>({ control: form.control });

  console.debug('formState?.errors?: ', formState?.errors);

  const summaryItems = useMemo(
    () =>
      Object.entries(formValues)?.map(([name, value]) => {
        const fieldName = name;

        return {
          isError:
            (formState?.errors as any)?.[name]?.message ||
            (formState?.errors as any)?.[name]?.root?.message ||
            (formState?.errors as any)?.[name]?.some?.((cur: any) =>
              Object.values(cur || {})?.some?.(val => Boolean(val)),
            ),
          fieldName,
          displayValue: getDisplayValue(fieldName, value),
          value,
        };
      }),
    [formValues, formState],
  );

  const scrollToStep = (fieldName: FieldName<AVSFormValues>) => () => {
    const el = document.getElementById(`step_${fieldName}`);

    el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(118.58deg, #F6F6F6 0%, #E7E7E7 100%)',
        m: { md: 5 },
        position: 'sticky',
        top: 40,
      }}
    >
      <Stack alignItems="center" direction="row" gap={1} sx={{ px: 5, py: 2 }}>
        <Typography pt={5} variant="captionC">
          Summary
        </Typography>
      </Stack>
      {summaryItems?.map(cur => (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          key={cur.fieldName}
          onClick={scrollToStep(cur.fieldName)}
          sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)', cursor: 'pointer' }}
        >
          <Checked
            valid={
              cur.isError
                ? false
                : cur.value === undefined ||
                  (Array.isArray(cur.value) &&
                    typeof cur.value?.[0] === 'object' &&
                    cur.value?.some?.(field => field?.value === undefined))
                ? undefined
                : true
            }
          />
          <Typography variant="caption">{labelMap?.[cur?.fieldName]}</Typography>
          <Typography sx={{ wordBreak: 'break-all' }} variant="captionM">
            {cur.displayValue}
          </Typography>
        </Stack>
      ))}
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ px: 5, py: 2, borderTop: '1px solid rgba(32, 39, 35, 0.08)' }}
      >
        <Button fullWidth type="submit">
          Submit
        </Button>
      </Stack>
    </Box>
  );
}
