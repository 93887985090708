import { Editor } from '@monaco-editor/react';
import { Box, Collapse, Link, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { BiCaretDown } from 'react-icons/bi';
import { GrGithub } from 'react-icons/gr';
import { AVS_TYPES } from 'types/avs';
import { avsTypeToSrc } from 'utils/avs';

import CustomContractFields from './CustomContractFields';

export default function CodePanel({ avsType }: { avsType: AVS_TYPES }) {
  const { data: svcMngrSrc } = useQuery({
    queryKey: ['LOAD_SVC_MNGR_SRC', avsType],
    queryFn: async () => (await axios.get<string>(String(avsTypeToSrc[avsType]?.raw)))?.data,
    enabled: avsType !== undefined && Boolean(avsTypeToSrc[avsType]?.raw),
  });

  const [showCode, setShowCode] = useState(false);

  return (
    <Box sx={{ pl: { md: 5, xs: 2.5 } }}>
      {avsType !== AVS_TYPES.CUSTOM_BLS && (
        <Typography
          onClick={() => setShowCode(prev => !prev)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mx: 'auto',
            mt: 2,
            width: 'max-content',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
          }}
          variant="caption"
        >
          {showCode ? 'Hide' : 'View'} Source
          <Box component={BiCaretDown} sx={{ transform: showCode ? 'rotate(180deg)' : 'none' }} />
        </Typography>
      )}
      <Collapse in={showCode || avsType === AVS_TYPES.CUSTOM_BLS} sx={{ mt: 2 }}>
        {avsTypeToSrc[avsType]?.repoLink && (
          <Stack alignItems="center" direction="row" gap={1} mb={1}>
            <GrGithub size={20} />
            <Link
              href={avsTypeToSrc[avsType]?.repoLink}
              rel="noopener noreferrer"
              sx={{ '&:hover': { textDecoration: 'underline' }, color: '#5C8CE1' }}
              target="_blank"
            >
              {avsTypeToSrc[avsType]?.repoLink}
            </Link>
          </Stack>
        )}
        {avsType === AVS_TYPES.CUSTOM_BLS ? (
          <CustomContractFields />
        ) : (
          <Editor
            height="600px"
            language="sol"
            options={{
              selectOnLineNumbers: true,
              scrollBeyondLastLine: false,
              readOnly: true,
            }}
            theme="vs-dark"
            value={svcMngrSrc}
          />
        )}
      </Collapse>
    </Box>
  );
}
