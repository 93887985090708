import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconExpanded({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ fill: 'none', height: '18px', width: '18px', ...sx }}
      {...props}
      viewBox="0 0 18 18"
    >
      <path d="M1.8002 12.6L9.0002 5.40001L16.2002 12.6" stroke="#202723" />
    </SvgIcon>
  );
}
