import numeral from 'numeral';
import { formatUnits, parseUnits } from 'viem';

export function truncate(fullStr: string, separator = '...', frontChars = 8, backChars = 6) {
  const truncateIfExceedLen = frontChars + backChars;

  if (fullStr.length <= truncateIfExceedLen) return fullStr;

  return (
    fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
  );
}

function trimZero(v: string) {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/;

  return v.replace(regexp, '$1');
}

export function isPositiveNumber(target: string): boolean {
  return /^\d+(\.{0,1}\d+){0,1}$/.test(target);
}

export function commify(amount: number | string) {
  // With usage of formatWithPrecision, there is a possibility of amount string being >= "1e21" or <= "1e-7" which will result in NaN
  const result = numeral(amount)?.format('0,0.[000000000000000000]');

  return result === 'NaN' ? '0' : result;
}

/**
 * @description Formats a number / bigint to 6 significant figures (default) and trims trailing zeros.
 * @description 0 dp for large integers
 * @returns string
 */
export function formatWithPrecision(value: number | bigint, _precision = 4, decimals = 18) {
  const stringVal = typeof value === 'number' ? value?.toString() : formatUnits(value, decimals);
  const integerLength = stringVal?.split('.')?.[0]?.length;
  // 0.123124125123 -> 0.1231
  // 12345123754.12412412124 -> 12345123754
  // 123.12344 -> 123.1
  // 123456.789 -> 123457
  const precision = integerLength > _precision ? integerLength : _precision;

  return trimZero(Number(stringVal)?.toPrecision(precision));
}

export function parseUnitsSafe(value: string, decimals: number): bigint {
  let result;

  try {
    result = parseUnits(value, decimals);
  } catch (error) {
    console.error('Parse units error: ', error);
    result = 0n;
  }

  return result;
}
