import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconArb({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '36px', height: '40px', ...sx }} viewBox="0 0 36 40" {...props}>
      <path
        d="M1.61816 12.1599V27.8399C1.61816 28.8479 2.14616 29.7599 3.02616 30.2719L16.6102 38.1119C17.4742 38.6079 18.5462 38.6079 19.4102 38.1119L32.9942 30.2719C33.8582 29.7759 34.4022 28.8479 34.4022 27.8399V12.1599C34.4022 11.1519 33.8742 10.2399 32.9942 9.72787L19.4102 1.88787C18.5462 1.39187 17.4742 1.39187 16.6102 1.88787L3.02616 9.72787C2.16216 10.2239 1.63416 11.1519 1.63416 12.1599H1.61816Z"
        fill="#213147"
      />
      <path
        d="M20.9613 23.0404L19.0253 28.3524C18.9773 28.4964 18.9773 28.6564 19.0253 28.8164L22.3533 37.9524L26.2093 35.7284L21.5853 23.0404C21.4733 22.7524 21.0733 22.7524 20.9613 23.0404Z"
        fill="#12AAFF"
      />
      <path
        d="M24.8502 14.112C24.7382 13.824 24.3382 13.824 24.2262 14.112L22.2902 19.424C22.2422 19.568 22.2422 19.728 22.2902 19.888L27.7462 34.848L31.6021 32.624L24.8502 14.128V14.112Z"
        fill="#12AAFF"
      />
      <path
        d="M18.0014 2.48C18.0974 2.48 18.1934 2.512 18.2734 2.56L32.9614 11.04C33.1374 11.136 33.2334 11.328 33.2334 11.52V28.48C33.2334 28.672 33.1214 28.864 32.9614 28.96L18.2734 37.44C18.1934 37.488 18.0974 37.52 18.0014 37.52C17.9054 37.52 17.8094 37.488 17.7294 37.44L3.04144 28.96C2.86544 28.864 2.76944 28.672 2.76944 28.48V11.504C2.76944 11.312 2.88144 11.12 3.04144 11.024L17.7294 2.544C17.8094 2.496 17.9054 2.464 18.0014 2.464V2.48ZM18.0014 0C17.4734 0 16.9614 0.128 16.4814 0.4L1.79344 8.88C0.849437 9.424 0.273438 10.416 0.273438 11.504V28.464C0.273438 29.552 0.849437 30.544 1.79344 31.088L16.4814 39.568C16.9454 39.84 17.4734 39.968 18.0014 39.968C18.5294 39.968 19.0414 39.84 19.5214 39.568L34.2094 31.088C35.1534 30.544 35.7294 29.552 35.7294 28.464V11.504C35.7294 10.416 35.1534 9.424 34.2094 8.88L19.5054 0.4C19.0414 0.128 18.5134 0 17.9854 0H18.0014Z"
        fill="#9DCCED"
      />
      <path
        d="M8.27246 34.8643L9.63246 31.1523L12.3525 33.4083L9.80846 35.7443L8.27246 34.8643Z"
        fill="#213147"
      />
      <path
        d="M16.754 10.3041H13.026C12.754 10.3041 12.498 10.4801 12.402 10.7361L4.41797 32.6241L8.27397 34.8481L17.074 10.7361C17.154 10.5121 16.994 10.2881 16.77 10.2881L16.754 10.3041Z"
        fill="white"
      />
      <path
        d="M23.2823 10.3041H19.5543C19.2823 10.3041 19.0263 10.4801 18.9303 10.7361L9.81027 35.7281L13.6663 37.9521L23.5863 10.7361C23.6663 10.5121 23.5063 10.2881 23.2823 10.2881V10.3041Z"
        fill="white"
      />
    </SvgIcon>
  );
}
