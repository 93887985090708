import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useAccount } from 'wagmi';

import EnsureNetworkButton from './EnsureNetworkButton';

export const ConditionalConnectButton: FC<{ bypass?: boolean; chainId?: number } & BoxProps> = ({
  bypass = false,
  chainId = 17000, //  holesky
  children,
  sx,
  ...props
}) => {
  const { chainId: currentChainId, isConnected } = useAccount();

  const isRouteAccessible =
    bypass || (chainId ? currentChainId === chainId && isConnected : isConnected);

  return isRouteAccessible ? (
    children
  ) : (
    <>
      {isConnected && (
        <Typography textAlign="center">Please switch to chain ID: {chainId}</Typography>
      )}
      <Box
        sx={{
          width: '100%',
          '& > div': { display: 'flex', flex: 1 },
          '&& > div > button': {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '56px',
            border: '1px solid red',
          },
          ...sx,
        }}
        {...props}
      >
        <EnsureNetworkButton fullWidth />
      </Box>
    </>
  );
};
