import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CopyableText from 'components/Copyable/CopyableText';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import RegistryCoordinatorAbi from 'contracts/RegistryCoordinatorAbi';
import BridgeTemplate from 'contracts/templates/BridgeTemplate';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { useOperators } from 'hooks/useOperators';
import { useWriteTx } from 'hooks/useWriteTx';
import { ChangeEventHandler, useMemo, useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { AVSDeployment } from 'types/protoc-gen/avs';
import { Address, ContractFunctionName, getAddress, isAddress } from 'viem';

interface RegCoordinatorFnParams {
  name: ContractFunctionName<typeof RegistryCoordinatorAbi>;
  abi: typeof RegistryCoordinatorAbi;
  address: Address;
}
interface SvcMngrFnParams {
  name: ContractFunctionName<typeof BridgeTemplate.abi>;
  abi: typeof BridgeTemplate.abi;
  address: Address;
}
interface RoleItem {
  label: string;
  value: string;
  editFn?: SvcMngrFnParams | RegCoordinatorFnParams;
  requiredSender?: Address;
}

function EditRoleField({
  item,
  onSuccess,
  refetchRoles,
}: {
  item: Required<RoleItem>;
  refetchRoles: () => void;
  onSuccess?: () => void;
}) {
  const [input, setInput] = useState('');
  const [inputError, setInputError] = useState('');
  const { isPending, write } = useWriteTx({
    contractAbi: item?.editFn?.abi,
    functionName: item?.editFn?.name,
    contractAddress: item?.editFn?.address,
    functionArgs: [isAddress(input) ? getAddress(input) : input],
    txKey: `edit_${item?.label}_${item?.editFn?.address}_${input}`,
    onTxConfirmed: () => {
      refetchRoles();
      onSuccess?.();
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const val = e?.target?.value;

    if (inputError && isAddress(input)) {
      setInputError('');
    }

    setInput(val);
  };

  return (
    <>
      <TextField
        InputProps={{ sx: { '&&>input': { fontSize: 12 } } }}
        error={Boolean(inputError)}
        fullWidth
        helperText={inputError}
        onChange={handleChange}
        placeholder={`New ${item?.label} Address`}
        value={input}
      />
      <Web3LoadingButton
        loading={isPending}
        onClick={() => {
          if (!isAddress(input)) {
            setInputError('Please enter a valid address');

            return;
          }

          setInputError('');
          write();
        }}
        requiredSender={item?.requiredSender}
        variant="contained"
      >
        Edit
      </Web3LoadingButton>
    </>
  );
}

function RoleList({
  isAvsOwner,
  item,
  refetchRoles,
}: {
  item: RoleItem;
  refetchRoles: () => void;
  isAvsOwner: boolean;
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const isAddress = String(item?.value)?.startsWith('0x');

  return item?.value === 'undefined' ? null : (
    <Stack gap={1} mb={2} mr={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="bodySmall">{item?.label}:</Typography>
        {isAddress && isAvsOwner && (
          <Box
            component={BiEditAlt}
            onClick={() => {
              setIsEditMode(prev => !prev);
            }}
            size={17}
            sx={{ cursor: 'pointer', opacity: isEditMode ? 1 : 0.5 }}
          />
        )}
      </Stack>
      {isAddress ? (
        <CopyableText
          href={`https://holesky.etherscan.io/address/${item?.value}`}
          linkProps={{ sx: { '&:hover': { textDecoration: 'underline' } } }}
          placement="right"
          showTooltip={false}
          text={item?.value}
        >
          <Typography>{item?.value}</Typography>
        </CopyableText>
      ) : (
        <Typography>{item?.value}</Typography>
      )}
      <Collapse in={isEditMode} mountOnEnter>
        {item?.editFn && (
          <EditRoleField
            item={item as Required<RoleItem>}
            onSuccess={() => setIsEditMode(false)}
            refetchRoles={refetchRoles}
          />
        )}
      </Collapse>
    </Stack>
  );
}

export default function ContractRolesDialog({
  avs,
  isAvsOwner,
  onClose,
  open,
  ...rest
}: DialogProps & { avs: AVSDeployment; isAvsOwner: boolean }) {
  const { isAllowlistEnabled } = useOperators({ avs });
  const {
    allowlistManager,
    churnApprover,
    ejector,
    paused,
    refetchRoles,
    regCoordOwner,
    rewardsInitiator,
    svcMngrOwner,
  } = useAvsRoles(avs);

  const items = useMemo(
    () => [
      {
        label: 'Service Manager Owner',
        value: svcMngrOwner,
        editFn: {
          name: 'transferOwnership',
          abi: BridgeTemplate.abi,
          address: avs?.serviceManagerProxy as Address,
        },
        requiredSender: svcMngrOwner,
      },
      {
        label: 'Allowlist Manager',
        value: allowlistManager,
        editFn: {
          name: 'setAllowlistManager',
          abi: BridgeTemplate.abi,
          address: avs?.serviceManagerProxy as Address,
        },
        requiredSender: svcMngrOwner,
      },
      {
        label: 'Rewards Initiator',
        value: rewardsInitiator,
        editFn: {
          name: 'setRewardsInitiator',
          abi: BridgeTemplate.abi,
          address: avs?.serviceManagerProxy as Address,
        },
        requiredSender: svcMngrOwner,
      },
      {
        label: 'Registry Coordinator Owner',
        value: regCoordOwner,
        editFn: {
          name: 'transferOwnership',
          abi: RegistryCoordinatorAbi,
          address: avs?.registryCoordinatorProxy as Address,
        },
        requiredSender: regCoordOwner,
      },
      {
        label: 'Churn Approver',
        value: churnApprover,
        editFn: {
          name: 'setChurnApprover',
          abi: RegistryCoordinatorAbi,
          address: avs?.registryCoordinatorProxy as Address,
        },
        requiredSender: regCoordOwner,
      },
      {
        label: 'Ejector',
        value: ejector,
        editFn: {
          name: 'setEjector',
          abi: RegistryCoordinatorAbi,
          address: avs?.registryCoordinatorProxy as Address,
        },
        requiredSender: regCoordOwner,
      },
      { label: 'Allowlist Enabled', value: isAllowlistEnabled },
      { label: 'Paused', value: Boolean(paused) },
    ],
    [
      svcMngrOwner,
      avs?.serviceManagerProxy,
      avs?.registryCoordinatorProxy,
      allowlistManager,
      rewardsInitiator,
      regCoordOwner,
      churnApprover,
      ejector,
      isAllowlistEnabled,
      paused,
    ],
  );

  return (
    <Dialog
      disablePortal
      onClose={onClose}
      open={open}
      sx={{ '& .MuiPaper-root': { p: 3, background: '#FFFFFF' } }}
      {...rest}
    >
      <DialogTitle>
        <Typography fontWeight={500} variant="h5">
          Contract Roles/Status
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ bgcolor: '#FAFAFA', border: '1px solid rgba(32, 39, 35, 0.08)', p: 3 }}>
          {items?.map(({ editFn, label, requiredSender, value }) => (
            <RoleList
              isAvsOwner={isAvsOwner}
              item={{ label, value: String(value), editFn, requiredSender } as RoleItem}
              key={`${label}_${String(value)}`}
              refetchRoles={refetchRoles}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
