import { isAddress } from 'viem';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: () => 'This field is required.',
    notType: ({ type }) => {
      return type === 'number' ? 'Please enter a numeric value.' : `Please enter a ${type}.`;
    },
  },
  string: {
    min: ({ min }) => `Please enter at least ${min} characters.`,
    max: ({ max }) => `Please enter at most ${max} characters.`,
    email: () => 'Please enter a valid email address.',
    url: () => 'Please enter a valid url.',
  },
  number: {
    min: ({ min }) => `Please enter a number greater than or equal to ${min}.`,
    max: ({ max }) => `Please enter a number smaller than or equal to ${max}.`,
    integer: () => 'Please enter an integer value.',
  },
});

yup.addMethod(yup.string, 'address', function address() {
  return this.test('is-address', function (value?: string) {
    if (value === undefined) {
      return true;
    }

    if (!isAddress(value || '', { strict: false })) {
      return this.createError({ message: 'Please enter a valid address.' });
    }

    if (!isAddress(value || '', { strict: true })) {
      return this.createError({ message: 'Must use checksummed address.' });
    }

    return true;
  });
});

yup.addMethod(yup.number, 'step', function (stepValue, message) {
  return this.test(
    'step',
    message || `Only multiples of ${stepValue} are allowed`,
    function (value: string) {
      return Number(value) % stepValue === 0;
    },
  );
});

yup.addMethod(yup.string, 'noLeadingDigit', function noLeadingDigit() {
  return this.matches(/^[^\d]/gi, 'Cannot begin with a digit');
});

export type Casing = 'uppercase' | 'lowercase' | 'any';
yup.addMethod(yup.string, 'alphanumeric', function alphanumeric(casing: Casing = 'any') {
  switch (casing) {
    case 'uppercase':
      return this.matches(/^[A-Z0-9]+$/, 'Please enter uppercase alphanumeric characters only');
    case 'lowercase':
      return this.matches(/^[a-z0-9]+$/, 'Please enter lowercase alphanumeric characters only');
    default:
      return this.matches(/^[a-zA-Z0-9]+$/, 'Please enter alphanumeric characters only');
  }
});

yup.addMethod(yup.string, 'noUrl', function noUrl() {
  return this.matches(/^((?!www).)*$/gi, 'Cannot contain urls');
});

yup.addMethod(yup.array, 'unique', function (message, mapper = (a: any) => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size;
  });
});
