import { jwtDecode } from 'jwt-decode';

export const authUtil = (() => {
  let _accessToken: string;
  let _headers = {};

  const setAccessToken = (val: string) => {
    _accessToken = val;
    window.localStorage?.setItem('dashboard_access', val);
    _headers = {
      ..._headers,
      Authorization: `Bearer ${_accessToken}`,
    };
  };

  const getAccessToken = () => _accessToken;

  const loadAccessToken = () => {
    const token = window.localStorage?.getItem('dashboard_access');

    _headers = {
      ..._headers,
      Authorization: `Bearer ${token}`,
    };
  };

  const getAuthHeaders = () => _headers;

  const getRoles = () => {
    const decoded: any = jwtDecode(_accessToken);

    return decoded?.roles;
  };

  return { getAccessToken, setAccessToken, getAuthHeaders, getRoles, loadAccessToken };
})();
