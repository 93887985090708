import { IconButton, IconButtonProps, Stack, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

import IconInfo from './icons/IconInfo';

export function WithInfo({ info, text }: { text: ReactNode; info?: string }) {
  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
      {typeof text === 'string' ? <Typography variant="body1">{text}</Typography> : text}
      {info && <Info info={info} sx={{ p: 0, ml: 1 }} />}
    </Stack>
  );
}

export function Info({ info, sx, ...props }: { info: ReactNode } & IconButtonProps) {
  return (
    <Tooltip placement="right" title={info}>
      <IconButton sx={{ p: 0, ...sx }} {...props}>
        <IconInfo />
      </IconButton>
    </Tooltip>
  );
}
