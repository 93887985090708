import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconLogo({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: '206px', height: '40px', ...sx }} viewBox="0 0 206 40" {...props}>
      <path
        clipRule="evenodd"
        d="M0.309521 11.6618C-0.103174 11.992 -0.103173 12.6197 0.309521 12.9498L2.44992 14.6621C2.75295 14.9046 3.18399 14.9029 3.48518 14.6582L15.6942 4.73836C15.887 4.58176 15.9989 4.34663 15.9989 4.09829V0.826267C15.9989 0.134735 15.199 -0.249727 14.659 0.18227L0.309521 11.6618ZM3.99625 21.6648C3.69506 21.9095 3.26402 21.9112 2.96099 21.6688L0.99426 20.0954C0.583565 19.7668 0.581201 19.143 0.989395 18.8113L14.6541 7.70871C15.1931 7.2708 15.9989 7.65435 15.9989 8.34879V11.5201C15.9989 11.7685 15.887 12.0036 15.6942 12.1602L3.99625 21.6648ZM1.50047 25.8179C1.09227 26.1496 1.09464 26.7734 1.50533 27.102L14.659 37.6249C15.199 38.0569 15.9989 37.6724 15.9989 36.9809L15.9989 15.7706C15.9989 15.0762 15.1931 14.6927 14.6541 15.1306L1.50047 25.8179ZM17.6483 36.706C17.6483 37.4407 18.5367 37.8087 19.0562 37.2891L36.8584 19.4868C37.1805 19.1647 37.1805 18.6425 36.8584 18.3204L19.0562 0.518403C18.5367 -0.00113675 17.6483 0.366827 17.6483 1.10157L17.6483 36.706Z"
        fill="#202723"
        fillRule="evenodd"
      />
      <path
        d="M193.234 32.136V13.8373H197.015V14.9715C197.015 16.1057 197.002 16.9123 196.977 17.3912C197.531 16.1813 198.363 15.1983 199.472 14.4422C200.606 13.6608 202.031 13.2702 203.744 13.2702C204.249 13.2702 204.74 13.308 205.219 13.3836V17.0131C204.312 16.9375 203.732 16.8997 203.48 16.8997C201.413 16.8997 199.812 17.4164 198.678 18.4498C197.569 19.4832 197.015 21.0332 197.015 23.1V32.136H193.234Z"
        fill="#202723"
      />
      <path
        d="M179.802 32.741C177.005 32.741 174.787 31.8841 173.148 30.1701C171.535 28.4562 170.729 26.0365 170.729 22.9111C170.729 21.046 171.107 19.3825 171.863 17.9206C172.644 16.4335 173.703 15.2867 175.039 14.4801C176.4 13.6484 177.925 13.2325 179.613 13.2325C182.335 13.2325 184.453 14.1147 185.965 15.879C187.477 17.6181 188.233 19.9874 188.233 22.9868V23.7807H174.547C174.572 25.6207 175.076 27.0825 176.059 28.1663C177.042 29.2502 178.303 29.7921 179.84 29.7921C181 29.7921 181.983 29.5148 182.789 28.9603C183.621 28.4058 184.125 27.5362 184.301 26.3516H188.082C187.88 28.1916 187.061 29.7164 185.625 30.9263C184.188 32.1361 182.247 32.741 179.802 32.741ZM184.301 21.0964C184.276 19.5841 183.848 18.3743 183.016 17.4669C182.184 16.5595 181.05 16.1059 179.613 16.1059C178.227 16.1059 177.08 16.5847 176.173 17.5425C175.265 18.4751 174.749 19.6597 174.623 21.0964H184.301Z"
        fill="#202723"
      />
      <path
        d="M154.773 40L157.987 31.6824L150.728 13.8374H154.925L159.197 25.5577C159.424 26.1626 159.688 26.9061 159.991 27.7883C160.218 26.9565 160.457 26.213 160.709 25.5577L164.603 13.8374H168.535L158.781 40H154.773Z"
        fill="#202723"
      />
      <path
        d="M138.3 32.741C136.51 32.741 134.998 32.2369 133.763 31.2287C132.553 30.1953 131.948 28.8217 131.948 27.1077C131.948 23.8563 134.128 21.9156 138.489 21.2854L142.08 20.7939C142.786 20.6931 143.277 20.5041 143.555 20.2268C143.857 19.9244 144.008 19.5085 144.008 18.9792C144.008 18.097 143.681 17.4039 143.025 16.8998C142.395 16.3705 141.538 16.1059 140.455 16.1059C139.169 16.1059 138.161 16.4209 137.43 17.051C136.699 17.6812 136.308 18.5381 136.258 19.6219H132.439C132.515 18.4373 132.868 17.3661 133.498 16.4083C134.153 15.4253 135.061 14.6566 136.22 14.1021C137.38 13.5224 138.715 13.2325 140.228 13.2325C142.723 13.2325 144.588 13.8752 145.823 15.1607C147.083 16.4209 147.714 18.2105 147.714 20.5293V32.1361H144.16V31.2665C144.16 30.9389 144.185 30.397 144.235 29.6408C143.756 30.5986 143 31.3548 141.967 31.9093C140.959 32.4638 139.736 32.741 138.3 32.741ZM139.056 29.8677C140.492 29.8677 141.677 29.4392 142.61 28.5822C143.567 27.7001 144.046 26.5532 144.046 25.1418V22.7221C143.819 23.0246 143.114 23.2892 141.929 23.5161L139.623 23.9319C138.413 24.1588 137.468 24.5243 136.787 25.0284C136.132 25.5072 135.804 26.1878 135.804 27.0699C135.804 27.9269 136.107 28.6074 136.712 29.1115C137.342 29.6156 138.123 29.8677 139.056 29.8677Z"
        fill="#202723"
      />
      <path d="M129.639 32.1361H111.454V5.67108H115.461V28.7335H129.639V32.1361Z" fill="#202723" />
      <path
        d="M102.962 32.4386C100.693 32.4386 98.9289 31.8967 97.6686 30.8129C96.4084 29.7038 95.7783 27.9773 95.7783 25.6333V16.862H90.5231V13.8374H95.7783V7.56144H99.5212V13.8374H105.533V16.862H99.5212V25.1796C99.5212 26.6163 99.8489 27.637 100.504 28.242C101.185 28.8469 102.281 29.1493 103.793 29.1493C104.474 29.1493 105.117 29.0989 105.722 28.9981V32.0983C104.865 32.3251 103.945 32.4386 102.962 32.4386Z"
        fill="#202723"
      />
      <path
        d="M85.0431 5.67108V29.0737H88.786V32.1361H76.9146V29.0737H81.3002V8.73346H77.6707V5.67108H85.0431Z"
        fill="#202723"
      />
      <path
        d="M74.6287 32.1361H70.2809L67.71 24.4991H57.5021L54.9312 32.1361H50.7724L60.2242 5.67108H65.1391L74.6287 32.1361ZM58.6363 21.1342H66.5758L63.4756 11.9093L62.606 8.99811C62.3288 10.0567 62.0389 11.0271 61.7365 11.9093L58.6363 21.1342Z"
        fill="#202723"
      />
    </SvgIcon>
  );
}
