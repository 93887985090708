import { Address } from 'viem';

export enum AVS_TYPES {
  GENERIC = 0,
  COPROCESSOR = 1,
  BRIDGE = 2,
  MACH = 3,
  ECDSA_HELLO_WORLD = 4,
  CUSTOM_BLS = 5,
}

export enum AVS_QUORUMS {
  ETH_LST = 0,
  EIGEN = 1,
  REALT = 2,
}

// Separate strategy arrays for each quorum
export const strategiesByQuorums: Record<AVS_QUORUMS, Address[]> = {
  [AVS_QUORUMS.ETH_LST]: [
    '0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0', // beaconETH
    '0x7D704507b76571a51d9caE8AdDAbBFd0ba0e63d3', // stETH
    '0x3A8fBdf9e77DFc25d09741f51d3E181b25d0c4E0', // rETH
    '0x05037A81BD7B4C9E0F7B430f1F2A22c31a2FD943', // LsETH
    '0x9281ff96637710Cd9A5CAcce9c6FAD8C9F54631c', // sfrxETH
    '0x31B6F59e1627cEfC9fA174aD03859fC337666af7', // ETHx
    '0x46281E3B7fDcACdBa44CADf069a94a588Fd4C6Ef', // osETH
    '0xaccc5A86732BE85b5012e8614AF237801636F8e5', // mETH
    '0x7673a47463F80c6a3553Db9E54c8cDcd5313d0ac', // ankrETH
    '0x80528D6e9A2BAbFc766965E0E26d5aB08D9CFaF9', // WETH
  ],
  [AVS_QUORUMS.EIGEN]: [
    '0x43252609bff8a13dFe5e057097f2f45A24387a84', // Eigen (EIGEN)
  ],
  [AVS_QUORUMS.REALT]: [
    '0xAD76D205564f955A9c18103C4422D1Cd94016899', // reALT
  ],
};

export interface Metadata {
  address: string;
  metadataName: string;
  metadataDescription: string;
  metadataDiscord: string;
  metadataLogo: string;
  metadataTelegram: string;
  metadataWebsite: string;
  metadataX: string;
  restakeableStrategies: any[];
  createdAtBlock: number;
  updatedAtBlock: number;
  createdAt: string;
  updatedAt: string;
  shares: any[];
  totalOperators: number;
  totalStakers: number;
}
